.Tabs--vertical {
    display: flex;
    flex-flow: row wrap;
}

.Tabs--vertical .nav-tabs {
    display: flex;
    flex-flow: column wrap;
    border: 0;
}

.Tabs--vertical .tab-content {
    flex: 1 1;
}

.Tabs--vertical .tab-content .tab-pane {
    background: rgba(49, 53, 61, 0.65);
    padding: 2rem;
    border-radius: 0 0.25rem 0.25rem 0;
    -webkit-border-radius: 0 0.25rem 0.25rem 0;
    -moz-border-radius: 0 0.25rem 0.25rem 0;
    -ms-border-radius: 0 0.25rem 0.25rem 0;
    -o-border-radius: 0 0.25rem 0.25rem 0;
    height: 100%;
}

.resizable-editor {
    border: 1px solid #3d3d3d;
    border-radius: 4px;
    resize: vertical;
    overflow: auto;
    min-height: 100px; /* Adjust as needed */
    max-height: 300px; /* Adjust as needed */
    width: 100%; /* Adjust as needed */
}


.text-title-post .rdw-editor-main {
    height: 100px;
}
.Tabs--vertical .tab-pane .create--form {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Tabs--vertical .tab-pane .ql-toolbar.ql-snow, .Tabs--vertical .tab-pane .ql-container.ql-snow {
    border: 0;
    background: #ffffff08;
    border-radius: 0.25rem 0.25rem 0 0;
    border-bottom: 1px solid rgba(49, 53, 61, 0.65);
    color: #ffffff;
}

.Tabs--vertical .tab-pane .ql-toolbar.ql-snow .ql-formats * {
    color: #ffffff;
    stroke: #ffffff;
}

.Tabs--vertical .tab-pane .ql-container.ql-snow {
    border-radius: 0 0 0.25rem 0.25rem;
}

/* .Tabs--vertical .nav-tabs .nav-item {
    flex: 1 1;
} */

.Tabs--vertical .nav-tabs .nav-link {
    margin-bottom: 0;
    border: 1px solid #ffffff10;
    border-right: 0;
    color: #ffffff;
    font-size: 0.875rem;
    min-width: 120px;
    min-height: 80px;
    /* height: 100%; */
    border-radius: 0;
    -webkit-border-radius: 0;
    display: block;
    padding: 1rem;
    margin: 0;
    margin-bottom: 0;
    width: 100%;
    background: transparent;
}

.Tabs--vertical .nav-tabs .nav-link svg {
    display: block;
    margin: auto auto 0.5rem;
    font-size: 1.8rem;
}

.Tabs--vertical .nav-item:first-child .nav-link {
    border-radius: 0.25rem 0 0 0;
    -webkit-border-radius: 0.25rem 0 0 0;
    -moz-border-radius: 0.25rem 0 0 0;
    -ms-border-radius: 0.25rem 0 0 0;
    -o-border-radius: 0.25rem 0 0 0;
}

.Tabs--vertical .nav-item:last-child .nav-link {
    border-radius: 0 0 0 0.25rem;
    -webkit-border-radius: 0 0 0 0.25rem;
    -moz-border-radius: 0 0 0 0.25rem;
    -ms-border-radius: 0 0 0 0.25rem;
    -o-border-radius: 0 0 0 0.25rem;
}

.Tabs--vertical .nav-tabs .nav-item.show .nav-link,
.Tabs--vertical .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: rgba(49, 53, 61, 0.65);
    border-color: rgba(49, 53, 61, 0.65);
    border-right: 1px solid #ffffff10;
    outline: none;
    box-shadow: none;
}

.Tabs--vertical .nav-tabs .nav-item .nav-link:hover,
.Tabs--vertical .nav-tabs .nav-item .nav-link:focus {
    color: #ffffff;
    background-color: #8c49a3;
    border-color: #8c49a3;
    outline: none;
    box-shadow: none;
}

.create--form form .form-label {
    font-weight: 600;
    margin-bottom: 0.2rem;
}

.create--form .editor--tool {
    background: #a9a9b730;
    color: #ffffff;
    display: inline-block;
    width: 35px;
    line-height: 35px;
    font-size: 1.2rem;
    height: 35px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    cursor: pointer;
    text-align: center;
}

.create--form form .form-control {
    color: #a9a9b7;
    padding: 0 1rem;
    font-size: 0.75rem;
    height: 40px;
    background: #ffffff08;
    border: 0;
    border-radius: 0.25rem;
    border:1px solid #b39d9d52;
    /* box-shadow: inset 0 0 10px #3d414a;
    -moz-box-shadow: inset 0 0 10px #3d414a;
    -webkit-box-shadow: inset 0 0 10px #3d414a; */
    box-shadow: none;
    border: 1px solid #565557;
    background: #1e1f21;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.create--form form select.form-control {
    background: #ffffff08 url('../../assets/images/down-arrow.png');
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 17px;
    border: 2px solid #a1989854;
    box-shadow: inset 0 0 10px #16181c;
    -moz-box-shadow: inset 0 0 10px #16181c;
    -webkit-box-shadow: inset 0 0 10px #16181c;
   
}

.create--form form textarea.form-control {
    height: 100px;
}

.rdw-editor-main {
    color: #a9a9b7;
    padding: 0 1rem;
    font-size: 0.75rem;
    /* height: 50px; */
    background-color: #ffffff08;
    border: 0;
    border-radius: 0.25rem;
}

.create--form .label--upload {
    background: #ffffff08;
    padding: 2rem;
    cursor: pointer;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    -o-border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
}
.rdw-editor-main,.create--form .label--upload,.create--form form select.form-control{
    background: #ffffff08;
    border: 0;
    border-radius: 6px;
    border:1px solid #b39d9d52;
    /*box-shadow: inset 0 0 10px #3d414a;
    -moz-box-shadow: inset 0 0 10px #3d414a;
    -webkit-box-shadow: inset 0 0 10px #3d414a;
    */background: #1e1f21;
    box-shadow: none;
    border: 1px solid #565557;
    background: #1e1f21;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.Tabs--vertical .sub--Tabs .nav-tabs {
    flex-flow: row wrap;
}

.Tabs--vertical .sub--Tabs .nav-tabs .nav-item {
    flex: none;
}

.Tabs--vertical .sub--Tabs .tab-content .tab-pane {
    background: none;
    padding: 2rem 0 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    height: auto;
}

.Tabs--vertical .sub--Tabs .nav-tabs .nav-link {
    margin-bottom: 0;
    border: 1px solid #ffffff10;
    border-right: 1px solid #ffffff10;
    min-width: 0;
    min-height: 0;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    -o-border-radius: 0.25rem;
    height: auto;
    padding: 0.5rem 1rem;
}

.Tabs--vertical .sub--Tabs .nav-item:first-child {
    margin-right: 0.5rem;
}

.Tabs--vertical .sub--Tabs .nav-tabs .nav-item.show .nav-link,
.Tabs--vertical .sub--Tabs .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #8c49a3;
    border-color: transparent;
    outline: none;
    box-shadow: none;
}

.Tabs--vertical .sub--Tabs .nav-tabs .nav-item .nav-link:hover,
.Tabs--vertical .sub--Tabs .nav-tabs .nav-item .nav-link:focus {
    color: #ffffff;
    background-color: #8c49a3;
    border-color: #8c49a3;
    outline: none;
    box-shadow: none;
}

.create--form .label--upload svg {
    font-size: 2rem;
    display: block;
    width: auto;
    height: auto;
    margin: 0 auto 1rem;
}

.audio--play--timer {
    color: #a9a9b7;
    padding: 1rem;
    background-color: #ffffff08;
    border: 0;
    text-align: center;
    border-radius: 0.25rem;
}

.audio--play--timer p strong {
    font-size: 2.5rem;
    font-weight: 500;
}

.audio--play--timer .progress {
    height: 0.15rem;
    margin-bottom: 1rem;
    background-color: #e9ecef;
}

.audio--play--timer .progress-bar {
    background-color: #8c49a3;
}

.audio--play--timer .list-group {
    justify-content: center;
}

.audio--play--timer .list-group-item {
    background: none;
    border: 0;
    border-radius: 0;
    color: #a9a9b780;
    padding: 0;
    font-size: 1.8rem;
    width: auto;
    margin: 0 0.25rem;
}

.live--frame {
    min-height: 300px;
    background: #ffffff08;
    padding: 0.5rem;
    position: relative;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    -o-border-radius: 0.25rem;
}

.live--frame .online--dot {
    position: absolute;
    left: 1rem;
    top: 1rem;
    background: #a9a9b720;
    font-size: 0.75rem;
    padding: 0.3rem 0.6rem;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    -o-border-radius: 0.25rem;
}

.live--frame .online--dot .dot--circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 0.5rem;
    border-radius: 100px;
    -webkit-border-radius: 100px;
}

.live--frame .online--dot .circle--green {
    background: green;
}

.live--frame .online--dot .circle--red {
    background: red;
}

.live--frame .fullscreen--icon, .live--frame .flip--camera {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    background: #a9a9b720;
    font-size: 1.2rem;
    padding: 0.3rem 0.6rem;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    cursor: pointer;
}

.live--frame .fullscreen--icon:hover, .live--frame .flip--camera:hover {
    background: #a9a9b740;
}

.user--frame {
    margin: 1rem 0 2rem;
}

.user--frame a {
    color: #ffffff;
}

.user--frame a img {
    max-width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    margin-right: 1rem;
}

.live--comments {
    position: relative;
}

.create--form form .live--comments textarea.form-control {
    height: 50px;
    padding: 0.8rem 3rem 0.8rem 1rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    resize: none;
}

.live--comments button {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    width: auto;
    height: 36px;
    padding: 0 1rem;
    margin: auto 0;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    line-height: 1;
}

.post--thumb--upload, .album--upload {
    position: relative;
}

.post--thumb--upload .post--media--edit {
    position: absolute;
    left: 1rem;
    top: 1rem;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    background: rgba(49, 53, 61, 0.65);
    display: inline-block;
    padding: 0.25rem 0.8rem;
    cursor: pointer;
}

.post--thumb--upload .post--media--close, .post--thumb--upload .cerificate--files--close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    background: rgba(49, 53, 61, 0.65);
    color: #ffffff;
    font-size: 1rem;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
}

.post--album--grid .album--upload .cerificate--files--close {
    right: 1rem;
    top: 1rem;
}

.post--album--grid .album--upload .post--media--edit {
    left: 1rem;
    top: 1rem;
}

.post--thumb--upload .post--media--close:hover, .post--thumb--upload .cerificate--files--close:hover {
    background: #a9a9b7;
    color: rgba(49, 53, 61, 0.65);
}

.post--album--grid {
    display: flex;
    flex-flow: row wrap;
    column-gap: 10px;
}

.post--album--grid .album--upload {
    flex: 1 1;
}

.post--album--grid .album--upload img, .post--thumb--upload img {
    padding: 0.5rem;
    background: #ffffff08;
    border-radius: 0.5rem;
    object-fit: cover;
    object-position: center center;
    height: 250px;
    width: 100%;
    -webkit-border-radius: 0.5rem;
}

.use--thumbnail {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
}

.use--thumbnail input {
    width: 17px;
    height: 17px;
    margin-right: 0.5rem;
}

.rdw-editor-toolbar, .rdw-option-wrapper {
    border: 1px solid #ffffff08;
    background: #ffffff08;
    color: #ffffff;
}

.rdw-option-wrapper img {
    filter: invert(1);
}

.rdw-dropdown-wrapper {
    height: 22px;
    background: #ffffff10;
    border: 1px solid #ffffff08;
    color: #ffffff;
}

.rdw-dropdown-selectedtext {
    color: #ffffff;
    font-size: 0.8rem;
}

.rdw-dropdown-carettoopen {
    border-top: 5px solid #ffffff50;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

.post--thumb--upload video {
    padding: 0.5rem;
    background: #ffffff08;
    border-radius: 0.5rem;
    object-fit: cover;
    object-position: center center;
    height: 250px;
    width: 100%;
    -webkit-border-radius: 0.5rem;
}
.liveCommentBox .comments--panel.new-comments--panel > .comments--box {
    position: relative;
    height: 298px;
    overflow: scroll;
    padding-bottom: 0px !important;
  }
  /* .liveCommentBox .comments--panel.new-comments--panel {
    height: 70px;
  
  }
  body:has(.comments--box) .comments--panel.new-comments--panel{
    height: 100vh;
  } */
  .liveCommentBox .comments--panel.new-comments--panel {
    height: fit-content;
    margin-top: 70px;
    min-height: 70px;
  }  
.stopBtn {
    margin-top: 10px;
    float: right;
  } 
 .css-79elbk {
    width: 100%;
  }
/** thumbnail new design **/
.post--thumb--upload img {
    height: 250px;
    width: auto;
    margin: 0 auto;
}
.post--thumb--upload {
    text-align: center;
}
.DraftEditor-editorContainer span {
    word-break: break-all;
}

.perviewAudio  .label--upload {
    height: 100%;
}

.perviewAudio.row {
    width: 100%;
  }

.perviewAudio .uploadGroupWrap {
    height: 100%;
}
.create--form form   .audio--upload--grid .form-control {
    background: #191b1f;
    margin-bottom: 10px;
    padding: 9px;
    border-radius: 3px;
    width: 100%;
    color: #818594;
}

.create--form form  .audio--upload--grid .post--media--close {
    top: -8px;
    right: -4px;
    background: rgb(0 0 0 / 44%);
    border-radius: 50px;
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create--form form  .audio--upload--grid .post--media--close svg {
    width: 13px;
    height: 13px;
}
.live-text-container {
    padding-right: 0px;
    padding-left: 8px;
}

.live-text-container .live-text {
    color: #8c49a3;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
    float: right;
    background: #fff;
    border-radius: 3px;
    padding: 2px 6px 2px 2px;
}


span.live-dot {
    animation-duration: 1s;
    animation-name: live-dot;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    vertical-align: middle;
    font-size: 22px;
}

span.live-word {
    vertical-align: middle;
    margin: 1px 0 0 4px;
    display: inline-block;
    line-height: 0;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 13px;
}

@keyframes live-dot {
    from {
        opacity: .3
    }

    to {
        opacity: 1
    }
}
/*-=-===-==-=
media css-=-=-==-=*/

@media (min-width:768px) and (max-width:991px) {
    .Tabs--vertical .tab-content .tab-pane {
        padding: 2rem;
    }
    .common--wrapper.create--form {
        margin-top: 20px;
    }
    .live--frame--row.row {
        margin-right: 0px;
        margin-left: 0;
        padding-bottom: 15px;
    }
    .live--frame--row.row .user--frame {
        margin: 1rem 0 0.5rem;
    }
    .live--frame--row.row .form-group {
        margin-bottom: 8px;
    }
    .modal-dialog {
        max-width: 800px;
        width: calc(100% - 20px);
        margin: 10px;
    }
    datepicker__input-container input {
        font-size: 14px !important;
        font-weight: 400;
        color: #fff !important;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .create--form .form-group.col-sm-12.thumbnail-wrap .label--upload {
        padding: 1rem;
        font-size: 12px;
    }
}

@media screen and (max-width:767px) {
    .Tabs--vertical .tab-content .tab-pane {
        padding: 1rem;
    }
    .common--wrapper.create--form {
        margin-top: 0px;
    }
}

@media screen and (max-width: 575px) {
    .Tabs--vertical {
        display: flex;
        flex-flow: column;
    }
}

@media screen and (max-width:575px) {
    .Tabs--vertical .nav-tabs {
        flex-flow: row wrap;
        margin-bottom: 1rem;
    }

    .Tabs--vertical .nav-tabs li {
        flex: 1 1 100%;
        max-width: 33.333%;
    }

    .Tabs--vertical .sub--Tabs .nav-tabs .nav-item {
        flex: none;
        max-width: 100%;
    }

    .Tabs--vertical .nav-tabs .nav-link {
        border-right: 1px solid #ffffff10;
        min-width: 100%;
    }

    .Tabs--vertical .nav-item:first-child .nav-link {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    .Tabs--vertical .btn {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }

    .Tabs--vertical .live--comments button {
        padding: 0;
    }
    .live--frame--row.row {
        margin-right: 0px;
        margin-left: 0;
        padding-bottom: 15px;
    }
    .live--frame--row.row .user--frame {
        margin: 1rem 0 0.5rem;
    }
    .live--frame--row.row .form-group {
        margin-bottom: 8px;
    }
    .modal-dialog {
        max-width: 800px;
        width: calc(100% - 20px);
        margin: 10px;
    }
    datepicker__input-container input {
        font-size: 14px !important;
        font-weight: 400;
        color: #fff !important;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .create--form .form-group.col-sm-12.thumbnail-wrap .label--upload {
        padding: 1rem;
        font-size: 12px;
    }
    .Tabs--vertical .sub--Tabs .tab-content .tab-pane {
        padding-top: 0;
    }
    .discover--module.common--wrapper.create--form .form-group.col-sm-12.col-lg-6.live--frame--wrapper {
        padding: 0;
    }
}

@media screen and (max-width:374px) {
    .discover--module.common--wrapper.create--form .text-end.col-sm-12 .btn {
        font-size: 11px;
        padding: 0.5rem 0.5rem;
    }
    .discover--module.common--wrapper.create--form  .Tabs--vertical .sub--Tabs .nav-tabs .nav-link {
        font-size: 12px;
        padding: 0.5rem 0.5rem;
    }
}