.order--table table td,
.order--table table th {
    vertical-align: middle;
    text-align: center;
    padding: 0.7rem;
}

.order--table table th {
    min-width: 140px;
}

.order--table table th:nth-child(1) {
    min-width: 120px;
}

.order--table table th:nth-child(2) {
    min-width: 230px;
}

.order--table table td .pro--image {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.order--table table td img {
    object-fit: cover;
    object-position: center;
    width: 60px;
    height: 60px;
    border: 3px solid #ffffff30;
    margin-right: 1rem;
}

.order--table table td strong {
    flex: 1 1;
}

.order--table table td strong small {
    display: block;
}

.order--table table td button {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
}

.order--table table td .btn-md {
    padding: 0.5rem 1rem;
    font-size: 1rem;
}


.order--table table td .form-control {
    color: #a9a9b7;
    padding: 0 0 0 0.5rem;
    text-align: center;
    font-size: 0.75rem;
    height: 40px;
    width: 68px;
    margin: 0;
    background-color: #ffffff08;
    border: 0;
    border-radius: 0.25rem;
    padding-right: 30px;
}

.table {
    margin-bottom: 0;
}

.order--table table td address {
    margin: 0;
    min-width: 150px;
}

.shop--Main--page .Gr--streamImage {
    overflow: hidden;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    width: 180px;
    height: 180px;
    margin: auto;
    margin-bottom: 1rem;
    border: 5px solid #ffffff;
}

.shop--Main--page .Gr--singleStream {
    padding-bottom: 15px;
    padding-top: 25px;
    position: relative;
}

.shop--Main--page .Gr--streamUser h4 {
    text-align: center;
    display: block;
    overflow: visible;
}

.shop--Main--page .Gr--streamDesc img {
    width: 45px;
    height: 45px;
    margin-right: 10px;
    object-fit: cover;
    position: relative;
    top: auto;
    right: auto;
    min-height: 0;
    min-width: 0;
}

.shop--Main--page .Gr--streamDesc {
    padding: 0.5rem 1rem 0;
}

.shop--Main--page .Gr--streamDesc a {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
}

.shop--Main--page .Gr--streamDesc p {
    font-weight: 600;
    font-size: 0.875rem;
    display: block;
}

.shop--Main--page .Gr--streamDesc p span {
    display: block;
    font-size: 80%;
    font-weight: normal;
}

.shop--Main--page .dropdown-toggle {
    color: #ffffff;
    width: 28px;
    height: 28px;
    line-height: 12px;
    z-index: 111;
    font-size: 1rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    padding: 0;
    position: absolute;
    right: 0.5rem;
    top: -0.5rem;
    border: 0;
}

.shop--Main--page .dropdown-toggle:hover {
    background: #313445;
}

.shop--Main--page .dropdown-toggle svg {
    font-size: 1rem;
    width: auto;
    vertical-align: middle;
    height: auto;
}

.shop--Main--page .dropdown-toggle::after {
    display: none;
}

.shop--Main--page .dropdown-menu {
    min-width: 0;
    padding: 0;
    font-size: 0.75rem;
    color: #ffffff;
    text-align: left;
    list-style: none;
    background-color: #313445;
    border: 0;
    overflow: hidden;
}

.shop--Main--page .dropdown-menu .dropdown-item {
    font-size: 0.75rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}

.shop--Main--page .dropdown-menu .dropdown-item:hover {
    background: #33365e;
    color: #ffffff;
}

.shop--Main--page .dropdown-menu .dropdown-item svg {
    margin-right: 0.4rem;
}

/*-=-=-=-=-=
Media Css-=--==-=*/

@media screen and (max-width:991px) {
    .shop--Main--page .Gr--streamImage {
        width: 120px;
        height: 120px;
    }
}