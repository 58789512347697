.discover--module h4 a {
    color: #ffffff;
}

.discover--module .discover--Slider .slick-slide.slick-current {
    margin-right: -300px;
    padding-right: 330px;
}

.discover--module .discover--Slider .slick-slide {
    padding-right: 330px;
    position: relative;
}

.discover--module .discover--Slider .slick-next,
.discover--module .discover--Slider .slick-prev {
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 100px;
    padding: 0 10px;
}

.discover--module .discover--Slider .slick-next:hover,
.discover--module .discover--Slider .slick-prev:hover {
    background: rgba(255, 255, 255, 1);
}

.discover--module .discover--Slider .slick-prev {
    left: 20px;
}

.discover--module .discover--Slider .slick-next {
    right: 20px;
}

.discover--module .discover--Slider .slick-next:before,
.discover--module .discover--Slider .slick-prev:before {
    font-size: 1.2rem;
    width: 15px;
    height: 15px;
    display: inline-block;
    line-height: 15px;
    text-align: center;
    padding: 0;
    border-color: #333333;
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    transform: rotate(-45deg);
}

.discover--module .discover--Slider .slick-next:before {
    transform: rotate(45deg) translate(-3px, 4px);
    -webkit-transform: rotate(45deg) translate(-3px, 4px);
}

.discover--module .discover--Slider .slick-prev:before {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.discover--module .Gr--slider--Desc {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.discover--module .slick-slide .Gr--post--image img {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    height: 350px;
    object-position: center;
}

.discover--module .Gr--slider--Desc .Gr--userRComments {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    left: 0;
    padding: 1rem 2rem;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0);
}

.discover--module .Gr--slider--Desc .Gr--userData {
    flex: 1 1;
    color: #fff;
    margin-left: 1.2rem;
}

.discover--module .Gr--slider--Desc .Gr--userData h4,
.discover--module .Gr--slider--Desc .Gr--userData h4 a {
    margin: 0;
    color: #fff;
    font-weight: normal;
}

.discover--module .Gr--slider--Desc .Gr--userData p {
    margin: 0;
    font-size: 12px;
    color: #b3b3b3;
    display: flex;
    flex-flow: row wrap;
}

.discover--module .Gr--slider--Desc .Gr--userRComments img {
    border: 1px solid #fff;
    padding: 2px;
    width: 45px;
    height: 45px;
    border-radius: 100px;
    object-fit: cover;
    max-width: 45px;
}

.discover--module .Gr--realmMPanel {
    position: relative;
    padding: 1rem;
    background-color: #313445;
    background-size: cover;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 0 2rem;
}

.discover--module .Gr--realmMPanel:before {
    background: url('../../assets/images/music-stream4.png') no-repeat 50%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    opacity: .1;
}

.discover--module .Gr--realmMPanel a {
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.discover--module .Gr--realmMPanel a img {
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border: 1px solid #fff;
    padding: 2px;
    width: 45px;
    height: 45px;
    object-fit: cover;
    max-width: 45px;
}

.discover--module .Gr--realmMPanel h4 {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin: 0 0 0 1rem;
    text-transform: capitalize;
}

.Gr--singleStream {
    background-color: rgba(49, 53, 61, .65);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    overflow: hidden;
    padding-bottom: 15px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .75);
}

/* .Gr--streamImage {
    overflow: hidden;
} */

.Gr--streamImage a img,
.Gr--streamImage img {
    height: 180px;
    object-fit: cover;
    width: 100%;
    transition: all .3s ease-in-out;
}

.Gr--streamImage:hover img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
}

.Gr--streamImage .dropdown-toggle {
    color: #ffffff;
    width: 28px;
    height: 28px;
    line-height: 12px;
    z-index: 111;
    font-size: 1rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    padding: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border: 0;
}

.Gr--streamImage .dropdown-toggle:hover {
    background: #313445;
}

.Gr--streamImage .dropdown-toggle svg {
    font-size: 1rem;
    width: auto;
    vertical-align: middle;
    height: auto;
}

.Gr--streamImage .dropdown-toggle::after {
    display: none;
}

.Gr--streamImage .dropdown-menu {
    min-width: 0;
    padding: 0;
    font-size: 0.75rem;
    color: #ffffff;
    text-align: left;
    list-style: none;
    background-color: #313445;
    border: 0;
    overflow: hidden;
}

.Gr--streamImage .dropdown-menu .dropdown-item {
    font-size: 0.75rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}

.Gr--streamImage .dropdown-menu .dropdown-item:hover {
    background: #33365e;
    color: #ffffff;
}

.Gr--streamImage .dropdown-menu .dropdown-item svg {
    margin-right: 0.4rem;
}

.Gr--streamDesc {
    padding: 0.5rem 0 0;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
}

.Gr--streamDesc p,
.Gr--streamDesc a {
    text-transform: capitalize;
    color: #a9a9b7;
    font-size: 14px;
}

.Gr--streamDesc a:hover,
.Gr--streamDesc a:hover p {
    color: #ffffff;
}

.Gr--streamDesc img {
    width: 46px;
    height: 46px;
    margin-right: 0;
    object-fit: cover;
    min-height: 46px;
    min-width: 46px;
    /* border: 1px solid #ffffff38; */
    padding: 3px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.Gr--streamDesc p,
.Gr--streamUser p {
    margin: 0;
    font-size: 12px;
    color: #b3b3b3;
    text-transform: capitalize;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.Gr--streamUser p svg {
    margin-right: 0.5rem;
}

.Gr--streamUser {
    padding: 0 1rem 0;
    min-height: 46px;
}

div#marketplace_id .marketplaceWrap.row {
    row-gap: 24px;
}

div#marketplace_id ul.react-tabs__tab-list {
    display: flex;
    gap: 14px;
}

div#marketplace_id .react-tabs__tab-list {
    border: none;
    margin-bottom: 35px;
}

div#marketplace_id .react-tabs__tab {
    font-weight: 500;
    padding: 6px 16px;
    opacity: 0.9;
}

div#marketplace_id .react-tabs__tab:after,
div#marketplace_id .react-tabs__tab:before {
    content: unset;
}

div#marketplace_id .react-tabs__tab--selected {
    border-radius: 50px;
    color: #8749a4;
    opacity: 1;
    font-weight: 600;
    border: 1px solid #8749a4
}

div#marketplace_id .Gr--streamImage img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 10px;
    box-shadow: 0 2px 4px #3c485826 !important;
}

div#marketplace_id .Gr--singleStream {
    border: 0px solid #FFF;
}

div#marketplace_id .Gr--streamDesc {
    padding: 0;
}

div#marketplace_id .Gr--streamImage {
    margin: 0 0 15px 0;
    min-height: auto;
    border-radius: 0;
    background: none;
}

div#marketplace_id .Gr--streamImage a>span {
    display: flex !important;
    min-height: auto !important;
    border-radius: unset !important;
    margin-bottom: 0;
}

div#marketplace_id .Gr--streamImage a>span>img {
    height: 250px;
    object-fit: cover;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.marketplaceWrap.row {
    row-gap: 30px;
}

div#marketplace_id .Gr--streamDesc a img {
    box-shadow: 0 5px 13px #00000073 !important;
    border: 0 !important;
    padding: 2px;
    position: relative;
    transition: all .5s ease;
    background-color: #fff;
    max-width: 100%;
    vertical-align: middle;
    overflow-clip-margin: content-box;
    overflow: clip;
    height: 36px;
    width: 36px;
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
}

div#marketplace_id .Gr--streamDesc {
    position: relative;
}

div#marketplace_id .Gr--streamDesc>a {
    position: absolute;
    top: -37px;
    left: 0;
    margin: auto;
    text-align: center;
    right: 0;
}

div#marketplace_id .Gr--streamUser {
    margin-top: 25px;
    width: 100%;
    padding: 0 20px;
}

div#marketplace_id .Gr--streamDesc>a img:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    z-index: 1;
}

div#marketplace_id .Gr--singleStream:hover {
    margin-top: -5px;
}

div#marketplace_id .react-tabs__tab svg {
    margin-right: 3px;
}

div#marketplace_id .Gr--singleStream {
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    padding: 8px;
}

div#marketplace_id .Gr--streamUser p {
    justify-content: center;
    margin-bottom: 10px;

}

button.createShopBtn.btn {
    border-radius: 50px;
    font-size: 14px;
    padding: 8px 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

div#marketplace_id .Gr--streamUser a {
    text-align: center;
}

div#marketplace_id .Gr--streamImage .dropdown-toggle {
    color: #8749a4;
    width: 36px;
    height: 36px;
    line-height: 12px;
    z-index: 111;
    font-size: 1rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    padding: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 50px;
    background: #fff;
    box-shadow: 0 5px 13px #3c485852 !important;
    border: 1px solid #e5e5e5;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

div#marketplace_id .Gr--streamImage .dropdown-toggle svg {
    font-size: 0.88rem;
    width: auto;
    vertical-align: middle;
    height: auto;
}

div#marketplace_id .Gr--streamImage .dropdown-toggle:hover {
    background: #8749a4;
    color: #fff;
    border: 1px solid #8749a4;
}

.marketplace_wrap h4.category--title {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #33333360;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    position: relative;
    text-transform: uppercase;
}

.marketplace_wrap h4.category--title::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 60px;
    height: 5px;
    background: #8c49a3;
}

/*-==-=-=-=-=
Media Css
-==--==-*/


@media (min-width:992px) and (max-width:1199px) {
    .discover--module .Gr--realmMPanel h4 {
        font-size: 18px;
    }
}

@media screen and (max-width:1400px) {
    div#marketplace_id .Gr--streamImage a>span>img {
        height: 200px;
    }
}

@media screen and (max-width:991px) {
    .discover--module .Gr--realmMPanel h4 {
        color: #fff;
        font-weight: 700;
        font-size: 15px;
        margin: 0 0 0 1rem;
        text-transform: capitalize;
    }

    .discover--module .discover--Slider .slick-slide.slick-current {
        margin-right: -150px;
        padding-right: 180px;
    }

    .discover--module .discover--Slider .slick-slide {
        padding-right: 180px;
    }

    .discover--module .slick-slide .Gr--post--image img {
        height: 250px;
    }

    .Gr--streamImage a img,
    .Gr--streamImage img {
        height: 150px;
    }
}

@media screen and (max-width:767px) {
    .discover--module {
        padding: 0 0 2rem;
    }

    .discover--module .discover--Slider .slick-slide.slick-current {
        margin-right: 0;
        padding-right: 0;
    }

    .discover--module .discover--Slider .slick-slide {
        padding-right: 0;
    }

    .discover--module .slick-slide .Gr--post--image img {
        height: 250px;
    }

    .discover--module .Gr--realmMPanel {
        margin: 0 0 1rem;
    }
}

@media screen and (max-width:500px) {
    button.createShopBtn.btn {
        margin: auto auto 25px auto;
    }

    div#marketplace_id ul.react-tabs__tab-list {
        justify-content: center;
    }

    .discover--module.marketplace_wrap {
        padding-top: 0 !important;
    }
}