.stream--page .streamerMList {
    background: rgba(49, 53, 61, 0.65);
    padding: 1rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    list-style: none;
}

.stream--page .streamerMList li {
    color: #fff;
    font-weight: 600;
    margin-bottom: 1.2rem;
}

.stream--page .streamerMList li:last-child {
    margin-bottom: 0;
}

.stream--page .streamerMList li a {
    display: flex;
    align-items: center;
    width: 100%;
}

.stream--page .streamerIcon {
    position: relative;
    text-align: center;
    padding: 2px;
    box-shadow: 0 0 0 2px transparent;
    -webkit-box-shadow: 0 0 0 2px transparent;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    display: inline-block;
    width: 50px;
    height: 50px;
    flex-flow: wrap;
    display: flex;
}

.stream--page .streamerIcon img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    object-fit: cover;
}

.stream--page .streamerMList li p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    flex: auto;
    margin-left: .5rem;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 600;
    flex-wrap: wrap;
}

.stream--page .card-group {
    flex-flow: row nowrap;
    overflow-x: auto;
    margin: 0 -0.5rem;
}

.stream--page .card-group::-webkit-scrollbar {
    display: none;
}

.stream--page .card-group::-webkit-scrollbar-button {
    display: none;
}

.stream--page .card {
    border: 0;
    background: no-repeat;
    position: relative;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    overflow: hidden;
    padding: 0 0.5rem;
    flex: 0 0 18.6666%;
    max-width: 18.6666%;
    cursor: pointer;
}

.stream--page .card .card-body {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: rgba(49, 53, 61, 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem
}

.stream--page .card-body .card-title {
    color: #ffffff;
    margin: 0;
    text-transform: capitalize;
    font-weight: 700;
}

.stream--page .card .card-img-top {
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.stream--page .card-group>.card:not(:last-child) .card-img-top {
    border-top-right-radius: 0.5rem;
}

.stream--page .card-group>.card:not(:first-child) .card-img-top {
    border-top-left-radius: 0.5rem;
}

.type--feed--post,
.single--stream--post {
    background: rgba(49, 53, 61, 0.65);
    padding: 1.5rem 1rem 1rem;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
}

.type--feed--post .type--here,
.post--options {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ffffff20;
}

.post--options {
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

.type--feed--post .type--comment {
    flex: 1 1;
}

.type--feed--post .type--here .user--avatar {
    display: inline-block;
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 100px;
    margin-right: 1rem;
}

.type--feed--post .type--here .user--avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.type--feed--post .type--here .form-control {
    width: 100%;
    background: none;
    border: 0;
    color: #ffffff;
}

.type--feed--post .type--comment input::-webkit-input-placeholder {
    font-size: 1.5rem;
    color: #ffffff;
}

.type--feed--post .type--here textarea {
    resize: none;
}

.post--options .list-group,
.single--stream--post .list-group {
    display: flex;
    align-items: center;
    background: none;
    flex-flow: row wrap;
    margin-right: 1rem;
}

.single--stream--post .list-group {
    margin-right: 0;
}

.post--options .list-group-item,
.single--stream--post .list-group-item {
    border: 0;
    background: none;
    color: #ffffff;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
}

.post--options .list-group-item:hover,
.single--stream--post .list-group-item:hover {
    background: rgba(49, 53, 61, 0.9);
}

.single--stream--post {
    padding: 1.5rem 0 1rem;
}

.single--stream--post .Gr--stream-user {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding: 0 1rem;
    margin-bottom: 1rem;
    position: relative;
}

.single--stream--post .Gr--stream-user .dropdown {
    position: absolute;
    right: 1rem;
    top: 0;
}

.single--stream--post .Gr--stream-user .dropdown-toggle {
    color: #ffffff;
    width: 28px;
    height: 28px;
    line-height: 12px;
    z-index: 111;
    font-size: 1rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    padding: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border: 0;
}

.single--stream--post .Gr--stream-user .dropdown-toggle:hover {
    background: #313445;
}

.single--stream--post .Gr--stream-user .dropdown-toggle svg {
    font-size: 1rem;
    width: auto;
    vertical-align: middle;
    height: auto;
}

.single--stream--post .Gr--stream-user .dropdown-toggle::after {
    display: none;
}

.single--stream--post .Gr--stream-user .dropdown-menu {
    min-width: 0;
    padding: 0;
    font-size: 0.75rem;
    color: #ffffff;
    text-align: left;
    list-style: none;
    background-color: #313445;
    border: 0;
    overflow: hidden;
}

.single--stream--post .Gr--stream-user .dropdown-menu .dropdown-item {
    font-size: 0.75rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    text-transform: capitalize;
}

.single--stream--post .Gr--stream-user .dropdown-menu .dropdown-item:hover,
.single--stream--post .Gr--stream-user .dropdown-menu .dropdown-item:focus,
.single--stream--post .Gr--stream-user .dropdown-menu .dropdown-item:active {
    background: #33365e;
    color: #ffffff;
}

.single--stream--post .Gr--stream-user .dropdown-menu .dropdown-item p {
    margin: 0;
}

.single--stream--post .Gr--stream-user img {
    display: inline-block;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 100px;
    margin-right: 0;
    object-fit: cover;
}

.Gr--streamPost--content img,
.Gr--streamPost--content video {
    width: 100%;
    object-fit: contain;
    margin-bottom: 1rem;
}

.single--stream--post .list-group {
    padding: 0 1rem;
    justify-content: flex-start;
}

.single--stream--post .list-group-item {
    border: 0;
    background: none;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    padding: 0.5rem 0.875rem;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    display: flex;
    align-items: center;
}

.single--stream--post .list-group-item svg {
    font-size: 1.5rem;
}

.single--stream--post .Gr--streamPost--content pre {
    font-family: inherit;
    padding: 0 1rem;
    font-size: 1rem;
    line-height: 1.7;
}

.single--stream--post .Gr--stream-user .Gr--streamDesc {
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
}

.single--stream--post .list-group-item a {
    color: #ffffff
}

.single--stream--post .Gr--streamPost--content pre {
    color: #fff;
}

.Gr--streamPost--content img,
.Gr--streamPost--content video {
    width: 100%;
    object-fit: contain;
    margin-bottom: 1rem;
}

.stream--page .card-body .card-title {
    color: #ffffff;
    margin: 0;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 18px;
}

a.leftangle {
    position: absolute;
    left: 6px;
    top: 0;
    bottom: 0;
    height: fit-content;
    margin: auto;
    z-index: 1;
    width: 25px;
    height: 25px;
    background: rgb(0 0 0 / 53%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
}

a.rightangle {
    position: absolute;
    right: 6px;
    top: 0;
    bottom: 0;
    height: fit-content;
    margin: auto;
    z-index: 1;
    width: 25px;
    height: 25px;
    background: rgb(0 0 0 / 53%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
}

a.rightangle svg {
    position: relative;
    left: 1px;
}

.scrolbarSlde {
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;
}

.scrolbarSlde .card a {
    display: block;
    overflow: hidden;
    position: relative;
}

.scrolbarSlde .card {
    overflow-x: auto;
}

.Gr--streamPost--content a img {
    height: 461px;
    width: auto;
    margin: 0 auto;
}

.single--stream--post {
    padding-left: 10px;
    padding-right: 10px;
}

.single--stream--post .streamDec {
    padding: 0 1rem;
}

.single--stream--post h2 {
    padding: 0 1rem;
}

.single--stream--post .Gr--streamPost--content {
    margin: 0 1rem;
}

a.showHidebtn {
    padding-left: 1rem;
    text-decoration: underline;
    margin-bottom: 10px;
    text-underline-offset: 3px;
    display: block;
    margin-top: 10px;
}

a.showHidebtn:hover {
    text-decoration: underline;
}

.mb-0.txtWrap {
    padding-left: 1rem;
    padding-right: 1rem;
}

.single--stream--post .streamDec,
.single--stream--post .streamDec p {
    padding: 0 !important;
}

.single--stream--post .list-group-item {
    border: 0
}

/*-=-=-=-=-=
Media Css
=-==-=-=-=*/


@media screen and (max-width:991px) {
    .comments--panel.new-comments--panel {
        MARGIN-TOP: 5px;
    }

    .single--stream--post .Gr--streamPost--content pre {
        color: #fff;
    }

    .discover--module.common--wrapper.visual--single--page .single--bg--col .visual--user {
        padding: 0;
    }

    .discover--module.common--wrapper.visual--single--page .single--bg--col .visual--desc .list-group-item {
        padding: 0;
    }

    .discover--module.common--wrapper.visual--single--page .single--bg--col .visual--desc h2 {
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 500;
    }

    .single--stream--post .Gr--streamPost--content pre {
        font-size: 14px;
        line-height: 24px;
        word-break: break-word;
    }

    .discover--module.common--wrapper.visual--single--page .single--bg--col .visual--desc p {
        font-size: 14px;
        line-height: 24px;
        word-break: break-word;
    }

    .discover--module.common--wrapper.visual--single--page .comments--panel {
        height: 100%;
    }

    .discover--module.common--wrapper.visual--single--page .comments--box {
        height: 100%;
    }

    .discover--module.common--wrapper.visual--single--page .live--comments {
        position: relative;
    }

    .discover--module.common--wrapper.visual--single--page .comments--box .visual--desc {
        margin-bottom: 0;
        flex: 1 1 100%;
        padding-left: 15px;
    }

    .discover--module.common--wrapper.visual--single--page .comments--box .visual--desc p {
        padding-left: 25px;
        padding-top: 3px;
    }

    .discover--module.common--wrapper.visual--single--page .showTextarea.reply--box .live--comments button.btn.btn-primary {
        padding-right: 0;
        background: none;
        border: none;
    }

    .stream--page .card-group .card .card-title.h5 {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.3px;
    }

    .discover--module .single--stream--post {
        padding: 1rem 0 0.5rem;
    }

    .discover--module .single--stream--post .Gr--stream-user .dropdown {
        right: 0;
    }

    .discover--module .single--stream--post .Gr--stream-user {
        padding: 0 0.5rem;
        margin-bottom: 0.7rem;
    }

    .discover--module .single--stream--post .list-group {
        padding: 0 0.5rem;
        justify-content: space-between;
    }

    .discover--module .single--stream--post .list-group-item {
        padding: 0.5rem 0.5rem;
    }

    .discover--module .single--stream--post .list-group-item span.ms-2 {
        margin-left: 0.3rem !important;
    }

    .discover--module .Gr--streamPost--content img,
    .discover--module .Gr--streamPost--content video {
        width: 100%;
        object-fit: cover;
        margin-bottom: 0.5rem;
        height: 100%;
    }

    .single--stream--post .Gr--streamPost--content pre {
        color: #fff;
    }

    div#scrollableDiv+.col-lg-3.col-sm-12 {
        margin-top: 25px;
    }

    .stream--page .card-group {
        flex-flow: row nowrap;
        overflow-x: scroll;
        margin: 0 -0.5rem;
    }
}

@media (max-width:767px) {


    .discover--module.common--wrapper.stream--page .single--stream--post.mt-4:first-child {
        margin-top: 5px !important;
    }

    .stream--page .card-group {
        flex-flow: row nowrap;
        display: flex;
        overflow-x: scroll;
        margin: 0 -0.5rem;
    }

    .stream--page .card-group .card {
        flex: 0 0 29%;
        max-width: 29%;
    }

    .stream--page .card-group .card .card-title.h5 {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.3px;
    }

    .discover--module .single--stream--post {
        padding: 1rem 0 0.5rem;
    }

    .discover--module .single--stream--post .Gr--stream-user .dropdown {
        right: 0;
    }

    .discover--module .single--stream--post .Gr--stream-user {
        padding: 0 0.5rem;
        margin-bottom: 0.7rem;
    }

    .discover--module .single--stream--post .list-group {
        padding: 0 0.5rem;
        justify-content: space-between;
    }

    .discover--module .single--stream--post .list-group-item {
        padding: 0.5rem 0.5rem;
    }

    .discover--module .single--stream--post .list-group-item span.ms-2 {
        margin-left: 0.3rem !important;
    }

    .discover--module .Gr--streamPost--content img,
    .discover--module .Gr--streamPost--content video {
        width: 100%;
        object-fit: cover;
        margin-bottom: 0.5rem;
        height: 100%;
    }

    .single--stream--post .Gr--streamPost--content pre {
        color: #fff;
    }

    .discover--module.common--wrapper.visual--single--page .single--bg--col .visual--user {
        padding: 0;
    }
    
    .discover--module.common--wrapper.visual--single--page .single--bg--col .visual--desc .list-group-item {
        padding: 0;
    }

    .discover--module.common--wrapper.visual--single--page .single--bg--col .visual--desc h2 {
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 500;
    }

    .single--stream--post .Gr--streamPost--content pre {
        font-size: 14px;
        line-height: 24px;
        word-break: break-word;
    }

    .discover--module.common--wrapper.visual--single--page .single--bg--col .visual--desc p {
        font-size: 14px;
        line-height: 24px;
        word-break: break-word;
    }

    .discover--module.common--wrapper.visual--single--page .comments--panel {
        height: 100%;
    }

    .discover--module.common--wrapper.visual--single--page .comments--box {
        height: 100%;
    }

    .discover--module.common--wrapper.visual--single--page .live--comments {
        position: relative;
    }

    .discover--module.common--wrapper.visual--single--page .comments--box .visual--desc {
        margin-bottom: 0;
        flex: 1 1 100%;
        padding-left: 15px;
    }

    .discover--module.common--wrapper.visual--single--page .comments--box .visual--desc p {
        padding-left: 25px;
        padding-top: 3px;
    }

    div#scrollableDiv+.col-lg-3.col-sm-12 {
        margin-top: 25px;
    }

    .discover--module.common--wrapper.visual--single--page .comments--box .visual--desc p {
        font-size: 14px;
    }
}

@media (max-width:360px) {
    .discover--module.common--wrapper.visual--single--page .comments--box .visual--desc {
        margin-bottom: 0;
        flex: 1 1 100%;
        padding-left: 10px;
    }

    .discover--module.common--wrapper.visual--single--page .comments--box {
        background: none;
        padding: 0.6rem;
    }

    .wrapper--comments .live--comments {
        padding: 0 0px 15px 0px;
        width: calc(100% - 10px);
    }

    .discover--module.common--wrapper.visual--single--page .comments--box .visual--desc p {
        font-size: 13px;
    }

    .stream--page .card-group .card {
        flex: 0 0 31%;
        max-width: 31%;
    }

    .discover--module.common--wrapper.stream--page .Gr--streamPost--content img {
        height: calc(100vh - 500px);
    }
}