.discover--module .discover--Slider .slick-slide.slick-current {
  margin-right: -300px;
  padding-right: 330px;
}

.discover--module .discover--Slider .slick-slide {
  padding-right: 330px;
  position: relative;
}

.discover--module .discover--Slider .slick-next,
.discover--module .discover--Slider .slick-prev {
  z-index: 1;
  background: rgba(255, 255, 255, 0.7);
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 100px;
  padding: 0 10px;
}

.discover--module .discover--Slider .slick-next:hover,
.discover--module .discover--Slider .slick-prev:hover {
  background: rgba(255, 255, 255, 1);
}

.discover--module .discover--Slider .slick-prev {
  left: 20px;
}

.discover--module .discover--Slider .slick-next {
  right: 20px;
}

.discover--module .discover--Slider .slick-next:before,
.discover--module .discover--Slider .slick-prev:before {
  font-size: 1.2rem;
  width: 15px;
  height: 15px;
  display: inline-block;
  line-height: 15px;
  text-align: center;
  padding: 0;
  border-color: #333333;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  transform: rotate(-45deg);
}

.discover--module .discover--Slider .slick-next:before {
  transform: rotate(45deg) translate(-3px, 4px);
  -webkit-transform: rotate(45deg) translate(-3px, 4px);
}

.discover--module .discover--Slider .slick-prev:before {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.discover--module .Gr--slider--Desc {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.discover--module .slick-slide .Gr--post--image img {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  height: 350px;
  object-position: center;
}

.discover--module .Gr--slider--Desc .Gr--userRComments {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  left: 0;
  padding: 1rem 2rem;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0);
}

.discover--module .Gr--slider--Desc .Gr--userData {
  flex: 1 1;
  color: #fff;
  margin-left: 1rem;
}

.discover--module .Gr--slider--Desc .Gr--userData h4,
.discover--module .Gr--slider--Desc .Gr--userData h4 a {
  margin: 0;
  color: #fff;
  font-weight: normal;
}

.discover--module .Gr--slider--Desc .Gr--userData p {
  margin: 0;
  font-size: 12px;
  color: #b3b3b3;
  display: flex;
  flex-flow: row wrap;
}

.discover--module .Gr--slider--Desc .Gr--userRComments img {
  border: 1px solid #fff;
  padding: 2px;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  object-fit: cover;
  max-width: 45px;
}

.discover--module .Gr--realmMPanel {
  position: relative;
  padding: 1rem;
  background-color: #313445;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 0 2rem;
}

.discover--module .Gr--realmMPanel:before {
  background: url("../../assets/images/music-stream4.png") no-repeat 50%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.discover--module .Gr--realmMPanel a {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.discover--module .Gr--realmMPanel a img {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border: 1px solid #fff;
  padding: 2px;
  width: 45px;
  height: 45px;
  object-fit: cover;
  max-width: 45px;
}

.discover--module .Gr--realmMPanel h4 {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 0 1rem;
  text-transform: capitalize;
}

.grid--Slider .slick-slide + .slick-slide {
  padding-left: 20px;
}

.grid--Slider .slick-next,
.grid--Slider .slick-prev {
  z-index: 1;
  top: -45px;
}

.grid--Slider .slick-prev {
  left: auto;
  right: 25px;
}

.grid--Slider .slick-next {
  right: 0;
}

.grid--Slider .slick-next:before,
.grid--Slider .slick-prev:before {
  font-size: 1rem;
  width: 12px;
  height: 12px;
  display: inline-block;
  line-height: 12px;
  text-align: center;
  padding: 0;
  border-color: #ffffff;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
}

.grid--Slider .slick-next:before {
  transform: rotate(45deg) translate(-3px, 3px);
  -webkit-transform: rotate(45deg) translate(-3px, 3px);
}

.grid--Slider .slick-prev:before {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.Gr--singleStream {
  background-color: rgba(49, 53, 61, 0.65);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  overflow: hidden;
  padding-bottom: 15px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
}

.Gr--streamImage {
  overflow: hidden;
  position: relative;
  background: #ffffff10;
  border-radius: 0.5rem;
  min-height: 180px;
  margin-bottom: 0.5rem;
}

.Gr--streamImage .pre--para {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -moz-line-clamp: 10;
  line-clamp: 10;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  padding: 10px 10px 0;
}

.Gr--streamImage .pre--para p {
  margin: 0;
}

p:empty {
  display: none;
}

.Gr--streamImage span {
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: -6px;
  min-height: 180px;
}

.Gr--streamImage a img,
.Gr--streamImage img {
  height: 180px;
  object-fit: cover;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.Gr--streamImage:hover img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.Gr--streamDesc {
  padding: 0.5rem 0 0;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
}

.Gr--streamDesc p,
.Gr--streamDesc a {
  text-transform: capitalize;
  color: #a9a9b7;
  font-size: 14px;
}

.Gr--streamDesc a:hover,
.Gr--streamDesc a:hover p {
  color: #ffffff;
}

.Gr--streamDesc img {
  width: 46px;
  height: 46px;
  margin-right: 0;
  object-fit: cover;
  min-height: 46px;
  min-width: 46px;
  padding: 3px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.Gr--streamDesc p,
.Gr--streamUser p {
  margin: 0;
  font-size: 12px;
  color: #b3b3b3;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.Gr--streamUser p svg {
  margin-right: 0.5rem;
}

.Gr--streamUser {
  padding: 0 1rem 0;
  min-height: 46px;
}

.Gr--streamUser h4 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #ffffff;
  font-size: 1rem;
}

.Gr--streamUser h4 a {
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #ffffff;
  font-size: 20px;
  line-height: 1.5;
  text-transform: capitalize;
}

/*-=-=-New Css-=-==
**-=-=-=11-10-2023-=-=-=-*/

.Live--video--grid {
  display: flex;
  flex-flow: row wrap;
}

.Live--video--grid .big--video {
  flex: 1 1;
}

.Live--video--grid .small--video {
  flex: 1 1;
  max-width: 40%;
}

.Live--video--grid .small--video--inner {
  display: flex;
  flex-flow: row wrap;
  height: 50%;
}

.Live--video--grid .small--video--inner .Gr--slider--Desc {
  flex: 1 1;
}

.Live--video--grid .small--video--inner .Gr--slider--Desc + .Gr--slider--Desc {
  max-width: 50%;
}

.discover--module .Live--video--grid .Gr--slider--Desc {
  border-radius: 0;
  height: 100%;
}

.discover--module .Live--video--grid .small--video .Gr--slider--Desc {
  height: 50%;
}

.discover--module .Live--video--grid .small--video--inner .Gr--slider--Desc {
  height: 100%;
}

.discover--module .Live--video--grid .Gr--slider--Desc a.Gr--post--image {
  display: block;
  height: 100%;
  position: relative;
}

.discover--module
  .Live--video--grid
  .Gr--slider--Desc
  a.Gr--post--image
  .video--play--icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 4rem;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.discover--module
  .Live--video--grid
  .Gr--slider--Desc
  a.Gr--post--image:hover
  .video--play--icon {
  opacity: 1;
}

.discover--module .Live--video--grid .Gr--slider--Desc a.Gr--post--image img,
.discover--module .Live--video--grid .Gr--slider--Desc a.Gr--post--image video,
.discover--module
  .Live--video--grid
  .Gr--slider--Desc
  a.Gr--post--image
  .lazy-load-image-background {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Live--video--grid .small--video .Gr--slider--Desc a.Gr--post--image img,
.Live--video--grid .small--video .Gr--slider--Desc a.Gr--post--image video {
  height: 100%;
}

.discover--module .Live--video--grid .Gr--slider--Desc a.Gr--post--image img,
.discover--module .Live--video--grid .Gr--slider--Desc a.Gr--post--image video,
.discover--module
  .Live--video--grid
  .Gr--slider--Desc
  a.Gr--post--image
  .lazy-load-image-background {
  object-fit: cover;
  width: 100%;
  height: 280px;
}

.discover--module
  .Live--video--grid
  .big--video
  .Gr--slider--Desc
  a.Gr--post--image
  img,
.discover--module
  .Live--video--grid
  .big--video
  .Gr--slider--Desc
  a.Gr--post--image
  video,
.discover--module
  .Live--video--grid
  .big--video
  .Gr--slider--Desc
  a.Gr--post--image
  .lazy-load-image-background {
  object-fit: cover;
  width: 100%;
  height: 560px;
}

.discover--module .Live--video--grid .Gr--slider--Desc .Gr--userData h4,
.discover--module .Live--video--grid .Gr--slider--Desc .Gr--userData h4 a {
  font-size: 1.5rem;
  padding-bottom: 0.1rem;
}

.discover--module .Live--video--grid .Gr--slider--Desc .Gr--userData h4,
.discover--module .Live--video--grid .Gr--slider--Desc .Gr--userData h4 a {
  font-size: 1.2rem;
  padding-bottom: 0.1rem;
  text-transform: capitalize;
}

.discover--module
  .Live--video--grid
  .small--video
  .Gr--slider--Desc
  .Gr--userData
  h4,
.discover--module
  .Live--video--grid
  .small--video
  .Gr--slider--Desc
  .Gr--userData
  h4
  a {
  font-size: 1.2rem;
}

.discover--module .Live--video--grid .Gr--slider--Desc .Gr--userData p span {
  display: flex;
  margin-right: 1rem;
  align-items: center;
  font-size: 14px;
}

.discover--module
  .Live--video--grid
  .Gr--slider--Desc
  .Gr--userData
  p
  span
  svg {
  margin-right: 0.5rem;
}

.discover--module .Live--video--grid .Gr--slider--Desc .Gr--userRComments {
  padding: 1rem 2rem 2rem;
}

.discover--module
  .Live--video--grid
  .small--video
  .Gr--slider--Desc
  .Gr--userRComments {
  padding: 1rem 1rem 2rem;
}

.slick-track {
  margin-left: 0;
  margin-right: 0;
}

.discover--main--page h4.category--title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #33333360;
  padding-bottom: 1rem;
  margin-bottom: 2.5rem;
  position: relative;
  text-transform: uppercase;
}

.discover--main--page h4.category--title::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 60px;
  height: 5px;
  background: #8c49a3;
}

.discover--main--page h4.category--title .btn {
  margin-left: auto;
  padding: 0.25rem 0.65rem;
  font-size: 0.85rem;
}

div#liveVideo_grid {
  display: grid;
  grid-template-columns: 60% 20% 20%;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.bigVideo__wrap:nth-child(1) {
  grid-area: 1 / 1 / 3 / 2;
}

.bigVideo__wrap:nth-child(2) {
  grid-area: 1 / 2 / 2 / 4;
}

.bigVideo__wrap:nth-child(3) {
  grid-area: 2 / 2 / 3 / 3;
}

.bigVideo__wrap:nth-child(4) {
  grid-area: 2 / 3 / 3 / 4;
}

div#liveVideo_grid .bigVideo__wrap:nth-child(1) a.Gr--post--image span img {
  height: 560px !important;
}

div#liveVideo_grid
  .bigVideo__wrap:nth-child(1)
  a.Gr--post--image
  .lazy-load-image-background {
  height: 560px !important;
}

/*-==-=-=-=-=
Media Css
-==--==-*/

@media (min-width: 1200px) and (max-width: 1366px) {
  div#liveVideo_grid {
    display: grid;
    grid-template-columns: 54% 23% 23%;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

@media screen and (max-width: 1250px) {
  .Live--video--grid .small--video {
    flex: 1 1;
    max-width: 59%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .discover--module .Gr--realmMPanel h4 {
    font-size: 18px;
  }

  .bigVideo__wrap.div1 {
    grid-area: 1 / 1 / 4 / 2;
  }

  .bigVideo__wrap.div2 {
    grid-area: 1 / 2 / 2 / 4;
  }

  .bigVideo__wrap.div3 {
    grid-area: 2 / 2 / 3 / 4;
  }

  .bigVideo__wrap.div4 {
    grid-area: 3 / 2 / 4 / 4;
  }

  div#liveVideo_grid a.Gr--post--image .lazy-load-image-background {
    object-fit: cover;
    width: 100%;
    height: 183px;
  }
}

@media screen and (max-width: 1030px) {
  .bigVideo__wrap.div1 {
    grid-area: 1 / 1 / 4 / 2;
  }

  .bigVideo__wrap.div2 {
    grid-area: 1 / 2 / 2 / 4;
  }

  .bigVideo__wrap.div3 {
    grid-area: 2 / 2 / 3 / 4;
  }

  .bigVideo__wrap.div4 {
    grid-area: 3 / 2 / 4 / 4;
  }

  div#liveVideo_grid a.Gr--post--image .lazy-load-image-background {
    object-fit: cover;
    width: 100%;
    height: 183px;
  }
}

@media screen and (max-width: 991px) {
  .discover--module .Live--video--grid .Gr--slider--Desc .Gr--userRComments {
    padding: 1rem 1rem 1rem;
  }

  .discover--module .discover--Slider .slick-slide.slick-current {
    margin-right: -150px;
    padding-right: 180px;
  }

  .discover--module .discover--Slider .slick-slide {
    padding-right: 180px;
  }

  .discover--module .slick-slide .Gr--post--image img {
    height: 250px;
  }

  .Gr--streamImage a img,
  .Gr--streamImage img {
    height: 150px;
  }

  .discover--module {
    padding: 0.5rem 1rem 3rem 1rem;
  }

  .discover--module
    .Live--video--grid
    .small--video
    .Gr--slider--Desc
    .Gr--userRComments {
    flex-direction: column;
    text-align: left;
    align-items: baseline;
  }

  .discover--module
    .Live--video--grid
    .small--video
    .Gr--slider--Desc
    .Gr--userRComments
    img {
    margin: 0;
  }
}

@media screen and (max-width: 800px) {
  div#liveVideo_grid {
    display: grid;
    grid-template-columns: 35% 25% 40%;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .bigVideo__wrap.div1 {
    grid-area: 1 / 1 / 2 / 3;
  }

  .bigVideo__wrap.div2 {
    grid-area: 2 / 1 / 3 / 3;
  }

  .bigVideo__wrap.div3 {
    grid-area: 1 / 3 / 2 / 4;
  }

  .bigVideo__wrap.div4 {
    grid-area: 2 / 3 / 3 / 4;
  }

  div#liveVideo_grid
    .bigVideo__wrap:nth-child(1)
    a.Gr--post--image
    .lazy-load-image-background {
    height: 241px !important;
  }

  div#liveVideo_grid a.Gr--post--image .lazy-load-image-background {
    object-fit: cover;
    width: 100%;
    height: 241px;
  }
}

@media screen and (max-width: 767px) {
  div#liveVideo_grid
    .bigVideo__wrap:nth-child(1)
    a.Gr--post--image
    .lazy-load-image-background {
    height: 509px !important;
  }

  div#liveVideo_grid {
    display: grid;
    grid-template-columns: 25% 25% 50%;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .bigVideo__wrap.div4 {
    display: none;
  }

  .bigVideo__wrap.div1 {
    grid-area: 1 / 1 / 3 / 3;
  }

  .bigVideo__wrap.div2 {
    grid-area: 1 / 3 / 2 / 4;
  }

  .bigVideo__wrap.div3 {
    grid-area: 2 / 3 / 3 / 4;
  }

  .discover--module {
    padding: 0 0 2rem;
  }

  .discover--module .discover--Slider .slick-slide.slick-current {
    margin-right: 0;
    padding-right: 0;
  }

  .discover--module .discover--Slider .slick-slide {
    padding-right: 0;
  }

  .discover--module .slick-slide .Gr--post--image img {
    height: 250px;
  }

  .discover--module .Gr--realmMPanel {
    margin: 0 0 1rem;
  }

  .discover--module .Gr--slider--Desc .Gr--userRComments {
    padding: 1rem;
  }

  .discover--module .Gr--slider--Desc .Gr--userData h4,
  .discover--module .Gr--slider--Desc .Gr--userData h4 a {
    font-size: 1rem;
  }

  .Live--video--grid .big--video {
    flex: auto;
  }

  .Live--video--grid .small--video {
    flex: 1 1;
    max-width: 100%;
  }

  .Live--video--grid {
    flex-direction: column;
  }

  .big--video {
    height: 265px;
  }

  .discover--module .Live--video--grid .small--video .Gr--slider--Desc {
    width: 50%;
  }

  .Live--video--grid .small--video--inner {
    width: 47%;
    flex-direction: column;
  }

  .discover--module .Live--video--grid .small--video--inner .Gr--slider--Desc {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .Live--video--grid .small--video {
    display: flex;
  }

  .discover--module
    .Live--video--grid
    .small--video
    .Gr--slider--Desc
    .Gr--userRComments {
    padding: 1rem;
  }

  .discover--module
    .Live--video--grid
    .small--video--inner
    .Gr--slider--Desc
    + .Gr--slider--Desc {
    display: none;
  }

  .discover--module
    .Live--video--grid
    .big--video
    .Gr--slider--Desc
    a.Gr--post--image
    img {
    height: 286px !important;
  }
}

@media screen and (max-width: 590px) {
  .discover--module
    .Live--video--grid
    .small--video
    .Gr--slider--Desc
    .Gr--userData
    h4,
  .discover--module
    .Live--video--grid
    .small--video
    .Gr--slider--Desc
    .Gr--userData
    h4
    a {
    font-size: 1rem;
  }

  .discover--module .Live--video--grid .small--video .Gr--slider--Desc {
    height: 300px;
    width: 50%;
  }

  .discover--module .Live--video--grid .small--video--inner .Gr--slider--Desc {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  div#liveVideo_grid .Gr--userRComments {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    text-align: left;
  }

  div#liveVideo_grid .Gr--userRComments {
    align-items: start !important;
  }

  div#liveVideo_grid .Gr--userRComments > a {
    margin-left: 0px;
    margin-bottom: 5px;
  }

  div#liveVideo_grid h4,
  div#liveVideo_grid h4 a {
    font-size: 1rem !important;
    padding-bottom: 0.1rem;
    text-transform: capitalize;
    display: flex;
  }

  div#liveVideo_grid .Gr--userData h4 + a {
    display: flex;
    margin: 5px 0 0 0;
  }

  div#liveVideo_grid a.Gr--post--image .lazy-load-image-background {
    object-fit: cover;
    width: 100%;
    height: 219px;
  }

  div#liveVideo_grid
    .bigVideo__wrap:nth-child(1)
    a.Gr--post--image
    .lazy-load-image-background {
    height: 440px !important;
  }

  div#liveVideo_grid .Gr--slider--Desc .Gr--userData {
    flex: 1 1;
    color: #fff;
    margin-left: 0;
  }
}

@media screen and (max-width: 547px) {
  .Live--video--grid .small--video--inner {
    width: 100%;
    flex-direction: row;
  }

  .discover--module .Live--video--grid .small--video .Gr--slider--Desc {
    height: 200px;
    width: 100%;
  }

  .Live--video--grid .small--video {
    display: block;
  }
}

@media screen and (max-width: 475px) {
  div#liveVideo_grid h4,
  div#liveVideo_grid h4 a {
    font-size: 0.9rem !important;
    padding-bottom: 0.1rem;
    text-transform: capitalize;
    display: flex;
  }

  div#liveVideo_grid .Gr--userData p span {
    display: flex;
    margin-right: 1rem;
    align-items: center;
    font-size: 12px;
  }

  .discover--module .Live--video--grid .Gr--slider--Desc .Gr--userRComments {
    flex-direction: column;
    text-align: left;
  }

  .Live--video--grid .small--video--inner {
    width: 100%;
    flex-direction: row;
  }

  .discover--module .Live--video--grid .small--video .Gr--slider--Desc {
    height: 200px;
    width: 100%;
  }

  .Live--video--grid .small--video {
    display: block;
  }

  .Live--video--grid .small--video--inner {
    width: 100%;
    flex-direction: column;
  }

  .discover--module .Live--video--grid .small--video .Gr--slider--Desc {
    height: 300px;
  }

  .discover--main--page h4.category--title .btn {
    font-size: 13px;
  }

  .discover--module .Live--video--grid .Gr--slider--Desc .Gr--userRComments {
    flex-direction: column;
    text-align: left;
    align-items: baseline;
    row-gap: 4px;
  }

  .discover--module .Live--video--grid .small--video .Gr--slider--Desc {
    height: 151px;
  }
}

@media screen and (max-width: 414px) {
  div#liveVideo_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .bigVideo__wrap.div1 {
    grid-area: 1 / 1 / 2 / 3;
  }

  .bigVideo__wrap.div2 {
    grid-area: 2 / 1 / 3 / 2;
  }

  .bigVideo__wrap.div3 {
    grid-area: 2 / 2 / 3 / 3;
  }

  div#liveVideo_grid
    .bigVideo__wrap:nth-child(1)
    a.Gr--post--image
    .lazy-load-image-background {
    height: 212px !important;
  }

  div#liveVideo_grid a.Gr--post--image .lazy-load-image-background {
    object-fit: cover;
    width: 100%;
    height: 212px;
  }
}

@media screen and (max-width: 396px) {
  div#liveVideo_grid
    .bigVideo__wrap:nth-child(1)
    a.Gr--post--image
    .lazy-load-image-background {
    height: 295px !important;
  }

  div#liveVideo_grid a.Gr--post--image .lazy-load-image-background {
    object-fit: cover;
    width: 100%;
    height: 295px;
  }
}

@media screen and (max-width: 360px) {
  div#liveVideo_grid
    .bigVideo__wrap:nth-child(1)
    a.Gr--post--image
    .lazy-load-image-background {
    height: 295px !important;
  }

  .bigVideo__wrap.div2 {
    display: none;
  }

  .bigVideo__wrap.div3 {
    display: none;
  }

  div#liveVideo_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}