.Gx--chat--user, .Gx--chatt--box {
    height: calc(100vh - 150px);
    overflow: hidden;
}

.Gx--chat--user {
    background: rgba(49, 53, 61, 0.65);
}

.Gx--chat--user form {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding: 1rem 1.25rem;
}

.list-group-back{
   background: #8c49a3 !important
}

.Gx--chat--user form input {
    flex: 1 1;
    background: #191b1f;
    color: #ffffff;
    border: 0;
    padding: 0 1rem;
    height: 41px;
    font-size: 0.85rem;
    margin-right: 0.5rem;
}

.Gx--chat--user form button {
    padding: 0 0.5rem;
    height: 41px;
    border: 0;
    background: #191b1f;
    font-size: 1.4rem;
}


.Gx--chat--user .chat--user--list {
    height: 100%;
    max-height: calc(100% - 75px);
    overflow-y: auto;
    overflow-x: hidden;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    border-radius: 0;
}

.Gx--chat--user .chat--user--list::-webkit-scrollbar {
    width: 0;
}

.Gx--chat--user .list-group-item {
    background: none;
    border: 0;
    color: #ffffff;
    cursor: pointer;
    border-radius: 0;
    border-bottom: 1px solid #ffffff10;
}

.Gx--chat--user .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.Gx--chat--user .list-group-item .user--data .user--count {
    margin-left: auto;
    background: #3E31AF;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    line-height: 22px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.Gx--chat--user .list--selected {
    background: #1c1f28;
}

.Gx--chat--user .list-group-item:last-child {
    border-bottom: 0;
}

.Gx--chat--user .list-group-item .user--data, .Gx--chatt--box .user--data {
    display: flex;
    align-items: center;
}

.Gx--chat--user .user--icon, .Gx--chatt--box .user--icon {
    width: 40px;
    height: 40px;
    margin-right: 1rem;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    position: relative;
    /* overflow: hidden; */
}

.Gx--chat--user .user--icon img, .Gx--chatt--box .user--icon img {
    border-radius: 100px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    -webkit-border-radius: 100px;
}

.user--data .status--circle {
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100px;
}

.user--data .offline--red {
    background: red;
}

.user--data .online--green {
    background: green;
}

.user--data .offline--idle {
    background: goldenrod;
}

.user--name h5 {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.user--name p {
    margin: 0;
    /* color: #94989e; */
    font-size: 12px;
}

.user--name p span {
    display: block;
}

.Gx--chatt--box {
    background: rgba(49, 53, 61, 0.65);
    position: relative;
    border-left: 1px solid #ffffff20;
}

.Gx--chatt--box .user--data {
    padding: 1rem;
    border-bottom: 1px solid #ffffff10;
}

.Gx--chatt--box .user--name p {
    display: flex;
    align-items: center;
}

.Gx--chatt--box .user--data .status--circle {
    position: relative;
    left: 0;
    top: auto;
    width: 8px;
    height: 8px;
    margin: 0;
    margin-right: 0.25rem;
    vertical-align: middle;
    display: inline-block;
}

.user--chat--body {
    display: flex;
    flex-flow: column wrap;
    padding: 2rem 1rem;
}

.user--chat--body .user--stack {
    display: flex;
    align-items: center;
}

.user--chat--body .user--stack img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    margin-right: 1rem;
}

.user--chat--body .user--stack small {
    display: block;
    text-align: right;
    color: #ffffff50;
}

.user--chat--body .reply--stack {
    flex: 1 1;
    justify-content: flex-end;
}

.user--chat--body .reply--stack img {
    margin-left: 1rem;
    margin-right: 0;
}

.user--message {
    background: #ffffff10;
    padding: 10px 12px;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    position: relative;
}

.user--message video, .user--message iframe, .user--message img {
    max-width: 100%;
}

.chat--action--buttons {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
}

.edit--message .input {
    background: #ffffff10;
    color: #ffffff;
    border: 0;
    margin-bottom: 0.2rem;
}

.edit--message--action {
    text-align: right;
}

.edit--message--action span {
    cursor: pointer;
    margin-left: 0.25rem;
}

.edit--message--action+small {
    display: none !important;
}

.download--file a {
    display: flex;
    color: #ededed;
    text-decoration: none;
}

.user--chat--body .user--message .chat--action--buttons span {
    background: #242425;
    cursor: pointer;
    opacity: 0;
    padding: 0.25rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
}

.user--chat--body .user--message:hover .chat--action--buttons span {
    opacity: 1;
}

.chat--user--area {
    max-height: calc(100% - 200px);
    max-height: calc(100% - 228px);
    height: 100%;
    overflow-y: auto;
    position: relative;
    overflow-x: visible;
}

.chat--input--box {
    position: absolute;
    position: -webkit-sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(49, 53, 61, 0.65);
    padding: 1rem;
}

.chat--input--box form {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.chat--input--box form .form-control {
    flex: 1 1;
    background: transparent;
    color: #fff;
    /* color: #1e2331; */
    border: 0;
    padding: 0 1rem;
    height: 40px;
    font-size: 0.85rem;
    margin-right: 0;
    border-radius: 0;
}

.chat--input--box form .chat--attach--file,
.chat--input--box form .btn-secondary {
    background: #ffffff10;
    color: #ffffff80;
    font-size: 1rem;
    padding: 0 0.8rem;
    border: 0;
    border-left: 1px solid #ffffff15;
    line-height: 40px;
    cursor: pointer;
    margin-right: 0;
    height: 40px;
    border-radius: 0;
    margin: 0;
}

.chat--input--box form .btn-secondary {
    margin-right: 1rem;
}

.chat--input--box form .btn-primary {
    padding: 0 1rem;
    min-height: 40px;
    background: #ffffff10;
    border: 0;
}

.user-lists {
    height: calc(100vh - 290px);
    overflow-y: auto;
    margin-top: 1rem;
    overflow-x: hidden;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

.user-lists::-webkit-scrollbar {
    width: 0 !important;
}

.user-lists h4 {
    font-size: 17px;
    text-align: center;
    font-weight: 500;
}
  
.userSingle {
    position: relative;
    margin-bottom: 0;
    border: 1px solid transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 0.5rem 1rem;
}
  
.userSingle.active {
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fff;
}
  
.userSingle:last-child {
    margin-bottom: 0;
}
  
.userSingle h5 {
    font-size: 15px;
    margin: 0;
    text-transform: capitalize;
}
  
.userSingle p {
    font-size: 13px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}
  
.userSingle .timeSpan {
    position: absolute;
    right: -5px;
    top: -5px;
    font-size: 10px;
    color: #777;
    text-overflow: inherit;
    width: auto;
    white-space: normal;
    overflow: visible;
}  

.model-chat-main-wraper-mainn .search-bar input {
    flex: 1 1;
    background: #191b1f;
    color: #ffffff;
    border: 0;
    padding: 0 1rem;
    height: 41px;
    font-size: 0.85rem;
    margin-right: 0.5rem;
}

.model-chat-main-wraper-mainn .modal-body {
    padding: 1rem;
}

.model-chat-main-wraper-mainn .user-lists {
    margin-top: 0;
}

.user-list-invite-main, .img-list-user-main-wraper {
    display: flex;
    align-items: center;
}

.img-list-user-main-wraper img {
    width: 40px;
    margin-right: 1rem;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    height: 40px;
    object-fit: cover;
    position: relative;
}

.user-list-invite-main .btn-primary {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
}

.user-list-invite-main .btn-primary img {
    max-width: 30px;
}

.model-chat-main-wraper-mainn .userSingle {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding: 0.5rem 0;
}

/* Msg Loader custom class */
.msg-loader {
    width: 25px !important;
    height: 25px !important;
    margin: 5px auto !important;
}
.discover--module.common--wrapper.messenger--main .comments--panel {
    height: 100%;
}
.discover--module.common--wrapper.messenger--main  .comments--box{
    height: 100%;
}

.discover--module.common--wrapper.messenger--main  .Gx--chat--user .chat--user--list,
.discover--module.common--wrapper.messenger--main  .Gx--chatt--box,
.discover--module.common--wrapper.messenger--main  .Gx--chat--user{
    max-height: 100%;
    /* height: 100%; */
}
.file-upload-single {
    position: relative;
    top: unset;
    left: unset;
    background: #292b33;
    line-height: 26px;
    padding: 1px 13px;
    border-radius: 50px;
}

.file-upload-single span + svg {
    padding-left: 2px;
    cursor:pointer;
    position: absolute;
}

.file-upload-single span.file-upload-icon {
    background: #ffffff10;
    padding: 3px;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    position: relative;
    display: flex;
}

.react-fancybox .box .image-box .close-btn {width: 27px;height: auto;right: -9px;top: -10px;}

.react-fancybox .box .image-box .close-btn + img {
    width: auto;
    height: auto;
    border-radius: unset;
    margin: 0;
}

.react-fancybox .box {
    max-width: 400px;
    margin: auto;
    left: 0;
    height: fit-content;
    top: 0;
    bottom: 0;
}
.react-fancybox .thumbnail img {
    border-radius: 5px;
    border: 1px solid #44464d;
    margin-left: auto;
}

.react-fancybox .thumbnail {
    text-align: right;
}
.fade.model-chat-main-wraper-mainn.modal.show .text-center.spinner--loader {
    position: absolute;
    top: -37px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}
.fade.model-chat-main-wraper-mainn.modal .modal-content {
    min-height: 200px;
}



.file-upload-single {
    background: none;
  }
  .file-upload-single span + svg {
    position: absolute;
    top: 0;
    right: 11px;
  }


  .chat--input--box form .form-control {
    padding: 6px 11px;
    height: 89px;
    width: 100%;
  }
  .file-upload-single span.file-upload-icon img {
    width: 60px;
    object-fit: contain;
    object-position: bottom;
  }
  .file-upload-single span + svg {
    right: 9px;
    top: -4px;
  }
  .chat--input--box form {
    display: flex;
    align-items: center;
    flex-flow: unset;
    flex-direction: column;
    background: #ffffff10;
  }

.file-upload-single {
    background: none;
  }
  .file-upload-single span + svg {
    position: absolute;
    top: 0;
    right: 11px;
  }

  .chat--input--box form .form-control {
    padding: 6px 11px;
    min-height: 63px !important;
    width: 100%;
  }
  .file-upload-single span.file-upload-icon img ,  .file-upload-single span.file-upload-icon video{
    width: 45px;
    object-position: bottom;
    border-radius: 5px;
    border: 1px solid #44464d;
    margin-left: auto;
    height: 45px;
    object-fit: cover;
  }
  .file-upload-single span.file-upload-icon video {
    height: 41px;
    width: 46px;
    object-fit: cover;
  }
  .file-upload-single span + svg {
    right: 9px;
    top: -4px;
  }
  .iconWrappers {
    /* position: absolute; */
    right: 27px;
    bottom: 26px;
    margin: auto;
    height: fit-content;
  }
  .chat--input--box {
    background: rgb(49, 53, 61);
  }
  .Gx--chatt--box video {
    width: 200px;
    height: 130px;
    object-fit: cover;
}

.Gx--chatt--box .user--message {
    padding: 6px;
}

.react-fancybox + small {
    padding-top: 3px;
}

.react-fancybox .thumbnail img {
    width: 200px;
    height: 130px;
    object-fit: cover;
    margin-right: 0;
    cursor: pointer
}
.file-uplaode-box {
    display: flex;
    justify-content: start;
    margin-right: auto;
}
.iconWrappers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: unset;
    width: 100%;
    padding: 9px 15px;
}
audio {
    width: 175px;
    height: 31px;
    filter: invert(1);
}

body:has(audio) span.file-upload-icon {
    background: #0e0c0b !important;
}
span.file-upload-icon {
    display: flex;
    align-items: center;
    gap: 5px;
}

span.file-upload-icon h6 {
    margin: 0 !important;
    font-size: 12px;
}
.audioWrapper {
    background: #0e0c0b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px 10px 0;
}
.audioWrapper .live-text {
    background: #bf0a0a;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 21px;
    padding: 0px 5px 0px 3px;
    border-radius: 2px;
}
.audioWrapper span.live-word {
    font-size: 11px;
}
.audioWrapper audio{
    width: 100% !important;
}
/*-=-==-==-=-==-=
Media css-=-=-=-*/

@media (min-width:768px) and (max-width:1199px) {
    .user--name p {
        font-size: 11px;
    }
}

@media screen and (max-width:767px) {
    .discover--module.common--wrapper.messenger--main  .Gx--chat--user .chat--user--list,
    .discover--module.common--wrapper.messenger--main  .Gx--chatt--box,
    .discover--module.common--wrapper.messenger--main  .Gx--chat--user{
        max-height: 100%;
        height: fit-content 
    }
    .Gx--chat--user {
        margin-bottom: 1rem;
    }

    .Gx--chatt--box {
        border-left: 0;
    }
}

@media screen and (max-width:575px) {
    .chat--user--area {
        max-height: 50vh;
        height: 100%;
        overflow-y: auto;
        position: relative;
        overflow-x: visible;
    }
    
    .user--chat--body .user--stack span {
        min-width: calc(100% - 60px)
    }
}