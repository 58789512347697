.Profile--page .profile--banner {
  text-align: center;
}

.profile--banner .profile--thumb {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}

.Profile--page .profile--banner img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border: 5px solid #ffffff20;
  border-radius: 100px;
  -webkit-border-radius: 100px;
}

.profile--banner .profile--thumb svg {
  position: absolute;
  left: unset;
  top: unset;
  bottom: 5px;
  right: 5px;
  margin: auto;
  font-size: 2rem;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
}

.profile--banner .profile--thumb:hover svg {
  transform: scale(1.1);
  -webkit-transform: scale(1);
}

.Profile--page .profile--banner h4 {
  margin: 1rem 0 0;
}

.Profile--page .profile--banner p {
  max-width: 600px;
  width: 100%;
  margin: auto;
}

.Profile--page .grid--view .Gr--streamImage img {
  height: 180px;
}

.Profile--page .list--view .Gr--streamImage a img {
  height: auto;
}

.Profile--page span.play--icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #313445;
  background: #ffffff;
  font-size: 1.8rem;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  margin: auto;
  z-index: 111;
  cursor: pointer;
}

.Profile--page .Gr--streamImage:hover span.play--icon {
  color: #ffffff;
  background: #33365e;
}

.Tabs--horizontal {
  display: flex;
  flex-flow: column wrap;
}

.Tabs--horizontal .nav-tabs {
  display: flex;
  flex-flow: row wrap;
  border: 0;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.Tabs--horizontal .tab-content .tab-pane {
  background: none;
  padding: 0;
}

.Tabs--horizontal .nav-tabs .nav-item {
  margin: 0 0.5rem;
}

.Tabs--horizontal .nav-tabs .nav-link {
  border: 0;
  border-bottom: 2px solid #ffffff30;
  color: #ffffff;
  font-size: 1rem;
  border-radius: 0;
  -webkit-border-radius: 0;
  display: block;
  padding: 0 0 0.5rem 0;
  width: 100%;
  background: transparent;
  margin: 0;
}

.Tabs--horizontal .nav-tabs .nav-item.show .nav-link,
.Tabs--horizontal .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: transparent;
  border-color: #8c49a3;
  outline: none;
  box-shadow: none;
}

.Tabs--horizontal .nav-tabs .nav-item .nav-link:hover,
.Tabs--horizontal .nav-tabs .nav-item .nav-link:focus {
  color: #ffffff;
  background-color: transparent;
  border-color: #8c49a3;
  outline: none;
  box-shadow: none;
}

.Profile--page .list-group {
  justify-content: flex-end;
  margin-bottom: 0;
}

.Profile--page .list-group-item {
  width: auto;
  font-size: 1.1rem;
  padding: 0.2rem 0.5rem 0.3rem;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 0;
}

.Profile--page .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #33365e;
  border-color: #33365e;
  border-radius: 0;
}

.Profile--page .list-group-item.inactive {
  z-index: 2;
  background-color: #fff;
  color: #33365e;
  border-color: #ffffff;
  border-radius: 0;
}

.Profile--page
  .list-group-horizontal
  > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.Profile--page
  .list-group-horizontal
  > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.Profile--page .dropdown-toggle {
  color: #ffffff;
  width: 28px;
  height: 28px;
  line-height: 12px;
  z-index: 111;
  font-size: 1rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  padding: 0;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  border: 0;
}

.Profile--page .dropdown-toggle:hover {
  background: #313445;
}

.Profile--page .dropdown-toggle svg {
  font-size: 1rem;
  width: auto;
  vertical-align: middle;
  height: auto;
}

.Profile--page .dropdown-toggle::after {
  display: none;
}

.Profile--page .dropdown-menu {
  min-width: 0;
  padding: 0;
  font-size: 0.75rem;
  color: #ffffff;
  text-align: left;
  list-style: none;
  background-color: #313445;
  border: 0;
  overflow: hidden;
}

.Profile--page .dropdown-menu .dropdown-item {
  font-size: 0.75rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.Profile--page .dropdown-menu .dropdown-item:hover {
  background: #33365e;
  color: #ffffff;
}

.Profile--page .dropdown-menu .dropdown-item svg {
  margin-right: 0.4rem;
}

.Profile--page .live--comments,
.Profile--page .comments--box,
.Profile--page .video--controls {
  display: none;
}

.Profile--page .list--view .live--comments,
.Profile--page .list--view .comments--box,
.Profile--page .list--view .video--controls {
  display: block;
}

.Profile--page .list--view .live--comments {
  padding: 1rem;
}

.Profile--page .list--view .live--comments button {
  right: 1.5rem;
}

.Profile--page .list--view .comments--box {
  background: none;
  padding: 1rem;
  margin-top: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
}

.Profile--page .Gr--streamImage video {
  width: 100%;
  height: 100%;
}

.Profile--page .video--block {
  margin-bottom: 0;
  background: none;
  padding: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
}

.Profile--page .video--block div {
  width: 100% !important;
  height: 100% !important;
}

.Profile--page .video--block .video--controls .list-group {
  margin: 0;
}

.Gr--singleStream {
  position: relative;
}

.Gr--singleStream .schedule--button {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  z-index: 111;
  background: #33365e;
  padding: 0.25rem 0.65rem;
  color: #ffffff;
  font-size: 0.75rem;
  border: 0;
}

.stream--page .streamerMList {
  background: rgba(49, 53, 61, 0.65);
  padding: 1rem;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  list-style: none;
}

.stream--page .streamerMList li {
  color: #fff;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

.stream--page .streamerMList li:last-child {
  margin-bottom: 0;
}

.stream--page .streamerMList li a {
  display: flex;
  align-items: center;
  width: 100%;
}

.stream--page .streamerIcon {
  position: relative;
  text-align: center;
  padding: 2px;
  box-shadow: 0 0 0 2px transparent;
  -webkit-box-shadow: 0 0 0 2px transparent;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  display: inline-block;
  width: 50px;
  height: 50px;
  flex-flow: wrap;
  display: flex;
}

.stream--page .streamerIcon img {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  object-fit: cover;
}

.stream--page .streamerMList li p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  flex: auto;
  margin-left: 0.5rem;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
  flex-wrap: wrap;
}

.stream--page .card-group {
  flex-flow: row nowrap;
  overflow-x: auto;
  margin: 0 -0.5rem;
}

.stream--page .card-group::-webkit-scrollbar {
  display: none;
}

.stream--page .card-group::-webkit-scrollbar-button {
  display: none;
}

.stream--page .card {
  border: 0;
  background: no-repeat;
  position: relative;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  overflow: hidden;
  padding: 0 0.5rem;
  flex: 0 0 18.6666%;
  max-width: 18.6666%;
  cursor: pointer;
}

.stream--page .card .card-body {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: calc(100% - 16px);
  height: 100%;
  background: rgba(49, 53, 61, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
}

.stream--page .card-body .card-title {
  color: #ffffff;
  margin: 0;
  text-transform: capitalize;
  font-weight: 700;
}

.stream--page .card .card-img-top {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.stream--page .card-group > .card:not(:last-child) .card-img-top {
  border-top-right-radius: 0.5rem;
}

.stream--page .card-group > .card:not(:first-child) .card-img-top {
  border-top-left-radius: 0.5rem;
}

.type--feed--post,
.single--stream--post {
  background: rgba(49, 53, 61, 0.65);
  padding: 1.5rem 1rem 1rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
}

.type--feed--post .type--here,
.post--options {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ffffff20;
}

.post--options {
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.type--feed--post .type--comment {
  flex: 1 1;
}

.type--feed--post .type--here .user--avatar {
  display: inline-block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 100px;
  margin-right: 1rem;
}

.type--feed--post .type--here .user--avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.type--feed--post .type--here .form-control {
  width: 100%;
  background: none;
  border: 0;
  color: #ffffff;
}

.type--feed--post .type--comment input::-webkit-input-placeholder {
  font-size: 1.5rem;
  color: #ffffff;
}

.type--feed--post .type--here textarea {
  resize: none;
}

.post--options .list-group,
.single--stream--post .list-group {
  display: flex;
  align-items: center;
  background: none;
  flex-flow: row wrap;
  margin-right: 1rem;
}

.single--stream--post .list-group {
  margin-right: 0;
}

.post--options .list-group-item,
.single--stream--post .list-group-item {
  border: 0;
  background: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
}

.post--options .list-group-item:hover,
.single--stream--post .list-group-item:hover {
  background: rgba(49, 53, 61, 0.9);
}

.single--stream--post {
  padding: 1.5rem 0 1rem;
}

.single--stream--post .Gr--stream-user {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding: 0 1rem;
  margin-bottom: 1rem;
  position: relative;
}

.single--stream--post .Gr--stream-user .dropdown {
  position: absolute;
  right: 1rem;
  top: 0;
}

.single--stream--post .Gr--stream-user .dropdown-toggle {
  color: #ffffff;
  width: 28px;
  height: 28px;
  line-height: 12px;
  z-index: 111;
  font-size: 1rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  padding: 0;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  border: 0;
}

.single--stream--post .Gr--stream-user .dropdown-toggle:hover {
  background: #313445;
}

.single--stream--post .Gr--stream-user .dropdown-toggle svg {
  font-size: 1rem;
  width: auto;
  vertical-align: middle;
  height: auto;
}

.single--stream--post .Gr--stream-user .dropdown-toggle::after {
  display: none;
}

.single--stream--post .Gr--stream-user .dropdown-menu {
  min-width: 0;
  padding: 0;
  font-size: 0.75rem;
  color: #ffffff;
  text-align: left;
  list-style: none;
  background-color: #313445;
  border: 0;
  overflow: hidden;
}

.single--stream--post .Gr--stream-user .dropdown-menu .dropdown-item {
  font-size: 0.75rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
}

.single--stream--post .Gr--stream-user .dropdown-menu .dropdown-item:hover,
.single--stream--post .Gr--stream-user .dropdown-menu .dropdown-item:focus,
.single--stream--post .Gr--stream-user .dropdown-menu .dropdown-item:active {
  background: #33365e;
  color: #ffffff;
}

.single--stream--post .Gr--stream-user .dropdown-menu .dropdown-item p {
  margin: 0;
}

.single--stream--post .Gr--stream-user img {
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 100px;
  margin-right: 0;
  object-fit: cover;
}

.Gr--streamPost--content img,
.Gr--streamPost--content video {
  width: 100%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.single--stream--post .list-group {
  padding: 0 1rem;
  justify-content: space-between;
}

.single--stream--post .list-group-item {
  border: 0;
  background: none;
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 0.875rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.single--stream--post .list-group-item svg {
  font-size: 1.5rem;
}

.grid--view .Gr--streamPost--content {
  height: 235px;
  overflow: hidden;
}

.grid--view .Gr--streamPost--content a {
  color: #fff;
}

.list--view .Gr--streamPost--content a {
  color: #fff;
}

.single--stream--post .Gr--streamPost--content pre {
  font-family: inherit;
  padding: 0 1rem;
  font-size: 1rem;
  line-height: 1.7;
}

.single--stream--post .Gr--stream-user .Gr--streamDesc {
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.single--stream--post .list-group-item a {
  color: #ffffff;
}

.Gr--streamPost--content a,
.Gr--streamPost--content span {
  height: 100%;
}

.Gr--streamPost--content a,
.Gr--streamPost--content a span,
.Gr--streamPost--content img {
  height: 100%;
  display: block;
}

.Profile--page .grid--view .Gr--streamImage img {
  height: 245px;
}

.rbc-show-more {
  color: #ffffff !important;
  padding: 0.25rem 0.5rem !important;
}

.rbc-calendar {
  margin-top: 1rem;
}

.rbc-today .rbc-button-link {
  color: #333333;
  font-weight: 500;
}

.Gr--streamImage .pre--para {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -moz-line-clamp: 10;
  line-clamp: 10;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  padding: 10px 10px 0;
}

.Gr--streamImage .pre--para p {
  margin: 0;
}

p:empty {
  display: none;
}

/*-==-=-=-=-=
Media Css
-==--==-*/

@media screen and (max-width: 991px) {
  .Gr--streamImage a img,
  .Gr--streamImage img {
    height: 150px;
  }
}

@media screen and (max-width: 767px) {
  .discover--module {
    padding: 0 0 2rem;
  }
}

@media screen and (max-width: 400px) {
  .Profile--page .profile--banner img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    border: 3px solid #ffffff20;
    border-radius: 100px;
    -webkit-border-radius: 100px;
  }
}