.navbar-brand .collapse--icon {
    display: none;
}

.navbar-brand img {
    filter: invert(1);
    -webkit-filter: invert(1);
    max-width: 150px;
}

.navbar-brand {
    margin-right: 0;
    padding: 0;
    display: block;
}

.navbar {
    align-items: flex-start;
    padding: 0;
    min-width: 210px;
    min-width: 80px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.navbar-expand-sm {
    flex-flow: column wrap;
    justify-content: flex-start;
}

.navbar-expand .navbar-collapse {
    display: block !important;
}

.navbar-expand .navbar-collapse {
    position: fixed;
    left: 0;
    top: 80px;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    flex: 0 0 80px;
    max-width: 210px;
    max-width: 80px;
    background-color: #191b1f;
    padding: 1rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    box-shadow: none;
    -webkit-box-shadow: none;
    scrollbar-width: thin;
    scrollbar-color: #313131 transparent;
    border-right: 1px solid #31313150;
}

.navbar-expand .navbar-nav {
    flex-direction: column;
    width: 100%;
}

.navbar .navbar-nav .nav-link {
    cursor: pointer;
    padding: 0;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: #a9a9b7;
    line-height: 25px;
    margin-right: 0;
    padding: 15px 20px;
    margin-bottom: 18px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar .navbar-nav .nav-link:last-child {
    margin-bottom: 0;
}

.navbar .navbar-nav .nav-link svg {
    margin-right: 13px;
    margin-right: 0;
    width: 32px;
    height: auto;
    min-width: 32px;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link:hover {
    color: #8c49a3;
    background: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:hover {
    color: #8c49a3;
    background: #ffffff05;
    border-radius: 0;
}

/*-==-=-=-=-=
SideBar Collapsible css
-==-=-=-=*/

.btn--Click,
.button--Toggle {
    position: fixed;
    left: 210px;
    left: 80px;
    z-index: 999999;
    border: 0;
    background: #191b1f;
    color: #ffffff;
    font-size: 30px;
    width: 22px;
    height: 31px;
    line-height: 16px;
    top: 81px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    border-radius: 0 0.4rem 0.4rem 0;
    -webkit-border-radius: 0 0.4rem 0.4rem 0;
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, .25), 0 8px 16px -8px rgba(0, 0, 0, .3);
    -webkit-box-shadow: 0 13px 27px -5px rgba(50, 50, 93, .25), 0 8px 16px -8px rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #31313150;
    padding-bottom: 1px;
}

.button--Toggle {
    left: 80px;
    left: 0px;
}

.btn--Click:focus-visible,
.button--Toggle:focus-visible {
    outline: none;
}

.button--Toggle span {
    display: block;
    transform: rotate(180deg) translateY(-2px);
}

.collapse--Nav {
    min-width: 80px;
    min-width: 0px;
}

.navbar-expand {
    flex-wrap: wrap;
}

.navbar .Gr--List--realm {
    padding: 0;
    margin: 0;
    border-top: 1px solid #ffffff10;
    padding-top: 1rem;
    margin-top: 0;
}

.Gr--List--realm li {
    text-align: center;
    margin-bottom: 0;
}

.Gr--List--realm li a {
    text-align: center;
    margin-bottom: 0;
    display: block;
    padding: 15px 15px;
}

.Gr--List--realm li a:hover {
    background: #ffffff05;
}

.Gr--List--realm li a img {
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    width: 35px;
    height: 35px;
    max-width: 35px;
    object-fit: cover;
}

.collapse--Nav .navbar-collapse {

    padding: 1rem 0;
    max-width: 80px;
    flex: 0 0 80px;
    max-width: 0px;
    flex: 0 0 0px;
}

.collapse--Nav .navbar-brand {
    display: block;
    padding-top: 0;
    width: 100%;
}

.collapse--Nav .navbar-brand .collapse--icon {
    display: block;
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
}

.collapse--Nav .navbar-brand img,
.collapse--Nav .navbar-nav .nav-link span {
    display: none;
}

.collapse--Nav .navbar-nav .nav-link svg {
    margin-right: 0;
}

a.nav-link span {
    display: none !important;
}

.Gr--List--realm li a {
    padding: 10px;
}

.Gr--List--realm li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

div#basic-navbar-nav {
    z-index: 111;
}

button.button--Toggle {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    box-shadow: -1px 2px 5px rgb(0 0 0 / 62%);
    -webkit-box-shadow: -1px 2px 5px rgb(0 0 0 / 62%);
}

/*-=-==-=-
Media Css
-=-==-=-=-*/

@media (min-width:992px) and (max-width:1199px) {
    .discover--module .Gr--realmMPanel {
        padding: 0.5rem;
    }

    .discover--module .Gr--realmMPanel h4 {
        font-size: 1rem;
    }

    .collapse--Nav .navbar-collapse {
        padding: 0;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .navbar-expand .navbar-collapse {
        padding: 0;
        top: 56px;
    }

    .new-header--elements .react-autosuggest__container input {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        height: 40px;
        padding-left: 1rem;
    }
}

@media screen and (max-width:767px) {

    .btn--Click,
    .button--Toggle {
        left: 0px;
        top: 80px;
    }

    .button--Toggle {
        left: 50px;
    }

    .btn--Click span {
        transform: rotate(180deg) translateY(-2px);
        display: block;
        -webkit-transform: rotate(180deg) translateY(-2px);
        -moz-transform: rotate(180deg) translateY(-2px);
        -ms-transform: rotate(180deg) translateY(-2px);
        -o-transform: rotate(180deg) translateY(-2px);
    }

    .button--Toggle span {
        display: block;
        transform: rotate(0deg) translateY(-2px);
    }

    .navbar {
        min-width: 0px;
    }

    .collapse--Nav02 .navbar-collapse {
        flex: 0 0 0px;
        max-width: 0px;
        padding: 0rem;
    }

    .collapse--Nav .navbar-collapse {
        flex: 0 0 80px;
        max-width: 80px;
        padding: 1rem 0rem;
    }

    .collapse--Nav .navbar-nav .nav-link svg+span {
        font-size: 0;
    }

    .navbar-brand {
        display: block;
        width: 100%;
    }

    .collapse--Nav .navbar-nav {
        padding-top: 85px;
    }

    body:has(.collapse--header) .collapse--Nav .navbar-nav {

        padding-top: 10px;
    }

    .Gr--List--realm li a {
        padding: 15px 8px;
    }

    .navbar-brand .collapse--icon {
        display: block;
        color: #ffffff;
        font-size: 2.5rem;
        font-weight: 500;
        text-align: center;
    }

    .navbar .navbar-nav .nav-link span,
    .navbar-brand img {
        display: none;
    }

    .collapse--Nav .navbar-brand .collapse--icon {
        display: none;
    }

    .collapse--Nav .navbar-brand img,
    .collapse--Nav .navbar-nav .nav-link span {
        display: block;
    }

    .navbar .navbar-nav .nav-link svg {
        margin-right: 0;
    }

    .collapse--Nav .navbar-nav .nav-link svg {
        margin-right: 0px;
    }

    .navbar .navbar-nav .nav-link {
        padding: 10px;
        justify-content: center;
    }

    .collapse--Nav .navbar-nav .nav-link {
        padding: 10px 10px;
    }

    .navbar .navbar-nav .nav-link svg {
        width: 26px;
        min-width: 26px;
    }

    .Gr--List--realm li a img {
        width: 26px;
        height: 26px;
        max-width: 26px;
    }

    .navbar-brand {
        padding: 0;
    }

    div#basic-navbar-nav {
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
    }

}

@media screen and (max-width: 374px) {
    .collapse--Nav .navbar-nav {
        padding-top: 117px;
    }
}