@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #444444 transparent;
}

html {
  height: 100%;
}

#root {
  height: 100vh;
}

body {
  font-size: 14px;
  /* color: #ffffff; */
  color: rgba(255, 255, 255, 0.9);
  font-family: Poppins, sans-serif;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background: #191b1f;
}

img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

p {
  clear: left;
  line-height: normal;
}

pre {
  white-space: normal;
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: 2.1rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

.main--wrapper {
  height: 100vh;
}

span:empty {
  display: none;
}

.page--content {
  display: flex;
  flex-flow: row wrap;
  background: #191b1f;
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;
}

body:has(.discover--module.auth--screen) .page--content {
  padding-top: 0;
}

html:has(.discover--module.auth--screen) body {
  background: #191b1f;
}

/* .logged--in .page--content {
  height: auto;
} */

.common--wrapper {
  flex: 1 1;
  max-width: calc(100% - 0px);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.collapse--Nav + .common--wrapper {
  flex: 1 1;
  max-width: calc(100% - 0px);
}

.collapse--Nav02 + .common--wrapper {
  max-width: calc(100% - 80px);
}

.btn {
  padding: 0.5rem 1.5rem;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
}

.dropdown-item p {
  color: #fff !important;
}

.comments--box .btn a {
  color: inherit;
}

.date__select .react-calendar {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 111;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: #141414;
  background-color: #dddddd;
  border-color: #dddddd;
}

.btn-primary {
  color: #fff;
  min-width: 0;
  height: auto;
  background-color: #8c49a3;
  border-color: #8c49a3;
}

.btn-outline-primary {
  color: #8c49a3;
  min-width: 0;
  height: auto;
  background-color: transparent;
  border-color: #8c49a3;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #8c49a3;
  border-color: #8c49a3;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #33365e;
  border-color: #33365e;
  box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #33365e;
  border-color: #33365e;
  box-shadow: none;
}

.btn-secondary {
  color: #fff;
  background-color: #4b3f78;
  border-color: #4b3f78;
}

.btn-secondary:hover,
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-secondary:focus-visible {
  color: #fff;
  outline: none;
  box-shadow: none;
  background-color: #694690;
  border-color: #694690;
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}

.form-control:focus {
  color: #ffffff;
  background-color: rgba(49, 53, 61, 0.65);
  border-color: rgba(49, 53, 61, 0.65);
  outline: 0;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #ffffff70;
}

.form-control::-moz-placeholder {
  color: #ffffff70;
}

.form-control:-ms-input-placeholder {
  color: #ffffff70;
}

.form-control:-moz-placeholder {
  color: #ffffff70;
}

.modal-header .btn-close {
  color: #ffffff;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB3UlEQVR4nO2a3U4CQQyF51K50ZUXBoRnExLiCyiLr6EYdRq3FxJY5ufMTIv9koZwQU7P2cku29Q5wzAMwzCMcSa+Vr72vr587Xw9+rpt2dQA9bb01bvf3vrh+wQpsPb1faI2vjqUUALd0MOp3qhnSAirMwJcW9cmhG7QHuttgRB6uyDSIoQQ81Q7hNghQKhmCKHmqT4Qgn2gWI0QYsxTvSBEFxGCJUOINU81QwjTo+7cnbZWCCnm6Slwg2rgzsWH8OxrCtC+T9R+AGj/oUUIYswzNUMQZ56pEYJY80zJEMSbZ0qEoMY8gwxBnXkGEYJa80xOCOrNMyn/1raJv2k5gxgl5SSov/LHlApBhXkGHYIq8wwqBJXmmdwQVJtnUkO4CvNEynOeA0DME5qSav4qQsg1rzoElHmVIaDNqwqhlHkVIfzrl6GcV9qWI3cIiPd5tSEghxnqQigxyVETQskxlvgQaszwxIZQc4ApLoQW01sxIbQcXTcPQeuCxMaB9hiXkcKSVmTmCOF9hKC0JalXhOhnoJjENbl3hGDICZC6KAk5AZfuAZJXZSH3gLFl6bWTuyz95IDb7BQCLUzS7i2tztIG5swB9/AyIJN0pem4H4bPuZOxym8YhmEYhmB+AMapCuYx8aW9AAAAAElFTkSuQmCC)
    no-repeat center center;
  background-size: 20px;
  filter: invert(1) brightness(0.8);
}

.btn-close:focus,
.btn-close:focus-visible {
  box-shadow: none;
  outline: none;
  border: 0;
}

.discover--module {
  padding: 2.5rem 1rem 1rem 2rem;
}

.spinner--loader {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  width: 100px;
  height: 100px;
  margin: 2em auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: relative;
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
}

.spinner--loader:after,
.spinner--loader:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: inherit;
  height: inherit;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.spinner--loader:before {
  -webkit-transform: rotateX(70deg);
  transform: rotateX(70deg);
  -moz-transform: rotateX(70deg);
  -ms-transform: rotateX(70deg);
  -o-transform: rotateX(70deg);
}

.spinner--loader:after {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  -webkit-transform: rotateY(70deg);
  transform: rotateY(70deg);
  -moz-transform: rotateY(70deg);
  -ms-transform: rotateY(70deg);
  -o-transform: rotateY(70deg);
}

.react-calendar__month-view__days__day--weekend {
  color: #999999 !important;
}
.react-calendar__tile--now {
  background: #90ee90 !important;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: #888888;
}

@-webkit-keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0 0 0 #fff;
  }

  12% {
    box-shadow: 0.2em 0.2em 0 0 #fff;
  }

  25% {
    box-shadow: 0 0.2em 0 0 #fff;
  }

  37% {
    box-shadow: -0.2em 0.2em 0 0 #fff;
  }

  50% {
    box-shadow: -0.2em 0 0 0 #fff;
  }

  62% {
    box-shadow: -0.2em -0.2em 0 0 #fff;
  }

  75% {
    box-shadow: 0 -0.2em 0 0 #fff;
  }

  87% {
    box-shadow: 0.2em -0.2em 0 0 #fff;
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0 0 0 #fff;
  }

  12% {
    box-shadow: 0.2em 0.2em 0 0 #fff;
  }

  25% {
    box-shadow: 0 0.2em 0 0 #fff;
  }

  37% {
    box-shadow: -0.2em 0.2em 0 0 #fff;
  }

  50% {
    box-shadow: -0.2em 0 0 0 #fff;
  }

  62% {
    box-shadow: -0.2em -0.2em 0 0 #fff;
  }

  75% {
    box-shadow: 0 -0.2em 0 0 #fff;
  }

  87% {
    box-shadow: 0.2em -0.2em 0 0 #fff;
  }
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #444444;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: 0;
}

.Gr--streamUser pre,
.Gr--streamImage pre {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Gr--streamImage pre {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0.5rem;
}

.Gr--streamImage a {
  color: inherit;
  display: block;
  padding: 0;
}

.form-group {
  margin-bottom: 1rem;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #8c49a3;
  border-color: #8c49a3;
}

.form-control {
  appearance: none;
  background: #ffffff08;
  color: #ffffff;
  border: 1px solid #ffffff20;
}

.date__select {
  background: #ffffff08;
  color: #ffffff;
  border: 1px solid #ffffff20;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  position: relative;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.date__select .form-label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date__select .react-calendar {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
}

select.form-control {
  background: #ffffff08 url("assets/images/down-arrow.png");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 17px;
}

.modal-footer {
  border: 0;
}

.custom-quill-editor .ql-editor {
  max-height: 5em;
  overflow-y: auto;
}

.custom-quill-editor .ql-container {
  max-height: 200px;
}

.custom-quill-editor .ql-toolbar {
  display: none;
}

.main-wraper-service-book .react-calendar {
  width: 100%;
}

.modal {
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999;
}

.editor--tool {
  background: #ffffff20;
  width: 30px;
  display: flex;
  height: 30px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
}

.lightbox-container {
  z-index: 999999 !important;
}

.visual--desc button {
  color: #fff !important;
}

.noti--dropdown.show.dropdown a,
.noti--dropdown.show.dropdown button {
  color: #b74cdb !important;
}

.justify-content-center.align-items-center.checkout_wrapper.row
  .col-md-2.col-sm-2 {
  text-align: center;
}

.visual--user--name.Gr--streamDesc .Gr--streamUser h5,
.visual--user.Gr--streamDesc .Gr--streamUser h5 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #fff;
  display: -webkit-box;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  overflow: hidden;
  line-height: 1.2;
}

.visual--user.Gr--streamDesc .Gr--streamUser p {
  color: #b3b3b3;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 0 5px;
  text-transform: capitalize;
}

.visual--user--name.Gr--streamDesc,
.visual--user.Gr--streamDesc {
  padding: 0;
}

.discover--module.common--wrapper.visual--single--page
  button.btn--active.px-0.btn.btn-link {
  text-transform: capitalize;
}

.video--user.Gr--streamDesc {
  padding: 0;
}

.video--user.Gr--streamDesc .Gr--streamUser h2 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #fff;
  display: -webkit-box;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  overflow: hidden;
  line-height: 1.2;
}

.video--user.Gr--streamDesc .Gr--streamUser p {
  color: #b3b3b3;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 0 5px;
  text-transform: capitalize;
}

.threecols.row .single--stream--post.fullimgnew {
  background-color: rgba(49, 53, 61, 0.65);
  border-radius: 10px;
  border: 2px solid #5b5d60;
  -webkit-border-radius: 16px;
  overflow: hidden;
  padding-bottom: 15px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
  margin-top: 0 !important;
}

.threecols.row .stream--postActions {
  display: none;
}

.threecols.row .single--stream--post.fullimgnew {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 0 !important;
}

.threecols.row .single--stream--post.fullimgnew .Gr--streamPost--content img {
  height: 245px;
  object-fit: cover;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.threecols.row .single--stream--post .Gr--stream-user {
  margin-top: 16px;
  margin-bottom: 0;
}

.video--main--page .list--view.row .Gr--streamDesc p svg,
.video--main--page .list--view.row .Gr--streamUser h4 {
  display: none;
}

.video--main--page .list--view.row .Gr--streamUser p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 12px;
  color: #b3b3b3;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 1.4;
}

.video--main--page .list--view.row .Gr--streamUser a p {
  margin-bottom: 0.5rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #ffffff;
  font-size: 1rem;
}

.video--main--page .list--view .Gr--singleStream .wrap {
  display: flex;
  flex-direction: column-reverse;
}

.video--main--page .list--view .wrap .Gr--streamDesc {
  padding-top: 18px;
}

.video--main--page .list--view .Gr--streamImage {
  margin-top: 0 !important;
}

.video--main--page .list--view span.like--action.list-viewshow {
  width: 100%;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
}

.video--main--page .grid--view.row .Gr--streamDesc {
  padding: 8px 20px 0px;
}

.video--main--page .grid--view.row .Gr--streamImage a img {
  height: 245px;
  object-fit: cover;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.video--main--page .list--view .Gr--streamImage a img {
  height: 461px;
  width: auto;
  margin: 0 auto;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.video--main--page .list--view .Gr--streamImage a {
  text-align: center;
  margin: 20px 0;
}

.video--main--page .list--view .Gr--streamImage {
  background: #292c33;
}

.video--main--page .list--view .Gr--streamDesc {
  display: flex;
  align-items: center;
  background: #292c33;
  margin: 0;
  padding: 18px 18px 10px;
}

.video--main--page .list--view .Gr--streamImage {
  padding: 0 !important;
  margin-top: 12px !important;
  margin-bottom: 0;
}

.video--main--page .list--view .Gr--singleStream {
  margin: 0 !important;
  padding: 0;
}

.visual--user--name.Gr--streamDesc {
  width: 100%;
}

.discover--module.common--wrapper.visual--single--page
  button.btn--active.btn.btn-link {
  text-align: left;
  text-decoration: none;
  font-weight: 700;
}

.discover--module.common--wrapper.visual--single--page button.btn.btn-link {
  text-align: left;
  text-decoration: none;
}

.discover--module.common--wrapper.visual--single--page
  button.btn--active.px-0.btn.btn-link
  b {
  margin-right: 5px;
}

.headng {
  font-size: 16px;
}

.notification {
  margin: 0;
  padding: 0;
}

.discover--module.common--wrapper.stream--page
  .Gr--streamPost--content
  div
  > p {
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.discover--module.common--wrapper.stream--page
  .Gr--streamPost--content
  div
  > p
  strong {
  margin-bottom: 10px !important;
  display: block;
  font-family: Poppins, sans-serif !important;
  font-weight: 500;
  font-size: 1rem;
}

.discover--module.common--wrapper.stream--page
  .Gr--streamPost--content
  div
  > p
  span {
  background: none !important;
  color: #fff !important;
  font-family: Poppins, sans-serif !important;
  font-size: 13px !important;
  line-height: 22px;
}

.streamDec.streamDecShow {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  /* or nowrap depending on your needs */
}

.Gr--streamPost--content.contextonlywrap {
  padding: 20px;
}

.Gr--streamPost--content.contextonlywrap a a {
  margin-top: 10px;
  text-decoration: underline;
  text-underline-offset: 5px;
}

a {
  cursor: pointer;
}

.text-end.col-sm-12 div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #3a3a3a;
  padding-bottom: 16px;
  border-top: 1px solid #3a3a3a;
  padding-top: 14px;
}

.text-end.col-sm-12 div p {
  margin: 0;
}

.btn--Click {
  display: none !important;
}

.ROOT_CSS {
  height: 600;
  width: 400;
}

.comments--panel.new-comments--panel > .comments--box {
  padding-bottom: 21px !important;
}

body:has(.comments--box > .visual--user--name)
  .comments--panel.new-comments--panel
  > .comments--box {
  padding-bottom: 34px !important;
}

.comments--panel.new-comments--panel {
  min-height: 57px;
}

.wrapper--comments {
  height: 100%;
}

.comments--panel.new-comments--panel > .comments--box {
  height: calc(100% - 50px) !important;
  max-height: 80vh;
}

.comments--panel,
.comments--panel.new-comments--panel .live--comments {
  border-top: 1px solid #252525 !important;
  background: #0b0e14 !important;
  background: transparent !important;
}

.playerActive .page--content {
  padding-bottom: 70px;
}

body
  .ReactModal__Overlay.ReactModal__Overlay--after-open
  .ril-inner.ril__inner
  img {
  transform: unset !important;
}

.single--stream--post .Gr--stream-user .dropdown-toggle {
  background: transparent !important;
}

video#videoGalaxy {
  position: relative;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.galaxy-miniplayer-infobar {
  height: 33px;
  flex: none;
  display: flexbox;
  display: flex;
  flex-direction: row;
  background: #101010;
}

.galaxy-miniplayer-inner {
  padding: 10px 12px;
  overflow: hidden;
  cursor: pointer;
  flex: 1;
  flex-basis: 1e-9px;
}

.galaxy-miniplayer-inner h1 {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

div#video-container-galaxy-miniplayer {
  position: fixed;
  z-index: 2018;
  right: 0;
  bottom: 0;
  background-color: var(--yt-spec-brand-background-primary);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  height: 202px;
  max-width: 300px;
  width: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5px;
  border: 3px solid #101010;
  padding-bottom: 31px;
}

button.galaxy-miniplayer-button {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 18px;
  height: 18px;
  background: transparent;
  border: none;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  background: #3d3b3b;
  border-radius: 50px;
}

button.galaxy-miniplayer-button svg {
  width: 12px;
  opacity: 0.9;
  height: 13px;
  position: relative;
  top: -2px;
}

div#video-container-galaxy-miniplayer:hover:after {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  div#video-container-galaxy-miniplayer:hover button.galaxy-miniplayer-button {
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  div#video-container-galaxy-miniplayer button.galaxy-miniplayer-button {
    opacity: 1;
  }
}

.streamDec.streamDecShow p {
  padding: 0 !important;
}

.Gr--streamPost--content.contextonlywrap {
  padding: 0;
}

.Gr--streamPost--content.contextonlywrap * {
  padding-left: 0;
}

.streamDec.streamDecShow {
  padding-left: 0 !important;
}

.single--stream--post .streamDec {
  padding: 0;
}

.single--stream--post h2 {
  padding: 0;
}

.single--stream--post {
  padding-left: 20px;
  padding-right: 20px;
}

a.showHidebtn {
  padding-left: 0;
}

.single--stream--post .list-group {
  padding: 0;
}

.single--stream--post .Gr--streamPost--content {
  margin: 0;
}

.single--stream--post .Gr--stream-user {
  padding: 0;
}

/* p {
  word-break: break-all;
} */
.videoGalaxy {
  width: 100% !important;
  height: 100% !important;
}

.single--stream--post {
  position: relative;
}

button.liveButton + .Gr--stream-user {
  top: 9px;
  position: relative;
}

.liveButton {
  background: #4caf50;
  color: #fff;
  border: none;
  padding: 4px 6px;
  border-radius: 2px;
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 13px;
  font-weight: 400;
}

.booking--details {
  height: 100%;
  padding: 3rem 0;
}

.booking--details .list-group {
  margin-bottom: 1rem;
}

.booking--details .list-group-item {
  background: #ffffff08;
  border: 1px solid #ffffff20;
  color: #ffffff;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.booking--details .list-group-item span {
  margin-left: auto;
  font-weight: 600;
  font-size: 16px;
}

.chat--sharing--screen {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  align-items: center;
  overflow: hidden;
}

.screen--sharing {
  flex: auto;
}

.video--screen--chat {
  padding: 2rem 0 0;
  position: relative;
  right: -100%;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  transition: right .4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.chat--window--toggle .screen--sharing {
  flex: 1 1;
}

.chat--window--toggle .video--screen--chat {
  right: 0;
}

.video--screen--chat .chat-container {
  padding: 0 1rem;
  height: calc(100vh - 106px);
  max-height: 100%;
  overflow-y: auto;
}

.video--screen--chat .chat-input {
  position: sticky;
  -webkit-position: sticky;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  display: flex;
  align-items: center;
}

.video--screen--chat .chat-input input {
  height: 42px;
  padding: 0 15px;
  width: 100%;
}

.video--screen--chat .chat-input button {
  height: 42px;
  padding: 0 15px;
  border-radius: 0;
  min-width: 70px;
}

.video--screen--chat .chat-container .chat-message+.chat-message {
  margin-top: .5rem;
}

.video--screen--chat .chat-container .chat-message strong {
  display: block;
}

.chat--toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .4s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-size: 25px;
}

.chat--close--toggle {
  right: 310px;
  bottom: auto;
  top: 0rem;
  border-radius: 0;
  width: 30px;
  height: 30px;
}

.chat--toggle .message--dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  position: absolute;
  right: 2px;
  top: 2px;
  background: green;
  border-radius: 100px;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(255,255,255, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(255,255,255, 0);
      box-shadow: 0 0 0 10px rgba(255,255,255, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
      box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}

.chat--close--toggle .message--dot {
  display: none;
}

.shared--screens {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.shared--screens .single--window {
  flex: 1 1 100%;
  max-width: 50%;
}

.shared--screens .single--window video {
  width: 100%;
  height: calc(100% - 120px);
  object-fit: cover;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

.shared--screens .user--window {
  position: relative;
}

.shared--screens .user--window .btn,
.shared--screens .mini--window .btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0b0e14;
  background: #ffffff;
  border: 0;
}

.shared--screens .mini--window {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  width: 200px;
  height: 250px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  overflow: hidden;
}

.shared--screens .mini--window video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.screening--buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 10px;
}

.screening--buttons button {
  padding: 0;
  border-radius: 100px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

.screening--buttons .btn-success {
  background: #4caf50;
  color: #ffffff;
}

.rbc-month-row {
  overflow: visible !important;
}

.rbc-date-cell {
  min-height: 80px;
}

.rbc-toolbar button {
  color: #ffffff !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active,
.rbc-toolbar button:hover {
  color: #373a3c !important;
}

.rbc-row-segment {
  padding: 0 6px 8px 6px !important;
}

.rbc-current .rbc-button-link {
  color: #0b0e14;
  font-weight: 700;
  font-size: 15px;
}

.rbc-toolbar .rbc-toolbar-label {
  padding: 0;
  text-align: right;
  font-weight: 600;
  font-size: 18px;
}

.calendar--icon {
  text-align: right;
  margin-bottom: 2rem;
}

.calendar--icon .btn {
  padding: 0.2rem 0.5rem 0.3rem;
}

.calendar--icon svg {
  font-size: 1.5rem;
}

/*-=-=-==-==-
Media css-=---=*/
@media screen and (max-width: 1200px) {
  .discover--module.common--wrapper.video--single--page .col-sm-12 {
    width: 100%;
  }

  video#remoteVideo {
    height: auto !important;
    width: 100vh !important;
  }
}

@media screen and (max-width: 991px) {
  .btn {
    padding: 0.5rem 0.8rem;
    font-size: 0.875rem;
  }

  .Edit--Profile--form .button--cover label {
    font-size: 14px;
  }

  h1 {
    font-size: 1.3rem;
  }

  .page--content {
    padding-top: 105px;
  }
}

@media screen and (max-width: 767px) {
  .btn--Click {
    display: flex !important;
  }

  .checkout_wrapper.row span.text-center.orCell {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .checkout_wrapper.row {
    text-align: center;
  }

  h2 {
    font-size: 1.4rem;
  }

  .common--wrapper {
    flex: 1 1;
    max-width: calc(100% - 0px);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }

  .collapse--Nav + .common--wrapper {
    flex: 1 1;
    max-width: calc(100% - 0px);
  }

  .collapse--Nav02 + .common--wrapper {
    max-width: calc(100% - 0px);
  }

  .discover--module.common--wrapper.stream--page
    .Gr--streamPost--content
    div
    > p {
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
  
  .page--content {
    padding-top: 115px;
  }
}

@media screen and (max-width:376px) {
  .page--content {
    padding-top: 115px;
  }
}

ul.rdw-dropdown-optionwrapper li {
  color: #444;
}

a.rdw-dropdown-selectedtext {
  color: #444;
}

a {
  color: #fff;
}

a.rdw-dropdown-selectedtext:hover {
  color: #444;
}

a.rdw-dropdown-selectedtext {
  color: #fff;
}

.comments--box .visual--user--name a {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 41px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.custom--add--times {
  margin-top: 1rem;
}

.custom--add--times form {
  display: flex;
  justify-content: flex-start;
  margin: 0 -5px;
}

.custom--add--times form .form-group {
  flex: 1 1;
}

.custom--add--times .list-group {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(auto, auto);
}

.custom--add--times .list-group-item {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  background-color: rgba(49, 53, 61, 0.65);
  color: #ffffff;
  border: 0;
  border-radius: 0;
}

.custom--add--times .list-group-item p {
  margin-bottom: 0;
}

.custom--add--times .list-group-item .form-select {
  background-color: transparent;
  border-color: #ffffff50;
  color: #ffffff90;
}

.custom--add--times .list-group-item .form-select option {
  color: #0b0e14;
}

.custom--add--times .action--icons {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  font-size: 20px;
  margin-left: auto;
}

.custom--add--times .action--icons svg,
.remove--interval svg {
  cursor: pointer;
  height: auto;
  width: 30px;
  font-size: 30px;
}

.day--checkbox .form-check {
  margin-bottom: 0;
  text-transform: uppercase;
  min-width: 50px;
  margin-top: 0.5rem;
}

.custom--add--times .add--interval {
  margin-top: 0.15rem;
}

.time--slots {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
}

.time--slots .single--row {
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  margin-bottom: 0.5rem;
  align-items: center;
}

.time--slots .single--row .form-group {
  flex: 1 1;
}

.booking--date {
  display: flex;
  gap: 20px;
  flex-flow: row wrap;
}

.booking--date .book--calender {
  flex: 1 1;
}

.booking--date .meeting--time {
  border-right: 1px solid #ffffff20;
  padding-right: 20px;
}

.book--time--list {
  border-left: 1px solid #ffffff20;
  padding-left: 20px;
}

.book--time--list .list-group-item {
  background: none;
  border: 0;
  padding: 0;
  display: flex;
  gap: 10px;
}

.book--time--list .list-group-item label,
.book--time--list .list-group-item button {
  flex: 1 1;
}

.book--time--list {
  display: none;
}

.show--book--time {
  display: block;
}

.book--time--list .list-group {
  max-height: 380px;
  overflow-y: auto;
}

.book--time--list .list-group-item + .list-group-item {
  margin-top: 0.5rem;
}

.book--time--list [type="radio"] {
  position: absolute;
  display: none;
  left: -9999px;
}

.book--time--list [type="radio"] + label {
  position: relative;
  padding: 1rem 2rem;
  text-align: center;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  cursor: pointer;
  line-height: 20px;
  display: block;
  color: #ffffff;
  border: 1px solid #ffffff50;
  min-width: 122px;
}

.book--time--list [type="radio"]:checked + label {
  color: #ffffff;
  background: rgba(49, 53, 61, 0.65);
  border: 1px solid #ffffff30;
}

.book--time--list .list-group-item button {
  display: none;
}

.book--time--list [type="radio"]:checked + label + button {
  display: block;
}

.event--form form {
  flex-flow: row wrap;
}

.event--form form .form-check {
  flex: 1 1 100%;
  max-width: 33.333%;
  padding: 0 5px 10px;
  margin: 0;
}

.event--form form .form-check input {
  display: none;
}

.event--form form .form-check label {
  border: 1px solid #ffffff30;
  padding: 1rem 2rem;
  padding-left: 4rem;
  border-radius: 0.25rem;
  display: flex;
  margin: 0;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  height: 100%;
  align-items: center;
}

.event--form form .form-check input:checked + label {
  border: 1px solid #ffffff80;
}

.event--form form .form-check [type="radio"]:checked + label:before,
.event--form form .form-check [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 19px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 26px;
  height: 26px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.event--form form .form-check [type="radio"]:checked + label:after, .event--form form .form-check [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 20px;
  height: 20px;
  background: #8c49a3;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 22px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.event--form form .form-check [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.event--form form .form-check [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.event--form form .form-check label small {
	display: block;
	font-size: 100%;
  font-weight: normal;
  color: #b74cdb;
}

@media screen and (max-width:992px) {
  .event--form form .form-check {
    max-width: 50%;
  }
}

@media screen and (max-width:576px) {
  .event--form form .form-check {
    max-width: 100%;
  }
}