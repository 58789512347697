.disclosure_wrap .col-xl-8 {
    padding: 0rem 1rem 0rem;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    flex-direction: column;
    display: flex;
   }
   .list--view.disclosure_wrap .Gr--streamImage a img{
       height: 461px;
       width: auto;
       margin: 20px auto 20px !important;
       border-radius: 0;
   }
   .disclosure_wrap .Gr--streamImage {
       text-align:center;
   }
   .disclosure_wrap .Gr--streamImage{
       background: rgb(41 44 51) !important;
       
   }
   
   .disclosure_wrap .Gr--singleStream {
       padding: 1rem;
       display: flex;
       flex-direction: column-reverse;
       border:none;
   }
  .disclosure_wrap .Gr--streamUser  h2,   .disclosure_wrapp .Gr--streamUser  h2{
    margin-bottom: 0.5rem;
     font-weight: 500;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     overflow: hidden;
     color: #ffffff;
     font-size: 1rem;
 }
 .disclosure_wrapp .Gr--streamDesc {
    padding: 8px 20px 0px 20px;
}
.disclosure_wrapp .Gr--streamImage a img{
    height: 245px;
    object-fit: cover;
    width: 100%;
    transition: all .3s ease-in-out;
}