.auth--screen {
    padding: 3rem;
   /** padding-top: 0;
    padding-bottom: 100px;**/
    height: 100vh;
    flex: 1 1;
}

.auth--screen h4 {
    text-transform: uppercase;
    margin: 0 0 1rem;
    font-size: 1.8rem;
    font-weight: 500;
}

.modal .modal-content {
    background: #272727;
}

.modal-header {
    border: 0;
    padding: 1rem 2rem;
}

.modal-body {
    padding: 2rem;
}

.modal-body h4 {
    margin-bottom: 1rem;
}

.auth--screen form {
    max-width: 450px;
    width: 100%;
    margin: 2rem auto auto;
    background: #272727;
    padding: 2rem;
    padding-bottom: 3rem;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
}

.auth--screen form .form-control {
    background-color: transparent;
    border: 0;
    padding: 1rem 0;
    font-size: 1rem;
    height: 50px;
    color: #ffffff80;
    border-bottom: 1px solid #555555;
    border-radius: 0;
}

.auth--screen form .form-control:focus, .auth--screen form .form-control:focus-visible {
    background-color: transparent;
    color: #ffffff;
    box-shadow: none;
}

.auth--screen form span {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.auth--screen form .text-info {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-decoration: none;
    text-align: center;
    color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    color : #999999 !important;
    -webkit-text-fill-color: #999999 !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
}

.auth--screen .btn-primary, .auth--screen .btn-outline-primary {
    width: calc(100% - 100px);
    margin: auto;
    padding: 0.75rem 2rem;
}

@media screen and (max-width:767px) {
    .auth--screen {
        padding: 2rem 0;
    }
}