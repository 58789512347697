.product--single--page .back--btn {
    color: #dddddd;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.product--single--page .back--btn svg {
    margin-right: 0.3rem;
}

.product--images img {
    width: 100%;
    object-fit: cover;
    height: 550px;
    border-radius: 0.5rem;
    object-position: bottom center;
}

.product--title h1 {
    font-size: 1.8rem;
    margin: 0 0 1rem;
}

.product--title h3 {
    font-weight: 500;
    margin: 0 0 1rem;
}

.product--title .form-group label {
    display: block;
}

.product--title .form-group select {
    width: fit-content;
    vertical-align: top;
    padding: 0 1rem;
    text-align: center;
    color: #ffffff;
    border: 0;
    height: 42px;
    background: #31353d;
    margin: 0 1rem 0 0;
    display: inline-block;
}

.product--single--page .list-group-item {
    position: relative;
    display: block;
    padding: 0;
    color: #ffffff;
    position: relative;
    margin-top: 0.5rem;
    background-color: transparent;
    border: 0;
    padding-left: 1rem;
}

.product--single--page .list-group-item:before {
    position: absolute;
    left: 0;
    top: 7px;
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 100px;
    background-color: #ffffff;
}

.product--single--page .list-group-item strong {
    font-weight: 500;
}

.product--title .list-group {
    margin: 0 0 1rem;
}

.product--description h3 {
    border-bottom: 1px solid #ffffff15;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
}

.product--description h5 {
    margin-top: 2rem;
}

.create--shop--form form #product-price, .create--shop--form form #product-stock {
    color: #a9a9b7;
    width: 100%;
    padding: 0 1rem;
    height: 40px;
    background-color: #ffffff10;
    border: 0;
    border-radius: 0.25rem;
    font-size: 0.75rem;
}

.product--images {
    position: relative;
}

.product--images .expand--Lightbox {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: 70px;
    height: 70px;
    background: #4b3f7830;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    font-size: 25px;
    cursor: pointer;
}

/*-=-==-=-=-=
Media css-=-=-==-*/

@media screen and (max-width:991px) {
    .product--images {
        margin-bottom: 2rem;
    }

    .product--images img {
        height: auto;
    }

    .product--title .form-group input {
        padding: 0;
    }
}