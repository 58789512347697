.btn--live {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    margin-bottom: 2rem;
}
.video--main--page .list-group {
    justify-content: flex-end;
    margin-bottom: 0;
    margin-left: auto;
}

.video--main--page .list-group-item {
    width: auto;
    font-size: 1.1rem;
    padding: 0.1rem 0.4rem 0.2rem;
    cursor: pointer;
}

.video--main--page .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #33365e;
    border-color: #33365e;
}

.video--main--page .list-group-item.inactive {
    z-index: 2;
    background-color: #fff;
    color: #33365e;
    border-color: #ffffff;
}

.video--main--page .Gr--singleStream {
    /* background-color: rgba(49,53,61,.65);
    border-radius: 10px;
    -webkit-border-radius: 10px; */
    overflow: hidden;
    /* padding-bottom: 15px; */
    /* box-shadow: 1px 1px 1px rgba(0,0,0,.75); */
}

.video--main--page .Gr--streamImage {
    overflow: hidden;
    position: relative;
    /* padding: 1rem; */
    padding-bottom: 0;
}

.video--main--page .Gr--streamImage .play--icon {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #ffffff;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 2px solid #ffffff;
    background: #31344560;
    border-radius: 10px;
    z-index: 111;
    min-height: unset;
}
.video--main--page .Gr--streamImage a img, .Gr--streamImage img {
    height: 180px;
    object-fit: cover;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    width: 100%;
    transition: all .3s ease-in-out;
}

.video--main--page .Gr--streamImage:hover img {
    transform: scale(1);
    -webkit-transform: scale(1);
}

.video--main--page .Gr--streamDesc {
    padding: 0.5rem 0 0;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
}

.video--main--page .Gr--streamDesc p, .video--main--page .Gr--streamDesc a {
    text-transform: capitalize;
    color: #a9a9b7;
    font-size: 14px;
}

.video--main--page .Gr--streamDesc a:hover, .video--main--page .Gr--streamDesc a:hover p {
    color: #ffffff;
}

.video--main--page .Gr--streamDesc img {
    width: 46px;
    height: 46px;
    margin-right: 0;
    object-fit: cover;
    min-height: 46px;
    min-width: 46px;
    border: 1px solid #ffffff38;
    padding: 3px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.video--main--page .Gr--streamDesc p, .video--main--page .Gr--streamUser p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 12px;
    color: #b3b3b3;
    text-transform: capitalize;
    font-weight: 400;
    line-height: 1.4;
}

.video--main--page .Gr--streamUser h4 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #ffffff;
    font-size: 1rem;
}

.video--main--page .Gr--streamUser h4 a {
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #ffffff;
    font-size: 20px;
    line-height: 1.5;
    text-transform: capitalize;
}

.video--main--page .dropdown-toggle {
    color: #ffffff;
    width: 28px;
    height: 28px;
    line-height: 12px;
    z-index: 111;
    font-size: 1rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    padding: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border: 0;
    background-color: transparent !important;
}

.video--main--page .dropdown-toggle:hover {
    background: #313445;
}

.video--main--page .dropdown-toggle svg {
    font-size: 1rem;
    width: auto;
    vertical-align: middle;
    height: auto;
}

.video--main--page .dropdown-toggle::after {
    display: none;
}

.video--main--page .dropdown-menu {
    min-width: 0;
    padding: 0;
    font-size: 0.75rem;
    color: #ffffff;
    text-align: left;
    list-style: none;
    background-color: #313445;
    border: 0;
    overflow: hidden;
}

.video--main--page .dropdown-menu .dropdown-item {
    font-size: 0.75rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}

.video--main--page .dropdown-menu .dropdown-item:hover {
    background: #33365e;
    color: #ffffff;
}

.video--main--page .dropdown-menu .dropdown-item svg {
    margin-right: 0.4rem;
}

.list--view .Gr--streamImage a img, .Gr--streamImage img {
    height: auto;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.list--view .Gr--streamImage {
    padding: 0;
}

.list--view .Gr--streamImage:hover img {
    transform: scale(1);
    -webkit-transform: scale(1);
}

.grid--view .like--action {
    display: none;
}

.list--view .Gr--streamDesc {
    display: flex;
    align-items: center;
}

.list--view .like--action {
    display: inline-block;
    margin-left: auto;
    font-size: 1.1rem;
}

.list--view .like--action svg {
    margin-left: 1rem;
    cursor: pointer;
    font-size: 26px;
}

.video--main--page .list--view .dropdown-toggle {
    right: 1.5rem;
    top: 1.5rem;
}



/*-==-=-=-=-=
Media Css
-==--==-*/

@media screen and (max-width:991px) {
    .Gr--streamImage a img, .Gr--streamImage img {
        height: 150px;
    }
}

@media screen and (max-width:767px) {
    .discover--module {
        padding: 0 0 2rem;
    }

    .video--main--page .Gr--streamUser {
        padding: 0rem 0 0 0.5rem;
    }

    .video--main--page .Gr--streamUser h4 {
        margin-bottom: 0;
    }
    .list--view .Gr--streamImage a img, .Gr--streamImage img {
        height: 180px;
    }
    .discover--module.common--wrapper.video--main--page .Gr--singleStream {
        padding-bottom: 0;
    }
}