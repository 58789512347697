.product--category--page .back--btn {
    color: #dddddd;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.product--category--page .back--btn svg {
    margin-right: 0.3rem;
}

.product--banner {
    position: relative;
    background: #191b1f;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: 2rem;
}

.product--banner img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: cover;
    height: 200px;
}

.product--banner h1 {
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 500;
    font-size: 1.8rem;
    bottom: 0;
    padding: 0 1rem;
    text-align: center;
    margin: 0;
    right: 0;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.zoom--image {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    padding: 0;
    background: #191b1f;
    width: 35px;
    height: 35px;
    z-index: 111;
    opacity: 0.75;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.Gr--streamImage:hover .zoom--image {
    opacity: 1;
    transform: scale(1.1);
}

.product--category--page .Gr--streamUser p {
    font-size: 1.2rem;
    font-weight: 600;
}

.uploaded--file {
    display: inline-block;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    border: 1px solid #ffffff20;
    padding: .25rem .75rem;
    width: max-content;
}

.uploaded--file svg {
    color: #8c49a3;
    margin-left: .25rem;
    font-size: 14px;
    cursor: pointer;
} 