.player {
    background: #0e0c0b;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    z-index: 99999;
    width: 100%;
    right: 0;
    left: 0;
    max-width: unset;
    box-shadow: 0px -1px 11px #070707;
    border-top: 1px solid #2c2c2c;
}

.player .row {
    align-items: center;
    width: 100%;
}


.player button.shuffle.btn {
    background: none;
    border: none;
    color: #ccc;
}

.player button.shuffle.btn img {
    filter: invert(1);
    opacity: 0.7;
    transition: all 0.3s ease;
}

.player button.shuffle.btn:hover img,
.player button.shuffle.btn:active img,
.player button.shuffle.btn:focus img {
    opacity: 1;
}

.player button.listPlayer.btn {
    background: none;
    border: none;
    color: #ccc;
}

.player button.listPlayer.btn img {
    filter: invert(1);
    opacity: 0.7;
    transition: all 0.3s ease;
}

.player button.listPlayer.btn:hover img,
.player button.listPlayer.btn:active img,
.player button.listPlayer.btn:focus img {
    opacity: 1;
}

img.imglistPlayer {
    max-width: 24px;
}

img.imgshuffle {
    max-width: 30px;
}

.player .btn-primary:not(:disabled):not(.disabled).active,
.player .btn-primary:not(:disabled):not(.disabled):active,
.player .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.playerwrappermain video {
    filter: invert(1);
}

img.imgaudio {
    height: 50px !important;
    max-height: 50px !important;
    min-height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    min-width: 50px !important;
    object-fit: cover;
    object-position: center;
}

.imgwrap img {
    width: auto;
    max-width: max-content;
}

.player button.listPlayer.btn,
.player button.shuffle.btn {
    padding-left: 7px;
    padding-right: 7px;
}

button.shuffle.btn.btn-primary {
    margin-right: 10px;
}

.alignRight {
    display: flex;
    justify-content: flex-end;
}

.imgwrap img {
    width: auto;
    max-width: max-content;
    border-radius: 50px;
    padding: 5px;
    border: 2px solid #737373;
}

.rhap_container {
    background-color: #0e0c0b !important;
}

.rhap_time {
    color: #fff !important;
}

.rotate {
    -webkit-animation: rotate 10s normal linear infinite;
    animation: rotate 10s normal linear infinite;
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }

    25% {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
    }

    50% {
        -webkit-transform: rotate3d(0, 0, 1, 180deg);
        transform: rotate3d(0, 0, 1, 180deg);
    }

    75% {
        -webkit-transform: rotate3d(0, 0, 1, 270deg);
        transform: rotate3d(0, 0, 1, 270deg);
    }

    100% {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
    }
}

.nameWrap {
    font-size: 14px;
    text-transform: capitalize;
    display: flex;
    align-content: center;
    margin: 0;
    text-align: left;
    color: #ccc;
    height: fit-content;
    margin: auto 0;
}

.nameWrap {
    padding-left: 0;
}

.player button.closeBtn {
    background: #fff;
    /* border: none; */
    width: 29px;
    height: 29px;
    padding: 0;
    border-radius: 50px;
    position: absolute;
    top: -16px;
    border: 2px solid #625f5f;
    right: 3px;
    font-size: 19px;
    color: #000;
    line-height: 0px;
    cursor: pointer;
    z-index: 9
}

.imgwrap.col {
    display: flex;
    align-content: center;
    gap: 10px;
}

/** List Css **/

li.single-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #222227;
    height: 58px;
    cursor: pointer;
}

li.single-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1px;
    padding-bottom: 10px;
    border-bottom: 1px solid #222227;
    height: 58px;
    cursor: pointer;
    padding-top: 10px;
    padding: 10px;
}

li.single-item.active {
    background: #8c49a3;
    border-radius: 4px;
}

li.single-item .btnWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    transition: 0.5s ease;
    margin-right: 11px;
    cursor: pointer;
    background: #fff;
    width: 35px;
    height: 35px;
}

li.single-item .btnWrapper img.dNone {
    display: none;
}

li.single-item img {
    opacity: 0.9;
    transition: opacity 0.5s ease;
}

li.single-item .btnWrapper:hover {
    background: #8c49a3;
}

li.single-item .btnWrapper:hover img {
    filter: invert(1);
}

.single-item__title a {
    transition: 0.5s ease;
    transition-property: color, background-color, border-color, box-shadow;
}

.single-item__title h4 a {
    font-size: 14px;
    opacity: 0.9;
}

li.single-item:hover .single-item__title h4 a {
    color: #d782f5;
    opacity: 1;
}

.single-item__title h4 {
    margin-bottom: 0;
}

.single-item__title span a {
    font-size: 12px;
    color: #ccc;
}

li.single-item:hover img {
    opacity: 1;
}


.close-list {
    background: #fff;
    border: none;
    width: 25;
    height: 25px;
    padding: 0;
    /* border-radius: 50px; */
    position: absolute;
    top: 1px;
    border: 2px solid #625f5f;
    right: 0.7px;
    font-size: 19px;
    color: #000;
    line-height: 0px;
    cursor: pointer;
    z-index: 9;
}

.release__list {
    position: fixed;
    background: #000;
    padding: 20px;
    bottom: 67px;
    width: fit-content;
    right: 7px;
    box-shadow: 0px -1px 11px #070707;
    border: 1px solid #2c2c2c;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom: 0;
}

ul.main__list.main__list--playlist.main__list--dashbox {
    margin: 0 !important;
    padding: 0;
}

li.single-item:last-child {
    margin: 0;
}

.react-player audio {
    filter: invert(1);
    opacity: 0.7;
}

.player {
    background-color: #111010 !important;
}



@media screen and (max-width:767px) {
    img.imgaudio {
        height: 34px;
    }

    .imgwrap.col {
        width: auto;
        flex: unset;
    }

    img.imgshuffle {
        max-width: 20px;
    }

    img.imglistPlayer {
        max-width: 15px;
    }

    .nameWrap {
        font-size: 13px;
    }

    img.imgaudio {
        height: 40px !important;
        max-height: 40px !important;
        min-height: 40px !important;
        width: 40px !important;
        max-width: 40px !important;
        min-width: 40px !important;
    }

    button.shuffle.btn.btn-primary {
        margin-right: 0;
        padding: 0;
    }

    .alignRight {
        flex: unset;
        width: auto;
        padding-right: 0;
        margin-left: auto;
    }

    .visual--single--page {
        padding-bottom: 7rem;
    }
}

@media screen and (max-width:575px) {
    .playerwrappermain {
        width: 100%;
        order: 13;
        margin-top: .5rem;
    }

    .rhap_container {
        padding: 10px 0;
    }
}