.audio--main--page .list-group {
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.audio--main--page .list-group-item {
    width: auto;
    font-size: 1.1rem;
    padding: 0.1rem 0.4rem 0.2rem;
    cursor: pointer;
}

.audio--main--page .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #33365e;
    border-color: #33365e;
}

.audio--main--page .list-group-item.inactive {
    z-index: 2;
    background-color: #fff;
    color: #33365e;
    border-color: #ffffff;
}

.Gr--singleStream {
    background-color: rgba(49,53,61,.65);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    overflow: hidden;
    padding-bottom: 15px;
    box-shadow: 1px 1px 1px rgba(0,0,0,.75);
}

.Gr--streamImage {
    overflow: hidden;
    position: relative;
    background: #ffffff10;
    border-radius: 0.5rem;
    min-height: 180px;
    margin-bottom: 0.5rem;
}

.Gr--streamImage span {
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: -6px;
    min-height: 180px;
}

.Gr--streamImage a img, .Gr--streamImage img {
    height: 180px;
    object-fit: cover;
    width: 100%;
    transition: all .3s ease-in-out;
}

.Gr--streamImage:hover img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
}

.Gr--streamDesc {
    padding: 0.5rem 0 0;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
}

.Gr--streamDesc p, .Gr--streamDesc a {
    text-transform: capitalize;
    color: #a9a9b7;
    font-size: 14px;
}

.Gr--streamDesc a:hover, .Gr--streamDesc a:hover p {
    color: #ffffff;
}

.Gr--streamDesc img {
    width: 46px;
    height: 46px;
    margin-right: 0;
    object-fit: cover;
    min-height: 46px;
    min-width: 46px;
    /* border: 1px solid #ffffff38; */
    padding: 3px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.Gr--streamDesc p, .Gr--streamUser p {
    margin: 0;
    font-size: 12px;
    color: #b3b3b3;
    text-transform: capitalize;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.Gr--streamUser p svg {
    margin-right: 0.5rem;
}

.Gr--streamUser {
    padding: 0 1rem 0;
    min-height: 46px;
}

.Gr--streamUser h4 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #ffffff;
    font-size: 1rem;
}

.Gr--streamUser h4 a {
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #ffffff;
    font-size: 20px;
    line-height: 1.5;
    text-transform: capitalize;
}

.audio--main--page .dropdown-toggle {
    color: #ffffff;
    width: 28px;
    height: 28px;
    line-height: 12px;
    z-index: 111;
    font-size: 1rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    padding: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border: 0;
}

.audio--main--page .dropdown-toggle:hover {
    background: #313445;
}

.audio--main--page .dropdown-toggle svg {
    font-size: 1rem;
    width: auto;
    vertical-align: middle;
    height: auto;
}

.audio--main--page .dropdown-toggle::after {
    display: none;
}

.audio--main--page .dropdown-menu {
    min-width: 0;
    padding: 0;
    font-size: 0.75rem;
    color: #ffffff;
    text-align: left;
    list-style: none;
    background-color: #313445;
    border: 0;
    overflow: hidden;
}

.audio--main--page .dropdown-menu .dropdown-item {
    font-size: 0.75rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    text-transform: capitalize;
}

.audio--main--page .dropdown-menu .dropdown-item:hover, .audio--main--page .dropdown-menu .dropdown-item:focus, .audio--main--page .dropdown-menu .dropdown-item:active {
    background: #33365e;
    color: #ffffff;
}

.audio--main--page .dropdown-menu .dropdown-item svg {
    margin-right: 0.4rem;
}

.list--view .Gr--streamImage a img, .Gr--streamImage img {
    height: auto;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
}

.list--view .Gr--streamImage {
    padding: 0;
}

.list--view .Gr--streamImage:hover img {
    transform: scale(1);
    -webkit-transform: scale(1);
}

.grid--view .like--action {
    display: none;
}

.list--view .Gr--streamDesc {
    display: flex;
    align-items: center;
}

.list--view .like--action {
    display: inline-block;
    margin-left: auto;
    font-size: 1.1rem;
}

.list--view .like--action svg {
    margin-left: 1rem;
    cursor: pointer;
    font-size: 26px;
}

.audio--main--page .list--view .dropdown-toggle {
    right: 1.5rem;
    top: 1.5rem;
}

.visual--single--page {
    padding-bottom: 6rem;
}

.visual--single--page .back--btn {
    color: #dddddd;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.visual--single--page .back--btn svg {
    margin-right: 0.3rem;
}

.visual--single--page .dropdown {
    position: absolute;
    right: 0;
    top: 0;
}

.visual--single--page .dropdown-toggle {
    color: #ffffff;
    width: 28px;
    height: 28px;
    line-height: 12px;
    z-index: 111;
    font-size: 1rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    padding: 0;
    border: 0;
}

.visual--single--page .dropdown-toggle:hover {
    background: #313445;
}

.visual--single--page .dropdown-toggle svg {
    font-size: 1rem;
    width: auto;
    vertical-align: middle;
    height: auto;
}

.visual--single--page .dropdown-toggle::after {
    display: none;
}

.visual--single--page .dropdown-menu {
    min-width: 0;
    padding: 0;
    font-size: 0.75rem;
    color: #ffffff;
    text-align: left;
    list-style: none;
    background-color: #313445;
    border: 0;
    overflow: hidden;
}

.visual--single--page .dropdown-menu .dropdown-item {
    font-size: 0.75rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}

.visual--single--page .dropdown-menu .dropdown-item p {
    margin: 0;
}

.visual--single--page .dropdown-menu .dropdown-item:hover {
    background: #33365e;
    color: #ffffff;
}

.visual--single--page .dropdown-menu .dropdown-item svg {
    margin-right: 0.4rem;
}

.visual--block {
    position: relative;
    margin-bottom: 1rem;
    background: none;
    padding: 0;
    border-radius: 0;
}

.visual--block img {
    width: 100%;
    object-fit: contain;
    height: 500px;
}

.visual--block .fullscreen--icon {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 50px;
    background: #ffffff80;
    color: #333333;
    font-size: 2rem;
    padding: 0.3rem 0.6rem;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    cursor: pointer;
}

.visual--desc .btn-group-vertical .btn {
    text-decoration: none;
    margin-bottom: 0.25rem;
    color: rgba(var(--bs-light-rgb),var(--bs-text-opacity));
}

.visual--desc .btn-group-vertical .btn--active {
    color: #8148a1;
    text-decoration: underline;
}

.visual--user {
    /* border-style: solid; */
    /* border-color: #ffffff10; */
    /* border-width: 1px 0; */
    /* padding: 2rem 0; */
    /* margin-top: 1rem; */
    margin-bottom: 1rem;
    position: relative;
}

.visual--user,
.visual--user--name,
.visual--desc ul {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.visual--user--name img {
    width: 40px;
    margin-right: 1rem;
    border-radius: 100px;
    object-fit: cover;
    height: 40px;
}

.visual--user--name h5 {
    margin: 0;
}

.visual--user--name h5 span {
    display: block;
    color: #ffffff90;
    font-size: 80%;
    font-weight: normal;
}

.visual--user button {
    margin-left: auto;
    padding: 0.5rem 1rem;
}

.visual--desc {
    margin-bottom: 0;
}

.visual--desc p {
    /* word-break: normal; */
}

.visual--desc ul {
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-between;
}

.visual--desc ul li {
    margin-right: 1.5rem;
    color: #ffffff90;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.visual--desc ul li svg {
    margin-right: 0;
    width: 26px;
    height: auto;
}

.live--comments textarea.form-control {
    border: 0;
    resize: none;
    padding: 0.8rem 1.5rem 2rem;
    padding-right: 3.5rem;
    font-size: 1rem;
    background: none;
}

.comments--box {
    background: #292c33;
    padding: 1.5rem;
    margin-top: 2rem;
    border-radius: 0.5rem 0.5rem 0 0;
    -webkit-border-radius: 0.5rem 0.5rem 0 0;
}

.comments--box .visual--desc ul li {
    margin-right: 0.3rem;
    font-size: 0.75rem;
}

.comments--box .visual--desc ul li svg {
    width: 15px
}

.comments--box .visual--desc {
    margin-bottom: 0;
    flex: 1 1 100%;
    padding-left: 55px;
}

.comments--box .visual--user--name {
    margin-bottom: 0;
}

.comments--box .live--comments {
    flex: 1 1;
}

.comments--box .reply--box {
    padding-left: 60px;
}

.comments--box .hideComment,
.comments--box .hideTextarea {
    display: none;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.comments--box .showComment,
.comments--box .showTextarea {
    display: block;
    border-top: 1px solid #ffffff10;
    padding-top: 1rem;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.comments--box .visual--user--name h5 {
    font-weight: normal;
    font-size: 0.875rem;
}

.audio--upload--grid {
    background: #ffffff08;
    padding: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    -o-border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    position: relative;
}

.create--form form .audio--upload--grid .form-control {
    padding: 0;
    background: none;
    border-radius: 0;
    height: auto;
    margin-bottom: 0.5rem;
}

.audio--upload--grid .post--media--close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
}

.audio--upload--grid audio {
    width: 100%;
    height: 35px;
}

.create--form form   .audio--upload--grid .form-control {
    background: #191b1f;
    margin-bottom: 10px;
    padding: 9px;
    border-radius: 3px;
    width: 100%;
    color: #818594;
}

.create--form form  .audio--upload--grid .post--media--close {
    top: -8px;
    right: -4px;
    background: rgb(0 0 0 / 44%);
    border-radius: 50px;
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create--form form  .audio--upload--grid .post--media--close svg {
    width: 13px;
    height: 13px;
}

.perviewAudio.row .audio--upload--grid {
    display: grid;
    grid-template-columns: 25px 1fr;
    column-gap: 5px;
}

.perviewAudio.row span.numberWrap {
    background: rgb(65 67 76);
    border-radius: 50px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    padding: 3px;
    font-weight: 500;
    margin-top: 7px;
}
.audioPlayerWrapper:last-child {
    margin-bottom: 0;
}
.player {
    position: fixed;
    height: auto;
    bottom: 0;
    display: flex;
    width: 100%;
    background-color: #181a1d ;
    left: 0;
    z-index: 9999999;
    color: #fff;
    font-size: 14px;
}
.player .thumbnail img {
    width: auto !important;
}
.dropup {
    display: none;
}

/*-==-=-=-=-=
Media Css
-==--==-*/

@media screen and (max-width:991px) {
    .Gr--streamImage a img, .Gr--streamImage img {
        height: 150px;
    }
}

@media screen and (max-width:767px) {
    .discover--module {
        padding: 0 0 2rem;
    }

    .visual--desc {
        margin-bottom: 1rem;
    }

    .visual--user {
        padding: 1rem 0;
    }
}