.commentsPanel.newCommentsPanel {
    position: relative;
    padding: 0 !important;
    margin-bottom: 0 !important;
    height: auto;
    display: block;
    background: #292c33;
    border-radius: 0.5rem;
    width: 100%;
}

.wrapperComments {
    display: flex;
    width: 100%;
}

.commentsPanel.newCommentsPanel>.liveComments {
    position: relative;
}

.commentsPanel.newCommentsPanel>.commentsBox+.liveComments {
    position: absolute;
}

.commentsBox {
    position: relative;
    max-height: 500px;
    overflow: scroll;
    /* padding-bottom: 0px !important; */
    background: none;
    padding: 0;
    margin-top: 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    padding-bottom: 17px;
}

.visualUserName {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 41px;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-flow: row wrap;
}

.visualUserName a {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 41px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #fff;
    text-decoration: none;
}

.visualUserName a img,
.visualUserName img {
    width: 30px;
    margin-right: 0.7rem;
    border-radius: 100px;
    object-fit: cover;
    height: 30px;
}

.visualUserName a h5 {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
}

.visualUserName a h5 span {
    display: block;
    color: #ffffff90;
    font-size: 80%;
    font-weight: normal;
}

.visualDesc {
    margin-bottom: 0;
    flex: 1 1 100%;
    padding-left: 30px;
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    padding-left: 30px;
}

.visualDesc p {
    word-break: break-all;
    padding-left: 12px;
    padding-top: 10px;
    clear: left;
    margin-top: 0;
    margin-bottom: 1rem;
}

.visualDesc ul {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    position: absolute;
    right: 0;
    top: -34px;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-between;
}

.visualDesc ul li {
    margin-right: 0.3rem;
    font-size: 0.75rem;
}

.visualDesc ul li svg {
    margin-right: 0;
    width: 18px;
    height: 18px;
    opacity: 0.8;
    cursor: pointer;
}

.liveComments {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #2a2c33;
    padding: 6px;
    width: calc(100% - 12px);
    left: 0;
    right: 0;
    margin: auto;
    border-top: 1px solid #3a3a3a;
    align-items: center;
    display: flex;
}

.liveComments textarea {
    padding: 7px !important;
    width: calc(100% - 35px) !important;
    font-size: 14px !important;
    float: left;
    color: #fff !important;
    border: 0 !important;
    resize: none !important;
    background: none !important;
    appearance: none !important;
    height: unset !important;
}

.liveComments button {
    right: 0;
    font-size: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: auto;
    height: 36px;
    padding: 0 1rem;
    margin: auto 0;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    line-height: 1;
    cursor: pointer;
    color: #fff;
    min-width: 0;
    background-color: #8c49a3;
    border-color: #8c49a3;
}

.commentsBox .commentsBox {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    height: auto;
    max-height: unset;
    overflow: unset;
}

.showTextArea.replyBox {
    padding-left: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    margin-top: 5px;
    width: 100%;
    border-top: 1px solid #ffffff10;
    margin-bottom: 25px;
}

.showTextArea.replyBox .liveComments {
    width: 100%;
    align-items: center;
    display: flex;
    position: relative;
    padding: 0;
    margin: 0;
    border: none;
    flex: 1 1;
}

.liveComments .error.error-massege.text-danger {
    position: absolute;
    bottom: -10px;
    left: 12px;
}

.live--comments .error.error-massege.text-danger {
    position: absolute;
    bottom: 1px;
    left: 13px;
}

.wrapperComments {
    padding-top: 9px;
}

span.commentsLab svg {
    margin-left: 6px;
}

.create--form form textarea.form-control {
    padding: 12px;
}

.comt .user--frame {
    margin: 1rem 0 1rem;
}

.comt .user--frame {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.comtTimmer {
    text-align: right;
}

.comtTimmer p {
    margin: 0;
}

.comt .liveComments {
    margin-left: 0;
}

@media screen and (max-width:400px) {
    .visualDesc {
        margin-bottom: 0;
        flex: 1 1 100%;
        padding-left: 30px;
        position: relative;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        padding-left: 18px;
    }

    .visualDesc ul li svg {
        margin-right: 0;
        width: 15px;
        height: 15px;
        opacity: 0.8;
        cursor: pointer;
    }

    .visualDesc ul {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        position: absolute;
        right: 0;
        top: -53px;
        list-style: none;
        padding: 0;
        margin: 0;
        justify-content: space-between;
    }
}