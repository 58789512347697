.myShop--page .profile--banner {
    text-align: center;
    margin-bottom: 2rem;
}

.myShop--page .profile--banner .profile--thumb {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
}

.myShop--page .profile--banner .profile--thumb::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #00000050;
    border-radius: 100px;
}

.myShop--page .profile--banner img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: center;
    border: 5px solid #ffffff20;
    border-radius: 100px;
    -webkit-border-radius: 100px;
}

.myShop--page .profile--banner .profile--thumb svg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    font-size: 2rem;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transform: scale(0.85);
    -webkit-transform: scale(0.85);
}

.myShop--page .profile--banner .profile--thumb:hover svg {
    transform: scale(1.1);
    -webkit-transform: scale(1);
}

.myShop--page .profile--banner h4 {
    margin: 1rem 0 0;
}

.myShop--page .profile--banner p {
    max-width: 600px;
    width: 100%;
    margin: auto;
}

.order--table table td,
.order--table table th {
    vertical-align: middle;
    text-align: left;
}

.order--table table td a {
    color: #8c49a3;
}

.order--table table td .form-group {
    margin: 0;
}

.order--table h3 {
    text-align: center;
    border: 1px solid #ffffff10;
    padding: 10px;
}

.order--table .react-datepicker-wrapper {
    margin-left: 0.5rem;
}

.order--table .react-datepicker-wrapper .form-control {
    background: #ffffff10;
    border: 0;
    color: #ffffff80;
}

.order--table table th {
    min-width: 140px;
}

.order--table table th:nth-child(1) {
    min-width: 120px;
}

.order--table table th:nth-child(2) {
    min-width: 230px;
}

.order--table table td .pro--image {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.order--table table td img {
    object-fit: cover;
    object-position: center;
    width: 60px;
    height: 60px;
    border: 3px solid #ffffff30;
    margin-right: 1rem;
}

.order--table table td strong {
    flex: 1 1;
}

.order--table table td strong small {
    display: block;
}

.order--table table td button {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
}

.order--table table td address {
    margin: 0;
    min-width: 150px;
}

.draft--page .Gr--streamDesc {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding-bottom: 0;
    padding-top: 1rem;
}

.draft--page .Gr--streamDesc p small {
    display: block;
}

.draft--page .Gr--streamDesc img {
    margin-right: 10px;
    object-fit: cover;
    position: relative;
    top: auto;
    right: auto;
    height: 45px;
    width: 45px;
    min-height: 45px;
    min-width: 45px;
    border: 2px solid #ffffff30;
    padding: 0;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.draft--page .Gr--streamImage:hover img {
    transform: scale(1);
}

.draft--page .dropdown-toggle {
    color: #ffffff;
    width: 28px;
    height: 28px;
    line-height: 12px;
    z-index: 111;
    font-size: 1rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    padding: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border: 0;
}

.draft--page .dropdown-toggle:hover {
    background: #313445;
}

.draft--page .dropdown-toggle svg {
    font-size: 1rem;
    width: auto;
    vertical-align: middle;
    height: auto;
}

.draft--page .dropdown-toggle::after {
    display: none;
}

.draft--page .dropdown-menu {
    min-width: 0;
    padding: 0;
    font-size: 0.75rem;
    color: #ffffff;
    text-align: left;
    list-style: none;
    background-color: #313445;
    border: 0;
    overflow: hidden;
}

.draft--page .dropdown-menu .dropdown-item {
    font-size: 0.75rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}

.draft--page .dropdown-menu .dropdown-item:hover {
    background: #33365e;
    color: #ffffff;
}

.draft--page .dropdown-menu .dropdown-item svg {
    margin-right: 0.4rem;
}

.draft--page .Gr--streamImage a img,
.draft--page .Gr--streamImage img {
    height: 180px;
    object-fit: cover;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    width: 100%;
    transition: all .3s ease-in-out;
}

.myShop--page .Gr--streamUser {
    padding-top: 1rem;
}

.myShop--page .Gr--streamUser p {
    font-size: 1.2rem;
    font-weight: 600;
}
.order--table table td a {
    color: #ca6ee9;
}
.discover--module.common--wrapper.order--table .form-check-input{
    width: 1.5em;
    height: 1.5em;
  }
/*-=-=-=-=-=
Media Css
=-==-=-=-=*/


@media screen and (max-width:991px) {
    .order--table table td .btn-md {
        padding: 0.5rem 1rem;
        font-size: 13px;
    }
    .order--table table td .form-control {
        color: #a9a9b7;
        padding: 0 2rem 0 0.5rem;
        text-align: center;
        font-size: 0.75rem;
        height: 40px;
        width: 70px;
        margin: 0;
        background-color: #ffffff08;
        border: 0;
        border-radius: 0.25rem;
    }
.order--table table th {
    font-weight: 300;
    letter-spacing: 0.3px;
}
.order--table table td .pro--image {
    font-weight: 200;
    letter-spacing: 0.3px;
    text-transform: capitalize;
}


}

@media screen and (max-width:767px) {
    .order--table h3 {
      
        font-size: 1.15rem;
    }
}

@media screen and (max-width:500px) {
    .order--table table td .btn-md {
        padding: 0.5rem 1rem;
        font-size: 13px;
        margin-bottom: 5px;
    }
}