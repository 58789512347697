div#commentsWrapper {
    background: transparent;
    padding: 0;
    margin-top: 2rem;
    border-radius: 0;
}

.commentsWrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.comment-title,
.no-comDiv {
    font-weight: 700;
    color: rgba(32, 32, 32, .8196078431372549);
}

.comment-title {
    font-size: 19px;
    color: #fff;
    font-weight: 400;
}

.hr-style {
    width: 100%;
    border-top: 1px solid;
    margin: 0.6rem 0 1rem 0;
}

.form {
    display: flex;
    background-color: #1E2128;
    padding: 20px;
    border-radius: 8px;
}

.form .userImg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.emoji-input {
    display: flex;
    width: 100%;
    position: relative;
    margin-left: .5rem;
}

.form .postComment {
    width: 100%;
    /* border: none;
    border-bottom: 1px solid #181818; */
    text-decoration: none;
    background-color: transparent;
    margin-left: 6px;
    color: #fff;
}

.form .postBtn {
    border: 2px solid rgb(140, 73, 163);
    border-radius: 4px;
    background-color: rgb(140, 73, 163);
    color: #fff;
    margin-left: 15px;
    font-size: 14px;
    cursor: pointer;
    padding: 4px 3px;
    font-weight: 400;
    height: 32px;
    width: 60px;
}

.halfDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.userInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.userInfo .commentsTwo {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.userLink {
    display: flex;
    text-decoration: none;
    color: inherit;
    align-items: center;
}

.imgdefault {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.userInfo .commentsTwo .imgdefault {
    width: 45px;
    min-width: 45px;
    height: 45px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    object-fit: cover;
}

.comment--img {
    display: flex;
    align-items: flex-start;
    flex: auto;
}

.userInfo .commentsTwo .fullName {
    display: flex;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
    flex-flow: column wrap;
}

.userInfo .commentsTwo .fullName p {
    font-size: 14px;
}

.infoStyle {
    margin-left: 60px;
    font-size: 14px;
    display: flex;
    align-items: flex-start;
}

.infoStyle .comments--icon, .userActions .comments--icon {
    margin-left: auto;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.infoStyle p {
    flex: 1 1;
}

.form .postBtn {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

button.postBtn:hover {
    background-color: #33365E;
    border-color: #33365E;
}

button.delBtn {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    font-weight: 600;
    font-size: 17px;
    margin: 0 0 0 5px !important;
    width: auto;
    padding: 0;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
}

.replyBtn {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    font-weight: 600;
    font-size: 17px;
    margin: 0 0 0 5px !important;
    width: auto;
    padding: 0;
    border-radius: 4px;
}

.userActions {
    margin-top: 0px;
}

.optionIcon {
    display: block;
    /* width: 115px; */
}

.userActions .actionsBtn {
    background-color: transparent;
    border: none;
    padding: 6px;
    border-radius: 50%;
}

button.cancelBtn {
    border: 2px solid rgb(140, 73, 163);
    border-radius: 4px;
    background-color: rgb(140, 73, 163);
    color: #fff;
    margin-left: 15px;
    font-size: 12px;
    cursor: pointer;
    padding: 4px 3px;
    font-weight: 400;
    height: 32px;
    width: 60px;
}

.emoji-input .emoji-icon {
    background-image: url(../../../public/images/smile.svg);
    position: relative;
    width: 24px;
    background-repeat: no-repeat;
    top: 14px;
    cursor: pointer;
}

.replyIcon {
    width: 16px;
    height: 13px;
    filter: invert(67%) sepia(0) saturate(0) hue-rotate(110deg) brightness(85%) contrast(84%);
    margin-right: 5px;
    position: absolute;
}

.optionIcon span {
    display: block;
    color: #ffffff90;
    font-size: 80%;
    font-weight: normal;
}

.szh-menu-container {
    position: relative;
    width: 0;
    height: 0;
}

.replySection {
    margin-left: 60px;
    margin-top: .5rem;
    margin-bottom: 1rem;
}

.replySectionBox {
    background: rgba(255, 255, 255, 0.05);
    padding: .5rem 1rem;
    border: 0;
    border-radius: .25rem;
    padding-bottom: 1rem;
}

.form .postComment:focus {
    outline: none;
}

.userImg a  {
    height: 40px;
    display: block;
    width: 40px;
}

.commentInputWrapper .form {
    margin: 10px 0px;
    align-items: center;
    gap: 10px;
}

.commentInputWrapper .userImg {
    margin: 0px;
}

.effect-3 {
    border: 0;
    padding: 7px 0;
    border-bottom: 1px solid #47494F;
}

.effect-3~.focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 99;
}

.effect-3~.focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 99;
}

.effect-3~.focus-border:before,
.effect-3~.focus-border:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #8C49A3;
    transition: 0.4s;
}

.effect-3~.focus-border:after {
    left: auto;
    right: 0;
}

.effect-3:focus~.focus-border:before,
.effect-3:focus~.focus-border:after {
    width: 50%;
    transition: 0.4s;
}

* :focus {
    outline-color: transparent;
    outline-style: none;
}

:focus {
    outline: none;
}

.nestedform {
    background-color: transparent;
    padding: 20px 0px;
    margin-left: -15px;
}

.nestedform .effect-3~.focus-border:before,
.nestedform .effect-3~.focus-border:after {
    background-color: #E4B0F5;
}

.nestedform .effect-3~.focus-border {
    position: absolute;
    bottom: 0;
    left: 6px;
    width: 100%;
    height: 2px;
    z-index: 99;
}

.commentInputWrapper form.form.nestedform {
    padding-top: 0;
}

form.form.nestedform {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.wrapMsgEmoji {
    display: flex;
    width: calc(100% - 185px);
}

@media screen and (max-width:600px) {
    .commentInputWrapper .form {
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        padding: 10px;
    }

    .commentInputWrapper .form button.postBtn {
        grid-area: 2 / 1 / 3 / 3;
    }

    .commentInputWrapper .userImg {
        grid-area: 1 / 1 / 2 / 2;
    }

    .emoji-input {
        grid-area: 1 / 2 / 2 / 3;
    }

    .commentInputWrapper form.form.nestedform {
        padding-top: 0;
        display: flex;
        flex-direction: column;
    }

    .commentInputWrapper form.form.nestedform>div.wrapMsgEmoji {
        display: flex;
        width: 100%;
    }

    .btnGroup {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
    }

    .infoStyle {
        font-size: 13px;
        margin-left: 50px;
    }

    .userInfo .commentsTwo .imgdefault {
        width: 40px;
        height: 40px;
        min-width: 40px;
    }

    .replySection {
        margin-left: 50px !important;
    }
}


@media screen and (max-width:360px) {
    .commentInputWrapper .form {
        display: grid;
        grid-template-columns: 55px calc(100% - 55px);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        padding: 10px;
    }

    .commentInputWrapper .form button.postBtn {
        grid-area: 2 / 1 / 3 / 3;
    }

    .commentInputWrapper .userImg {
        grid-area: 1 / 1 / 2 / 2;
    }

    .emoji-input {
        grid-area: 1 / 2 / 2 / 3;
    }

    .commentInputWrapper .form button.postBtn {
        margin-left: auto;
        font-size: 13px;
        height: 27px;
        width: 50px;
        padding: 0;
    }

    div#commentsWrapper {
        padding: 12px;
    }
}