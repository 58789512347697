.notify--main .back--btn {
    color: #ffffff;
}

.noti--cell {
    background: rgba(49, 53, 61, 0.65);
    padding: 2rem;
}

.noti--cell h4 {
    color: #ffffff;
    padding: 0;
    margin-bottom: 1rem;
}

.single--notif {
    padding: 0 0 1.5rem;
    margin-bottom: 1.5rem;
    color: #ffffff;
    font-size: 1rem;
    display: flex;
    border-bottom: 1px solid #ffffff10;
    align-items: center;
}

.single--notif:last-child {
    padding: 0;
    margin-bottom: 0;
    border-bottom: 0;
}

.single--notif svg {
    margin-left: auto;
    width: 30px;
    height: 30px;
    cursor: pointer;

}

.noti--cell .noti--image a {
    width: 35px;
    min-width: 35px;
    height: 35px;
    display: block;
    margin-right: 1rem;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    overflow: hidden;
}

.noti--cell .noti--image a img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 100px;
    -webkit-border-radius: 100px;
}

.noti--cell p a {
    font-weight: 600;
    color: #ffffff
}

.noti--cell p {
    margin: 0 1rem 0 0;
}

.noti--cell p span {
    display: block;
    color: #cccccc;
    font-size: 12px;
}

.single--notif button {
    margin-left: auto;
    padding: 0.35rem 0.85rem;
}
.single--notif svg {
    margin-left: auto;
}
