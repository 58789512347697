.Gr--singleStream {
  background-color: transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  overflow: hidden;
  height: 100%;
  padding-bottom: 15px;
  box-shadow: none;
}

.Gr--singleStream {
  background-color: rgba(49, 53, 61, 0.65);
  border-radius: 10px;
  border: 1px solid #5b5d60;
  -webkit-border-radius: 16px;
  overflow: hidden;
  padding-bottom: 15px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
}

.search--result--page .Gr--streamImage {
    overflow: hidden;
    position: relative;
    padding: 0;
    min-height: 0;
    background: none;
    margin: 0;
}

.Gr--streamImage a img,
.Gr--streamImage img {
  height: 245px;
  object-fit: cover;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.Gr--streamImage:hover img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.Gr--streamDesc {
  padding: 0.5rem 0 0;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
}

.single--stream--post .Gr--streamPost--content {
    height: auto;
}

.single--stream--post .Gr--streamImage span {
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: 0;
    min-height: 0;
}

.single--stream--post .Gr--streamPost--content pre {
    padding: 0;
}

.Gr--streamDesc {
  flex-wrap: nowrap;
}

.Gr--streamDesc p,
.Gr--streamDesc a {
  text-transform: capitalize;
  color: #a9a9b7;
  font-size: 14px;
}

.Gr--streamDesc a:hover,
.Gr--streamDesc a:hover p {
  color: #ffffff;
}

.Gr--streamDesc {
  padding: 15px 20px 10px 20px;
}

.Gr--streamDesc img {
  width: 46px;
  height: 46px;
  margin-right: 0;
  object-fit: cover;
  min-height: 46px;
  min-width: 46px;
  padding: 3px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.Gr--streamDesc p,
.Gr--streamUser p {
  margin: 0;
  font-size: 12px;
  color: #b3b3b3;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.Gr--streamUser p svg {
  margin-right: 0.5rem;
}

.Gr--streamUser {
  padding: 0 1rem 0;
  min-height: 46px;
}

.Gr--streamUser h4 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #ffffff;
  font-size: 1rem;
}

.Gr--streamUser h4 a {
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
  text-transform: capitalize;
}

.Gr--streamUser pre {
  color: #ffffff;
  white-space: break-spaces;
}

.audio--main--page .dropdown-toggle {
  color: #ffffff;
  width: 28px;
  height: 28px;
  line-height: 12px;
  z-index: 111;
  font-size: 1rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  padding: 0;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  border: 0;
}

.audio--main--page .dropdown-toggle:hover {
  background: #313445;
}

.audio--main--page .dropdown-toggle svg {
  font-size: 1rem;
  width: auto;
  vertical-align: middle;
  height: auto;
}

.audio--main--page .dropdown-toggle::after {
  display: none;
}

.audio--main--page .dropdown-menu {
  min-width: 0;
  padding: 0;
  font-size: 0.75rem;
  color: #ffffff;
  text-align: left;
  list-style: none;
  background-color: #313445;
  border: 0;
  overflow: hidden;
}

.audio--main--page .dropdown-menu .dropdown-item {
  font-size: 0.75rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
}

.audio--main--page .dropdown-menu .dropdown-item:hover,
.audio--main--page .dropdown-menu .dropdown-item:focus,
.audio--main--page .dropdown-menu .dropdown-item:active {
  background: #33365e;
  color: #ffffff;
}

.audio--main--page .dropdown-menu .dropdown-item svg {
  margin-right: 0.4rem;
}

.search--result--page .Gr--streamImage:hover img {
  transform: none;
}

.search--result--page .Gr--streamImage .play--icon {
  display: none;
}

.Tabs--horizontal {
  display: flex;
  flex-flow: column wrap;
}

/*-==-=-=-=-=
Media Css
-==--==-*/

@media screen and (max-width: 991px) {
  .Gr--streamImage a img,
  .Gr--streamImage img {
    height: 250px;
  }
}

@media screen and (max-width: 767px) {
  .discover--module {
    padding: 0 0 2rem;
  }
}