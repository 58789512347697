.Edit--Profile--form .back--btn {
    color: #dddddd;
    display: flex;
    align-items: center;
}

.Edit--Profile--form .back--btn svg {
    margin-right: 0.3rem;
}

.Edit--Profile--form h4 {
    margin-bottom: 1rem;
}

.Edit--Profile--form form {
    background: rgba(49, 53, 61, 0.65);
    padding: 1.5rem;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    margin-top: 1rem;
}

.Edit--Profile--form form .form-label {
    font-weight: 600;
    margin-bottom: 0.2rem;
}

.Edit--Profile--form form .form-control {
    color: #a9a9b7;
    padding: 0 1rem;
    height: 40px;
    background-color: #ffffff10;
    border: 0;
    border-radius: 0.25rem;
}

.Edit--Profile--form form textarea.form-control {
    height: 100px;
}

.Edit--Profile--form .image--change {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    flex-flow: row wrap;
}

.Edit--Profile--form .image--change img {
    width: 150px;
    height: 150px;
    background: #ffffff30;
    padding: 0.2rem;
    margin-right: 1rem;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    object-fit: cover;
    object-position: center;
}

.Edit--Profile--form .image--change p {
    margin: 0;
    margin-top: 0.5rem;
}

.Edit--Profile--form .image--cover,
.Edit--Profile--form .image--change {
    border-bottom: 1px solid #ffffff10;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
}

.Edit--Profile--form .image--cover img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 200px;
}

.Edit--Profile--form .button--cover label {
    background: #ffffff10;
    padding: 1rem 2rem;
    cursor: pointer;
    font-size: 1.2rem;
    margin: 0;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    -o-border-radius: 0.25rem;
    display: block;
    text-align: center;
}

.Edit--Profile--form .checkbox--group {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 2rem;
}

.Edit--Profile--form .checkbox--group label {
    position: relative;
    cursor: pointer;
    border: 2px solid #ffffff30;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 1rem 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    margin-right: 0.5rem;
    font-size: 1rem;
    min-width: 125px;
    min-height: 125px;
    cursor: pointer;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
}

.Edit--Profile--form .checkbox--group .custom--check:last-child label {
    margin-right: 0;
}

.Edit--Profile--form .checkbox--group input:checked+label {
   background: #8c49a3;
   border: 2px solid #8c49a3;
}

.Edit--Profile--form .checkbox--group label svg {
    display: block;
    margin: auto auto 0.5rem;
    font-size: 3.5rem;
}
.Edit--Profile--form form textarea.form-control {
    padding: 10px;
}
.Edit--Profile--form .image--change--wrap {
    position: relative;
    margin-right:16px
}

.Edit--Profile--form .image--change--wrap span.fullscreen--icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0px;
    margin: auto;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
}

.Edit--Profile--form .image--change--wrap span.fullscreen--icon svg {
    width: 27px;
    height: 27px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transform: scale(0.85);
    -webkit-transform: scale(0.85);
}

.Edit--Profile--form .image--change--wrap:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #00000050;
    border-radius: 100px;
}

.Edit--Profile--form .image--change img {
    margin-right: 0;
}
.Edit--Profile--form .image--change--wrap:hover span.fullscreen--icon svg {
    transform: scale(1.1);
    -webkit-transform: scale(1);
}
/*-=-=-=-=-=-
Media css-=-==-*/

@media screen and (max-width:991px) {
    .Edit--Profile--form .checkbox--group label {
        padding: 1rem;
        font-size: 0.85rem;
        min-width: 90px;
        min-height: 90px;
    }

    .Edit--Profile--form .checkbox--group label svg {
        font-size: 2.5rem;
    }
    .Edit--Profile--form .button--cover label {
        font-size: 14px;
    }
    .Edit--Profile--form form textarea.form-control {
       
        padding: 10px;
    }
    .Edit--Profile--form .checkbox--group label svg {
        font-size: 1.7rem;
    }
    .Edit--Profile--form .checkbox--group label {
        min-width: 75px;
        min-height: 05px;
        padding: 12px;
    }
}

@media screen and (max-width:767px) {
    .Edit--Profile--form .checkbox--group label {
  
      margin: 3px;
  }
    .Edit--Profile--form  .form-control {
      font-size: 14px;
  }
    .Edit--Profile--form .button--cover label {
      font-size: 12px;
  }
   .Edit--Profile--form .button--change label.btn {
      margin-bottom: 0 !important;
      margin-right: 10px;
  }
    .Edit--Profile--form form textarea.form-control {
       
        padding: 10px;
    }
    .Edit--Profile--form form textarea.form-control {
        padding: 10px;
        font-size: 13px;
    }
}
@media screen and (max-width:575px) {
    .Edit--Profile--form .image--change {
        justify-content: center;
        text-align: center;
    }

    .Edit--Profile--form .image--change img {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .Edit--Profile--form form {
        padding: 1rem;
    }

    .Edit--Profile--form .checkbox--group label {
        min-width: 70px;
        min-height: 70px;
        padding: 12px;
    }
}
@media screen and (max-width:400px) {
    .Edit--Profile--form .button--change .btn {
        font-size: 12px !important;
        margin: 0 0px 0 0 !important;
    }
    .Edit--Profile--form .button--change label.btn {
        font-size: 12px !important;
        margin: 0 5px 0 0 !important;
    }
    .discover--module.common--wrapper.Edit--Profile--form .text-end.col-sm-12 .btn{
        font-size: 12px !important; 
    }
}
@media screen and (max-width:374px) {
    .Edit--Profile--form .button--change .btn, .Edit--Profile--form .button--change label.btn {
        font-size: 12px !important;
        margin: 0 0 5px 0 !important;
    }
    .Edit--Profile--form .checkbox--group label {
        min-width: 60px;
        min-height: 60px;
        padding: 12px;
    }
    .Edit--Profile--form .checkbox--group label svg {
        font-size: 1.6rem;
    }
    .discover--module.common--wrapper.Edit--Profile--form .text-end.col-sm-12 .btn{
        font-size: 12px !important; 
    }
  
}