.video--block {
    position: relative;
    margin-bottom: 1rem;
    background: none;
    padding: 0;
    border-radius: 0;
    -webkit-border-radius: 0;
}

.back--btn {
    color: #dddddd;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.back--btn svg {
    margin-right: 0.3rem;
}

.video--block video {
    width: 100%;
    height: 100%;
}

.video--block .play--button {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 4rem;
    cursor: pointer;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
}

.video--user {
    /* border-style: solid;
    border-color: #ffffff10;
    border-width: 1px 0; */
    /* padding: 2rem 0; */
    /* margin-top: 1rem; */
    margin-bottom: 1rem;
    position: relative;
}

.video--user,
.video--user--name,
.video--desc ul {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.video--user--name img {
    max-width: 45px;
    margin-right: 1rem;
    border-radius: 100px;
    object-fit: cover;
    height: 45px;
    width: 45px;
}

.video--user--name h5 {
    margin: 0;
}

.video--user--name h5 span {
    display: block;
    color: #ffffff90;
    font-size: 80%;
    font-weight: normal;
}

.video--user button {
    margin-left: auto;
    padding: 0.5rem 1rem;
}

.video--user .dropdown {
    position: absolute;
    right: 0;
    top: 0;
}

.video--user .dropdown-toggle {
    color: #ffffff;
    width: 28px;
    height: 28px;
    line-height: 12px;
    z-index: 111;
    font-size: 1rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    padding: 0;
    border: 0;
    background-color: transparent !important;
}

.video--user .dropdown-toggle:hover {
    background: #313445;
}

.video--user .dropdown-toggle svg {
    font-size: 1rem;
    width: auto;
    vertical-align: middle;
    height: auto;
}

.video--user .dropdown-toggle::after {
    display: none;
}

.video--user .dropdown-menu {
    min-width: 0;
    padding: 0;
    font-size: 0.75rem;
    color: #ffffff;
    text-align: left;
    list-style: none;
    background-color: #313445;
    border: 0;
    overflow: hidden;
}

.video--user .dropdown-menu .dropdown-item {
    font-size: 0.75rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    text-transform: capitalize;
}

.video--user .dropdown-menu .dropdown-item:hover, .video--user .dropdown-menu .dropdown-item:focus, .audio--main--page .dropdown-menu .dropdown-item:active {
    background: #33365e;
    color: #ffffff;
}

.video--desc {
    margin-bottom: 2rem;
}

.video--desc ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.video--desc ul li {
    margin-right: 1.5rem;
    color: #ffffff90;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
}

.video--desc ul li svg {
    margin-right: 0.5rem;
    width: 17px;
    height: auto;
}

.comments--panel {
    background: #292c33;
    border-radius: 0.5rem;
    height: 100%;
}

.live--comments textarea.form-control {
    border: 0;
    resize: none;
    padding: 0.8rem 1.5rem 2rem;
    padding-right: 3.5rem;
    font-size: 1rem;
    background: none;
}

.comments--box {
    background: none;
    padding: 1.5rem;
    margin-top: 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    height: calc(100% - 70px);
}

.comments--box .visual--desc ul li .btn-sm {
    padding: 0.2rem 0.5rem;
    font-size: 11px;
}

.comments--box .comments--box {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    height: auto;
}

.comments--box .video--desc ul li {
    margin-right: 0.3rem;
    font-size: 0.75rem;
}

.comments--box .video--desc ul li svg {
    width: 15px
}

.comments--box .video--desc {
    margin-bottom: 0;
    flex: 1 1 100%;
    padding-left: 60px;
}

.comments--box .video--user--name {
    margin-bottom: 0;
}

.comments--box .live--comments {
    flex: 1 1;
}

.comments--box .reply--box {
    padding-left: 60px;
}

.comments--box .hideComment,
.comments--box .hideTextarea {
    display: none;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.comments--box .showComment,
.comments--box .showTextarea {
    display: block;
    border-top: 1px solid #ffffff10;
    padding-top: 1rem;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.comments--box .video--user--name h5 {
    font-weight: normal;
    font-size: 0.875rem;
}

.comments--box .visual--desc ul li:empty {
    display: none;
}

.comments--box .visual--desc ul li svg {
    margin-right: 0;
    width: 18px;
}

.single--bg--col {
    background: #292c33;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    padding: 1.5rem;
}

.text--white * {
    color: #ffffff !important;
}

.single--bg--col .user--video div {
    width: 100% !important;
    height: 100% !important;
}
.single--bg--col .user--video {
    text-align: center;
}
.single--bg--col .user--video video {
    object-fit: cover;
    border-radius: 0;
    /* height: 461px; */
    margin: 20px auto!important;
    width: 100%;
}

.single--bg--col .list-group {
    padding: 0;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background: none;
    flex-flow: row wrap;
    margin: 0;
}

.single--bg--col .list-group-item {
    border: 0;
    background: none;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    padding: 0.5rem 0.875rem;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    display: flex;
    align-items: center;
}

.single--bg--col .list-group-item:hover {
    background: rgba(49, 53, 61, 0.9);
}

.single--bg--col .list-group-item svg {
    font-size: 1.5rem;
}

.single--bg--col .list-group-item span:empty {
    display: none;
}

.related--Videos {
    border: 1px solid #ffffff10;
    padding: 0.8rem;
    background: none;
    border-radius: 0.5rem;
    height: 100%;
    overflow-y: auto;
    max-height: 500px;
}

.related--Videos .card {
    margin-bottom: 0.8rem;
    background: none;
    border: 0;
    border-bottom: 1px solid #ffffff10;
    padding-bottom: 0.8rem;
}

.related--Videos .card:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
}

.related--Videos .card a {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    color: #ffffff;
}

.related--Videos img.card-img-top {
    max-width: 100px;
    height: 90px;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    object-fit: cover;
    margin-right: 1rem;
}

.related--Videos .card-body {
    flex: 1 1;
    padding: 0;
}

.related--Videos .card-title {
    font-size: 1rem;
    font-weight: 500;
}

.related--Videos .card:hover .card-title {
    color: #ffffff80;
}

.related--Videos .card-subtitle {
    font-size: 0.85rem;
    margin-top: 0.3rem;
    font-weight: normal;
}

.related--Videos p.card-text {
    color: #ffffff90;
    font-weight: normal;
    font-size: 0.75rem;
}

.video--block .replay--btn {
    position: absolute;
    left: 1rem;
    top: 1rem;
    background: #ffffff20;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: inline-block;
    border-radius: 50px;
    -webkit-border-radius: 50px;
}

.video--block .replay--btn:hover {
    background: #ffffff50;
}

.video--block .video--controls {
    position: absolute;
    bottom: 1rem;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    width: calc(100% - 30px);
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    margin: auto;
    right: 0;
}

.video--block .video--controls .list-group {
    align-items: center;
}

.video--block .video--controls .list-group-item {
    background: none;
    border: 0;
    color: #ffffff90;
    padding: 0;
    font-size: 1rem;
    border-radius: 0;
}

/*-=-=-=-
Custom Range Slider Css
-=-==-=-==-=-=-*/

.custom-range::-webkit-slider-thumb {
    background: #8c8c8c;
}

.custom-range::-moz-range-thumb {
    background: #8c8c8c;
}

input[type="range"]::-webkit-slider-thumb {
    background: #fff;
}

input[type="range"]::-moz-range-thumb {
    background: #fff;
}

input[type=range] {
    margin: 0;
    outline: none;
    padding: 0;
    height: 6px;
    background-color: #8c8c8c;
    background-size: 50% 100%;
    background-repeat: no-repeat;
    border-radius: 10px;
    cursor: pointer;
    min-width: 250px;
    vertical-align: middle;
}

input[type=range].min-vw-10 {
    min-width: 0;
}

input[type=range]::-webkit-slider-runnable-track {
    box-shadow: none;
    border: none;
    background: transparent;
}



#red5pro-publish{
    pointer-events: none;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
}

.video--block .schedule--button {
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
    z-index: 111;
    background: #33365e;
    padding: 0.25rem 0.65rem;
    color: #ffffff;
    font-size: 0.75rem;
    border: 0;
}
.showTextarea.reply--box {
    padding-left: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    margin-top: 5px;
    width: 100%;
}

.live--comments textarea.form-control {
    padding: 7px;
    width: calc(100% - 35px);
    font-size: 14px;
    float: left;
    color: #fff;
    font-size: 14px;
}

.live--comments  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 13px;
  }
  .live--comments  ::-moz-placeholder { /* Firefox 19+ */
    font-size: 13px;
  }
  .live--comments  :-ms-input-placeholder { /* IE 10+ */
    font-size: 13px;
  }
  .live--comments  :-moz-placeholder { /* Firefox 18- */
    font-size: 13px;
  }






.live--comments {
    width: 100%;
	 align-items: center;
    display: flex;
}
.comments--panel.new-comments--panel > .comments--box + .live--comments ,
.comments--panel.new-comments--panel > .live--comments {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #2a2c33;
    padding: 6px;
    width: calc(100% - 12px);
    left: 0;
    right: 0;
    margin: auto;
    border-top: 1px solid #3a3a3a;
}

.comments--box .visual--user--name {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 41px;
    margin-top: 5px;
    margin-bottom: 5px;
}


.live--comments  button.btn.btn-primary {
right: 0;
}
.comments--panel.new-comments--panel  {
position: relative;
    padding: 0 !important;
    margin-bottom: 0 !important;
    /* height: calc(100vh - 170px); */
    display: block;
}
.wrapper--comments {
    position: sticky;
    position: -webkit-sticky;
    top: 85px;
}   
.comments--panel.new-comments--panel .comments--box + .live--comments {
position: absolute;
bottom: 0;
right: 0;
background: #2a2c33;
padding: 6px;
width: calc(100% - 12px);
left: 0;
right: 0;
margin: auto;
border-top: 1px solid #3a3a3a;
}


.comments--panel.new-comments--panel > .comments--box {
position: relative;
height: calc(100% - 50px);
overflow: scroll;
padding-bottom: 21px !important;
}
body:has(.comments--box > .visual--user--name) .comments--panel.new-comments--panel > .comments--box {
    padding-bottom: 34px !important;
}
.comments--box.new-comments--box .showTextarea.reply--box {
    position: relative;
    right: 0;
    margin-bottom: 10px;
}
.comments--box.new-comments--box  .visual--desc ul li svg {
    margin-right: 0;
    width: 16px;
}
.comments--box .visual--desc p {
    padding-left: 12px;
    padding-top: 3px;
}
.live--comments .btn {
    font-size: 14px;
}

.comments--panel.new-comments--panel > .comments--box .comments--box.new-comments--box .live--comments .btn {
    background: transparent;
    border: transparent;
    padding-right: 0;
}
.draft--page .Gr--streamImage .play--icon {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #ffffff;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 2px solid #ffffff;
    background: #31344560;
    border-radius: 10px;
    z-index: 111;
    min-height: unset;
    cursor: pointer;
}
.draft--page .Gr--streamUser {
    padding: 0;
}

 .draft--page .Gr--streamUser h4 {

    text-transform: capitalize;
}
.comments--panel , .comments--panel.new-comments--panel .comments--box + .live--comments{
    background: #0b0e14;

}
.comments--panel.new-comments--panel .comments--box + .live--comments {
    border-top: 1px solid #252525;
}

/*-=-=-=--=-=-
media css=-=-==-*/
@media screen and (max-width:991px) {

    
     .draft--page .Gr--streamUser h4 {
        font-size: 14px;
     
    }
    .video--user--name img {
        height: 45px;
        max-height: 45px;
        max-width: 45px;
        width: 45px;
    }
    .discover--module.common--wrapper.video--single--page .video--desc {
        padding-top: 0;
        margin-top: 0 !important;
    }
    .discover--module.common--wrapper.video--single--page  .single--bg--col .list-group-item svg {
        font-size: 1.2rem;
    }

   



    .discover--module.common--wrapper.video--single--page .Gr--streamPost--content pre {
        color: #fff;
    }
    
    .discover--module.common--wrapper.video--single--page .single--bg--col .visual--user {
        padding: 0;
    }
    

    .discover--module.common--wrapper.video--single--page .single--bg--col .visual--desc .list-group-item {
           padding: 0;
    }
    
    .discover--module.common--wrapper.video--single--page .single--bg--col .visual--desc h2 {
        text-transform: capitalize;
        font-size: 24px;
        font-weight: 500;
    }
    .discover--module.common--wrapper.video--single--page .Gr--streamPost--content pre {
        font-size: 14px;
        line-height: 24px;
        word-break: break-word;
    }
    .discover--module.common--wrapper.video--single--page  .showTextarea.reply--box .live--comments button.btn.btn-primary {
        padding-right: 0;
        background: none;
        border: none;
    }
    .discover--module.common--wrapper.video--single--page .single--bg--col .visual--desc p {
        font-size: 14px;
        line-height: 24px;
        word-break: break-word;
    }
    .discover--module.common--wrapper.video--single--page .comments--panel {
        height: 100%;
    }
    .discover--module.common--wrapper.video--single--page .comments--box{
        height: 100%;
    }
    .discover--module.common--wrapper.video--single--page .live--comments{
        position: relative;
    }

    .wrapper--comments .live--comments {
        padding: 0 15px 15px 15px;
        width: calc(100% - 30px );
    }


    .discover--module.common--wrapper.video--single--page .comments--box .visual--desc {
        margin-bottom: 0;
        flex: 1 1 100%;
        padding-left: 15px;
    }
    .discover--module.common--wrapper.video--single--page .comments--box .visual--desc p {
        padding-left: 25px;
        padding-top: 3px;
    }


}
@media screen and (max-width:575px) {
    .single--bg--col .user--video video {
        object-fit: cover;
        border-radius: 0;
        height: auto;
        margin: 20px auto!important;
        width: 100%;
    }

    .video--user--name img {
        height: 35px;
        max-height: 35px;
        max-width: 35px;
        width: 35px;
    }
    .video--block .video--controls {
        display: none;
    }

    .live--comments textarea.form-control {
        padding: 0.8rem 1rem;
        font-size: 0.85rem;
    }

    .related--Videos img.card-img-top {
        max-width: 70px;
        height: 60px;
    }

    .related--Videos .card-title {
        font-size: 0.85rem;
    }

    .related--Videos .card-subtitle {
        font-size: 0.75rem;
    }

    .related--Videos p.card-text {
        font-size: 0.65rem;
    }
}