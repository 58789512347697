#page--Header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #191b1f;
  box-shadow: 1px 0px 4px rgb(0 0 0 / 53%);
  -webkit-box-shadow: 1px 0px 4px rgb(0 0 0 / 53%);
  margin-bottom: 0;
  z-index: 999;
  padding: 0.8rem 1rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  width: 100%;
}

.collapse--header {
  transform: translateY(-75px);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.collapse--header + .discover--module {
  margin-top: -40px;
}

#header--collapse {
  position: absolute;
  top: 75px;
  right: 30px;
  background: #191b1f;
  border: 0;
  color: #ffffff;
  width: auto;
  min-width: 38px;
  padding: 0;
  border-radius: 0 0 0.25rem 0.25rem;
  font-size: 25px;
  line-height: 25px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  box-shadow: -1px 2px 5px rgb(0 0 0 / 62%);
  -webkit-box-shadow: -1px 2px 5px rgb(0 0 0 / 62%);
}

#header--collapse span {
  display: block;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

#header--collapse.header--Toggle span {
  display: block;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.header--elements {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.header--elements form {
  flex: 1 1;
  margin: 0 6rem;
  position: relative;
}

.header--elements form .form-control {
  background-color: rgba(49, 53, 61, 0.65);
  height: 50px;
  border-radius: 50px;
  border: 0;
  color: #a9a9b7;
  padding-left: 1.5rem;
  font-size: 16px;
}

.header--elements form .form-control::placeholder {
  color: #a9a9b7;
}

.header--elements form .form-control::-moz-placeholder {
  color: #a9a9b7;
}

.header--elements form .form-control::-webkit-input-placeholder {
  color: #a9a9b7;
}

.header--elements form button {
  background: none;
  position: absolute;
  padding: 0;
  border: 0;
  box-shadow: none;
  right: 1rem;
  top: 0.6rem;
  opacity: 0.6;
  font-size: 1.2rem;
  color: #ffffff;
}

.header--elements form button:hover,
.header--elements form button:focus,
.header--elements form .btn-primary:not(:disabled):not(.disabled).active,
.header--elements form .btn-primary:not(:disabled):not(.disabled):active,
.header--elements form .show > .btn-primary.dropdown-toggle {
  color: #a9a9b7;
  box-shadow: none;
  background-color: transparent;
  border-color: transparent;
}

.header--elements .list-group {
  background: none;
  border: 0;
  border-radius: 0;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
  flex: 1 1;
}

.header--elements .list-group .list-group-item {
  padding: 0 0 0 1.2rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  width: auto;
  color: #ffffff;
}

.header--elements .list-group .list-group-item:first-child {
  padding: 0;
}

.header--elements .list-group .list-group-item a {
  color: #ffffff;
  position: relative;
}

.header--elements .list-group .list-group-item a .badge {
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 0;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 100px;
  background-color: #8c49a3 !important;
}

.header--elements .list-group-item svg {
  font-size: 28px;
  fill: #a9a9b7;
  width: auto;
  height: auto;
}

.header--elements .list-group-item:hover svg {
  fill: #ffffff;
}

.header--elements .list-group-item .dropdown-toggle {
  white-space: nowrap;
  background: none;
  padding: 0;
  border: 0;
  box-shadow: none;
}

.header--elements .list-group-item .dropdown-toggle img {
  border-radius: 100px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  object-fit: cover;
  object-position: center;
}

.header--elements .list-group-item .dropdown-toggle:hover img {
  box-shadow: 0 0 5px #a9a9b7;
}

.header--elements
  .list-group-item
  .btn-primary:not(:disabled):not(.disabled).active,
.header--elements
  .list-group-item
  .btn-primary:not(:disabled):not(.disabled):active,
.header--elements .list-group-item .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.header--elements .list-group-item .dropdown-toggle::after {
  display: none;
}

.header--elements .dropdown-menu {
  background: #292c33;
  right: 0 !important;
  border: 0;
  padding: 0;
  border-radius: 0;
  margin: 40px 0 0;
  left: auto !important;
  min-width: 10rem;
  transform: none !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
}

.header--elements .dropdown-menu:before {
  top: -6px;
  left: auto;
  right: 10px;
  margin: auto;
  content: "";
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #292c33;
  width: 0;
  height: 0;
}

.header--elements .dropdown-item,
.header--elements .dropdown-menu .nav-item {
  font-size: 14px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.6rem 1rem 0.5rem;
  color: #fff;
}

.header--elements .dropdown-menu .nav-item {
  position: relative;
}

.header--elements .dropdown-menu .nav-item::before {
  border-style: solid;
  border-width: 0 0 0.1rem 0.1rem;
  content: "";
  display: inline-block;
  height: 0.45rem;
  width: 0.45rem;
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.header--elements .dropdown-menu .nav-item.show::before {
  border-style: solid;
  border-width: 0.1rem 0.1rem 0 0;
  content: "";
  display: inline-block;
  height: 0.45rem;
  width: 0.45rem;
  position: absolute;
  right: 1.5rem;
  top: 1.2rem;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.header--elements .dropdown-menu .nav-item .dropdown-toggle {
  color: #ffffff;
  font-size: 14px;
}

.header--elements .dropdown-item:hover,
.header--elements .dropdown-item:focus,
.header--elements .dropdown-menu .nav-item:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: #191b1f;
  color: #fff;
}

.header--elements .dropdown-menu .nav-item:hover {
  background: none;
  color: #fff;
}

.header--elements .nav-item .dropdown-menu {
  background: #32353d;
  margin: 9px -16px -8px;
  min-width: 10rem;
  position: static !important;
}

.header--elements .nav-item .dropdown-menu::before {
  display: none;
}

.color-set-light-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.noti--dropdown button {
  position: relative;
}

.noti--dropdown button span.noti--count {
  background: #8c49a3;
  color: #ffffff;
  display: inline-block;
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  font-size: 12px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  /* font-weight: 600; */
}

.header--elements .noti--dropdown .dropdown-menu {
  min-width: 24rem;
}

.noti--dropdown .dropdown-menu h4 {
  color: #ffffff;
  padding: 1rem;
}

.noti--dropdown .dropdown-menu li {
  padding: 0 1rem 1rem;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.noti--dropdown .dropdown-menu li:last-child {
  border-top: 1px solid #8c49a330;
  padding-top: 1rem;
  align-items: center;
  justify-content: center;
}

.noti--dropdown .dropdown-menu li:last-child a {
  color: #8c49a3;
  display: block;
  text-align: center;
  font-weight: 700;
}
.noti--dropdown .dropdown-menu li:last-child {
  border-top: 1px solid #8c49a330;
  padding-top: 1rem;
  align-items: start;
  justify-content: start;
}
.noti--dropdown .dropdown-menu li:last-child a:hover {
  color: #ffffff;
}
span.notification-wrap {
  display: flex !important;
  flex-direction: row !important;
}
.noti--dropdown .dropdown-menu span.noti--image a {
  width: 35px;
  min-width: 35px;
  height: 35px;
  display: block;
  margin-right: 1rem;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  overflow: hidden;
}

.noti--dropdown .dropdown-menu span.noti--image a img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 100px;
  -webkit-border-radius: 100px;
}

.noti--dropdown .dropdown-menu li p a {
  font-weight: 600;
  color: #8c49a3 !important;
}

.noti--dropdown .dropdown-menu li p {
  margin: 0 1rem 0 0;
}

.noti--dropdown .dropdown-menu li p span {
  display: block;
  color: #cccccc;
  font-size: 12px;
}

.color-set-light-dark input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  left: -99999px;
}

.header--elements
  .color-set-light-dark
  input[type="checkbox"]
  + label
  .off
  svg {
  font-size: 20px;
  fill: #ccc;
  width: auto;
  height: auto;
}

.color-set-light-dark input[type="checkbox"] + label {
  height: 35px;
  line-height: 35px;
  background-color: rgb(255 255 255 / 24%);
  padding: 0;
  border-radius: 100px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: all 0.25s ease-in;
  width: 80px;
  margin: 0 auto;
  cursor: pointer;
}

.color-set-light-dark input[type="checkbox"] + label:before,
.color-set-light-dark input[type="checkbox"] + label:hover:before {
  content: " ";
  position: absolute;
  top: 5px;
  left: auto;
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  width: 26px;
  height: 26px;
  background: #fff;
  z-index: 2;
  transition: all 0.25s ease-in;
  border-radius: 100px;
}

.color-set-light-dark input[type="checkbox"]:checked + label:before,
.color-set-light-dark input[type="checkbox"]:checked + label:hover:before,
.color-set-light-dark input[type="checkbox"]:focus:checked + label:before,
.color-set-light-dark
  input[type="checkbox"]:focus:checked
  + label:hover:before {
  transform: translateX(50px);
  -webkit-transform: translateX(50px);
}

.color-set-light-dark input[type="checkbox"]:checked + label:before,
.color-set-light-dark input[type="checkbox"]:checked + label:hover:before {
  background: #292c33;
}

.color-set-light-dark input[type="checkbox"] + label .off,
.color-set-light-dark input[type="checkbox"] + label .on {
  color: #fff;
}

.color-set-light-dark input[type="checkbox"] + label .off {
  display: inline-block;
  position: absolute;
  right: 9px;
  margin: 0;
  top: -3px;
}

.color-set-light-dark input[type="checkbox"] + label .on {
  display: none;
}

.color-set-light-dark input[type="checkbox"]:checked + label .off {
  display: none;
}

.color-set-light-dark input[type="checkbox"]:checked + label .on {
  display: inline-block;
  position: absolute;
  left: 9px;
  margin: 0;
  top: -1px;
}

.color-set-light-dark input[type="checkbox"]:checked + label,
.color-set-light-dark input[type="checkbox"]:focus:checked + label {
  background-color: rgb(255 255 255 / 24%);
}

.new-header--elements .react-autosuggest__container {
  flex: 1 1;
  margin: 0 0 0 1rem;
  position: relative;
}

.new-header--elements .react-autosuggest__container input {
  width: 100%;
  padding: 10px;
  background-color: rgba(49, 53, 61, 0.65);
  border: 0;
  border-radius: 50px;
  color: #a9a9b7;
  font-size: 16px;
  height: 50px;
  padding-left: 1.5rem;
}

.new-header--elements .list-group.list-group-horizontal {
  margin-left: 0;
}
.new-header--elements div#react-autowhatever-1:empty {
  display: none;
}
.new-header--elements div#react-autowhatever-1 {
  position: absolute;
  background: #282b32;
  width: 100%;
  margin-top: 0.5px;
  border-radius: 4px;
  border: 1px solid #292c33;
  box-shadow: -1px 0px 6px 2px #141414;
}
.new-header--elements div#react-autowhatever-1 ul li {
  background: #484848;
  list-style: none;
  padding: 4px 10px;
  margin: 4px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
}
.new-header--elements div#react-autowhatever-1 ul li:hover {
  background: #8749a4;
}
.new-header--elements div#react-autowhatever-1 ul {
  margin: 0;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.new-header--elements .react-autosuggest__container input:focus-visible {
  outline: none !important;
}
.page--content {
  transition: all ease 0.5s;
}
body:has(.collapse--header) .page--content {
  padding-top: 0;
  transition: all ease 0.5s;
}

.noti--dropdown .dropdown-menu li {
  width: 100%;
  position: relative;
}

.noti--dropdown .dropdown-menu li svg {
  right: 7px;
  position: absolute;
  top: 6px;
  width: 20px;
}
/*-=-=-=-=-=
Media Css
=-==-=-=-=*/

@media (max-width: 991px) {
  body:has(.collapse--header) .page--content {
    padding-top: 25px;
  }
  body:has(.collapse--header) .page--content {
    padding-top: 37px !important;
  }
  .navbar-brand img {
    filter: invert(1);
    -webkit-filter: invert(1);
    max-width: 125px;
  }

  .new-header--elements div#react-autowhatever-1 {
    left: 0;
    top: 56px;
    width: 90%;
    right: 0;
    margin: auto;
  }

  .header--elements form {
    margin: 0;
  }

  #page--Header {
    padding: 0.5rem 0;
  }
  
  #header--collapse {
    top: 57px;
  }
}

@media screen and (max-width: 576px) {
    #header--collapse {
        top: 77px;
        right: 10px;
    }

  .new-header--elements div#react-autowhatever-1 ul {
    margin: 0;
    padding: 10px;
  }
  .noti--dropdown.show.dropdown .dropdown-menu.show {
    width: 100%;
    min-width: 300px;
  }

  .header--elements .list-group-item svg {
    font-size: 18px;
  }

  .header--elements .list-group .list-group-item {
    padding: 0 0 0 .5rem;
  }
  .header--elements .list-group-item .dropdown-toggle img {
    width: 25px;
    height: 25px;
    min-width: 25px;
  }

  .header--elements form {
    margin: 0;
  }

  .header--elements form .form-control {
    height: 35px;
    padding-left: 0.6rem;
    font-size: 12px;
  }

  .header--elements form button {
    right: 0.4rem;
    top: 0.4rem;
    font-size: 0.85rem;
  }

  .header--elements.new-header--elements .navbar-brand {
    padding-bottom: 0;
    padding-top: 0;
    margin-right: 0;
  }

  .new-header--elements .list-group.list-group-horizontal {
    margin-left: auto;
  }

  .new-header--elements .list-group-item svg {
    font-size: 23px;
  }
 
  #header--collapse.header--Toggle {
    top: 126px;
    right: 10px;
  }

  .collapse--header {
    transform: translateY(-125px);
  }

  body:has(.collapse--header) .page--content {
    padding-top: 25px;
  }

  .new-header--elements .react-autosuggest__container input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    height: 40px;
    padding-left: 1rem;
  }
  .new-header--elements div#react-autowhatever-1 {
    left: 0;
    top: unset;
    width: 90%;
    right: 0;
    margin: auto;
  }
  .new-header--elements div#react-autowhatever-1 ul li {
    font-size: 12px;
    line-height: 16px;
  }
  .navbar-brand {
    padding: 1rem 0 1rem;
  }
}