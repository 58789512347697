.visual--single--page .back--btn {
    color: #dddddd;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.visual--single--page .back--btn svg {
    margin-right: 0.3rem;
}

.visual--single--page .dropdown {
    position: absolute;
    right: 0;
    top: 0;
}

.visual--single--page .dropdown-toggle {
    color: #ffffff;
    width: 28px;
    height: 28px;
    line-height: 12px;
    z-index: 111;
    font-size: 1rem;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    padding: 0;
    border: 0;
}

/**********  full photo css ****/
.visual--block img {
    width: auto !important;
    margin: 0 auto;
    cursor: pointer;
}

.visual--block {
    text-align: center;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block !important;
}

.visual--single--page .dropdown-toggle:hover {
    background: #313445;
}

.visual--single--page .dropdown-toggle svg {
    font-size: 1rem;
    width: auto;
    vertical-align: middle;
    height: auto;
}

.visual--single--page .dropdown-toggle::after {
    display: none;
}

.visual--single--page .dropdown-menu {
    min-width: 0;
    padding: 0;
    font-size: 0.75rem;
    color: #ffffff;
    text-align: left;
    list-style: none;
    background-color: #313445;
    border: 0;
    overflow: hidden;
}

.visual--single--page .dropdown-menu .dropdown-item {
    font-size: 0.75rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}

.visual--single--page .dropdown-menu .dropdown-item p {
    margin: 0;
}

.visual--single--page .dropdown-menu .dropdown-item:hover {
    background: #33365e;
    color: #ffffff;
}

.visual--single--page .dropdown-menu .dropdown-item svg {
    margin-right: 0.4rem;
}

.visual--block {
    position: relative;
    margin-bottom: 1rem;
    background: none;
    padding: 0;
    border-radius: 0;
}

.visual--block img {
    width: 100%;
    object-fit: contain;
    height: 500px;
}

.visual--block .fullscreen--icon {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 50px;
    background: #ffffff80;
    color: #333333;
    font-size: 2rem;
    padding: 0.3rem 0.6rem;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    cursor: pointer;
}

.visual--user {
    /* border-style: solid; */
    /* border-color: #ffffff10; */
    /* border-width: 1px 0; */
    /* padding: 2rem 0; */
    /* margin-top: 1rem; */
    margin-bottom: 1rem;
    position: relative;
}

.visual--user,
.visual--user--name,
.visual--desc ul {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.visual--user--name img {
    width: 40px;
    margin-right: 1rem;
    border-radius: 100px;
    object-fit: cover;
    height: 40px;
}

.visual--user--name h5 {
    margin: 0;
}

.visual--user--name h5 span {
    display: block;
    color: #ffffff90;
    font-size: 80%;
    font-weight: normal;
}

.visual--user button {
    margin-left: auto;
    padding: 0.5rem 1rem;
}

.visual--desc {
    margin-bottom: 0;
}

.visual--desc ul {
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-between;
}

.visual--desc ul li {
    margin-right: 1.5rem;
    color: #ffffff90;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.visual--desc ul li svg {
    margin-right: 0;
    width: 26px;
    height: auto;
}

.live--comments textarea.form-control {
    border: 0;
    resize: none;
    padding: 0.8rem 1.5rem 2rem;
    padding-right: 3.5rem;
    font-size: 1rem;
    background: none;
}

.comments--box {
    background: #292c33;
    padding: 1.5rem;
    margin-top: 2rem;
    border-radius: 0.5rem 0.5rem 0 0;
    -webkit-border-radius: 0.5rem 0.5rem 0 0;
}

.comments--box .visual--desc ul li {
    margin-right: 0.3rem;
    font-size: 0.75rem;
}

.comments--box .visual--desc ul li svg {
    width: 15px
}

.comments--box .visual--desc {
    margin-bottom: 0;
    flex: 1 1 100%;
    padding-left: 55px;
}

.comments--box .visual--user--name {
    margin-bottom: 0;
}

.comments--box .live--comments {
    flex: 1 1;
}

.comments--box .reply--box {
    padding-left: 60px;
}

.comments--box .hideComment,
.comments--box .hideTextarea {
    display: none;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.comments--box .showComment,
.comments--box .showTextarea {
    display: block;
    border-top: 1px solid #ffffff10;
    padding-top: 1rem;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.comments--box .visual--user--name h5 {
    font-weight: normal;
    font-size: 0.875rem;
}


/*-==-=-=-=-=
Media Css
-==--==-*/

@media screen and (max-width:991px) {
    .Gr--streamImage a img, .Gr--streamImage img {
        height: 150px;
    }
}

@media screen and (max-width:767px) {
    .discover--module {
        padding: 0 0 2rem;
    }
}