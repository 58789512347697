.create--shop--form .back--btn {
    color: #dddddd;
    display: flex;
    align-items: center;
}

.create--shop--form .back--btn svg {
    margin-right: 0.3rem;
}

.create--shop--form form {
    background: rgba(49, 53, 61, 0.65);
    padding: 1.5rem;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    margin-top: 1rem;
}

.create--shop--form form .form-label {
    font-weight: 600;
    margin-bottom: 0.2rem;
}

.create--shop--form form .form-control {
    color: #a9a9b7;
    padding: 0 1rem;
    height: 40px;
    background-color: #ffffff10;
    border: 0;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    box-shadow: none;
    border: 1px solid #565557;
    background: #1e1f21;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.create--shop--form form textarea.form-control {
    height: 100px;
}

.create--shop--form .label--upload {
    background: #ffffff10;
    padding: 2rem;
    cursor: pointer;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    -o-border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    box-shadow: none;
    border: 1px solid #565557;
    background: #1e1f21;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}


/*-=-=-=-=-=-=-
Media Css-=-=-=*/

@media (min-width:992px) and (max-width:1199px) {
    .create--shop--form .label--upload {
        padding: 2rem 1rem;
    }
}
@media (max-width:991px) {
    .create--shop--form .label--upload {
        background: #ffffff10;
        padding: 1rem 1.5rem;
        font-size: 11px;
}
.create--shop--form .text-end.col-sm-12 .btn{
    font-size: 12px !important; 
}
}
@media screen and (max-width:575px) {
    .create--shop--form form {
        padding: 1rem;
    }
}

